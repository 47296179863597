import PointChangeAPI from "api/PointChangeAPI";
import useFetchData from "hooks/useFetchData";

export default function usePointChanges( params: PointChangeGETPaginatedParams = {}) {
    return useFetchData({
        APIMethod: PointChangeAPI.getAll,
        params: [ params ],
        clear_data_on_update: true,
        debounce: true
    })
}