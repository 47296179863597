import NotificationAPI from "api/NotificationAPI";
import useFetchData from "hooks/useFetchData";

export default function useNotifications( params: GETPaginatedParams = {}) {
   return useFetchData({
      APIMethod: NotificationAPI.getAll,
      params: [ params ],
      clear_data_on_update: true,
      debounce: true
   })
}