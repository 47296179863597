import SupportFormAPI from "api/SupportFormAPI";
import useFetchData from "hooks/useFetchData";

export default function useSupportFormParticipantsOptions( id?: number ) {
   return useFetchData({
      APIMethod: SupportFormAPI.byId,
      params: [ id ],
      condition: !!id,
      resultDataHandler: data => data.participants.map( item => ({
         value: item.id,
         label: item.full_name
      }))
   })
}