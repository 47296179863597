import { useMemo } from "react";
import useSchedule from "hooks/Schedule/useSchedule";
import { dateToCalendarData } from "utils/calendarHelpers";

export default function useSchedulesEventsAndBreaks(
    params: SpecialistSchedulesGETPaginatedParams & { date?: Date } = {},
    onEventClick?: ( item: SpecialistSchedule ) => void
) {

   const { date, ...p } = params;
   const { year, month } = date ? dateToCalendarData( date ) : { year: undefined, month: undefined };

   const { d, e, update } = useSchedule({ 
      limit: 100,
      year, 
      month,
      ...p
   });

   const schedules = d?.data;

   const { events, breaks } = useMemo(() => {

      if ( !schedules ) return {};

      const events = {} as ScheduleCalendarEvents;
      const breaks = {} as ScheduleCalendarBreaks; 
      
      d.data.forEach(( item ) => {
         
         const date = item.date;
         events[ date ] = events[ date ] || [];
         breaks[ date ] = breaks[ date ] || [];
         
         events[ date ].push({
            id: item.id,
            start_time: item.starting_time,
            end_time: item.ending_time,
            text: "",
            onClick: onEventClick ? () => onEventClick( item ) : undefined
         })
         
         breaks[ date ].push(...item.breaks );
      });

      return { events, breaks }
   }, [ schedules ])

   return {
      e,
      schedules,
      events,
      breaks,
      updateSchedules: update
   };
}