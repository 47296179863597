import { Fragment } from "react";
import FormRadio from "../FormRadio";

import "./form-radio-group.scss";

export default function FormRadioGroup( props: {
	name: string;
	label?: string;
	value: number | string | boolean;
	disabled?: boolean;
	error?: string;
	style?: React.CSSProperties;
	buttons: {
		label: string;
		value: number | string | boolean;
	}[];
	children?: React.ReactNode;
	onChange?: ( name: string, checked_value: number | string | boolean | null ) => void;
}) {
	
	const { name, value, label, disabled, error, style, buttons, onChange } = props;

	return Array.isArray( buttons ) && !!buttons.length ?
		<div className="form-radio-group"> 
			{ label && <label> { label } </label> }

			<div className="form-radio-group-items" style={ style }>
				{ buttons.map(({ label, value: v }) => {
							
					const checked = v === value;
		
					return (
						<Fragment key={ label + v }>
							<FormRadio
								label={ label }
								checked={ checked }
								disabled={ disabled }
								onClick={() => !disabled && onChange && onChange( name, v )}
							/>
						</Fragment>
					)
				})}
			</div>

			{ error && <div className="error"> { error } </div> }
		</div> 
		: null 
}