import { useState } from "react";
import { useNavigate } from "react-router-dom";
import PostAPI from "api/PostAPI";
import Toast from "utils/Toast";
import useFormData from "hooks/useFormData";
import Paths from "router/Paths";

import Button from "components/buttons/Button";
import Modal from "components/modal/Modal";
import FormInput from "components/form/FormInput";
import Loader from "components/general/Loader";

import inputs from "./inputs";

export default function ArticleRequestModal({ slug, id, onClose }: {
   slug: string;
   id: number;
   onClose: () => void;
}) {

   const navigate = useNavigate();

   const [ mode, setMode ] = useState( "" ); 
   const [ querying, setQuerying ] = useState( false );
   const { data, errors, onDataChange } = useFormData();

   const onSubmit = async() => {

      setQuerying( true );

      try {
         switch ( mode ) {
            case "request_access": 
               await PostAPI.sendRequest( slug, id, {
                  email: String( data.email ),
                  request_description: String( data.request_description || "" )
               })

               Toast.success( "Dziękujemy! Twoja prośba o dostęp została wysłana. Potwierdzenie otrzymasz na podany adres e-mail." );
               onClose();
            break;

            case "have_pin":
               const pin = String( data.pin || "" );
               const email = String( data.email || "" );
               const { post } = await PostAPI.byId({ slug, id, pin, email });
               
               if ( post ) {
                  navigate( Paths.Articles.View( slug, id ), {
                     state: { pin, email }
                  });
               } else {
                  Toast.error( "Zły kod PIN lub adres e-mail" );
               }
            break;
         }
      } catch ( e: any ) {
         e?.response?.data?.message && Toast.error( e?.response?.data?.message );
      } finally {
         setQuerying( false );
      }
   }

   return (
      <Modal
         title={ mode === "have_pin" ? "Otwórz artykuł" : "Prośba o dostęp" }
         onClose={ onClose }
      > 
         <div>

            { mode === "" && 
               <>
                  <p style={{ margin: "0 0 30px 0"}}> W jaki sposób chcesz uzyskać dostęp do artykułu? </p>
            
                  <Button 
                     extra_classes="outline" 
                     style={{ width: "100%", marginBottom: 10 }}
                     onClick={() => setMode( "request_access" )}
                  > Poproś o dostęp </Button>

                  <Button
                     extra_classes="outline"
                     style={{ width: "100%" }}
                     onClick={() => setMode( "have_pin" )}
                  > Mam kod PIN </Button>
               </>
            }

            { mode === "request_access" && 
               <>
                  { inputs.request_access.map(({ name, label }) => (
                     <FormInput
                        key={ name }
                        name={ name }
                        value={ String( data[ name ] || "" )}
                        error={ errors[ name ]}
                        label={ label }
                        type={ name === "email" ? "email" : "textarea" }
                        input_style={ name !== "email" ? { height: 132 } : {}}
                        onChange={ onDataChange }
                     />
                  ))}
               </>
            }

            { mode === "have_pin" && 
               <>
                  { inputs.have_pin.map(({ name, label }) => (
                     <FormInput
                        key={ name }
                        name={ name }
                        value={ String( data[ name ] || "" )}
                        error={ errors[ name ]}
                        label={ label }
                        type={ name === "email" ? "email" : "text" }
                        onChange={ onDataChange }
                     />
                  ))}
               </>
            }

            { querying && <Loader/> }

            { mode && !querying && 
               <Button style={{
                  width: 95,
                  display: "block",
                  margin: "auto"
               }} onClick={ onSubmit }> 
                  { mode === "request_access" ? "Wyślij" : "Otwórz" } 
               </Button>
            }
         </div>
      </Modal>
   )
}