import Modal from "components/modal/Modal"
import FormInput from "components/form/FormInput"
import useFormData from "hooks/useFormData";

export default function ItemModal({ values, onClose, onConfirm }: {
    values?: FileObj | FileDirectoryObj;
    onClose: () => void;
    onConfirm: ( d: FileObjDTO & { id?: string }) => void;
}) {

    const is_edit = !!values;
    const is_file = values?.type === "file";
    const file_extension = is_file ? values.name.match(/.[a-z]+$/)?.[0] || "" : "";

    const title = is_edit
        ? is_file ? "Edytuj plik" : "Edytuj folder"
        : "Dodaj folder"

    const { data, errors, onDataChange, setErrors } = useFormData({ 
        id: values?.id,
        name: values?.name?.replace( file_extension, "" ) || "",
        parent_id: values?.parent_id 
    })

    const validation = () => {
        if ( !data.name ) {
            setErrors({ name: "To pole jest wymagane!" });
            return false;
        }

        return true;
    }

    return (
        <Modal
            title={ title }
            confirm_label={ title }
            validationFn={ validation }
            onClose={ onClose }
            onConfirm={() => onConfirm({
                id: String( data.id || "" ),
                name: String( data.name || "" ) + ( file_extension || "" ),
                parent_id: String( data.parent_id || "" )
            })}
        > 
            <>
                <FormInput
                    name="name"
                    value={ String( data.name || "" )}
                    error={ errors.name }
                    label={ is_file ? "Nazwa pliku" : "Nazwa folderu" }
                    style={{ margin: 0 }}
                    onChange={ onDataChange }
                />
            </>
        </Modal>
    )
}