import React from "react";
import { ReactComponent as CheckedSvg } from "./checked.svg";
import mergeCssClasses from "utils/mergeCssClasses";

import "./checkbox.scss";

export default function Checkbox( props: {
	name?: string;
	checked?: boolean;
	disabled?: boolean;
	error?: string;
	children?: React.ReactNode;
	style?: React.CSSProperties;
	onClick?: ( n: string, v: boolean ) => void;
}) {
		
	const { name, checked, disabled, error, children, onClick } = props;

	return (
		<>
			<div 
				className={ mergeCssClasses( "checkbox", checked && "checked", disabled && "disabled" )} 
				style={ props.style }
				onClick={() => name && onClick && onClick( name, !checked )}
			>

				<div className="checkbox-box"> 
					{ !!checked && <CheckedSvg/> }
				</div>

				{ children && 
					<div className="checkbox-label">
						{ children }
					</div>
				}
			</div>

			{ error && <div className="error"> { error } </div> }
		</>
	)
}