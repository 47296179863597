import MessengerAPI from "api/MessengerAPI";
import useFetchData from "hooks/useFetchData";

export default function useConversations() {
    return useFetchData<SConversation[], Conversation[]>({
        APIMethod: MessengerAPI.getAllConversations,
        resultDataHandler: data => data.map( item => {
            return {
                id: item.id,
                last_message: item.last_message,
                user: Array.isArray( item.users ) 
                    ? item.users[0]
                    : Object.values( item.users )[0]
            }
        })
    })
}