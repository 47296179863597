import { useParams } from "react-router-dom";
import SupportFormAPI from "api/SupportFormAPI";
import useFetchData from "hooks/useFetchData";

export default function useSupportForm( id?: number | "route_param" ) {
   
   const route_id = useParams().id;

   return useFetchData({
      APIMethod: SupportFormAPI.byId,
      params: [ id === "route_param" ? route_id : id ],
      condition: id === "route_param" ? !!route_id : !!id
   })
}