import { forwardRef } from "react";
import { cutText } from "utils/functions";

import { useAuthContext } from "context/AuthContext";
import withInnerPopup from "hoc/withInnerPopup";

import { AngleDownSvg } from "svg/icons";
import "./support-forms-widget.scss";

const SupportFormsWidget = forwardRef<HTMLDivElement, any>(({ show_popup, setPopup }, ref ) => {
   
   const { support_forms_assignments, support_form, selectItem } = useAuthContext();

   return !!support_forms_assignments?.length ? (
      <>
         <div ref={ ref } className="support-forms-widget" onClick={() => setPopup( s => !s )}> 

            <span> 
               <span> Forma wsparcia: </span> 
               <strong> { cutText( support_form?.name || "", 20 )} </strong>
               <AngleDownSvg style={{ transform: show_popup ? "rotate(180deg)" : "" }}/>
            </span>

            { show_popup && 
               <div className="support-forms-widget-popup">
                  { support_forms_assignments.map( item => (
                     <div 
                        key={ item.support_form.id }
                        className={ item.support_form.id === support_form?.id ? "active" : ""}
                        onClick={() => selectItem( item )}   
                     >
                        { item.support_form.name }
                     </div>
                  ))}
               </div>
            }
         </div>
         
         <div className="separator"/>
      </>
   ) : null
})

export default withInnerPopup( SupportFormsWidget )