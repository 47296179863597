import { ReactComponent as StarSvg } from "./star.svg";
import "./rating-box.scss";
import mergeCssClasses from "utils/mergeCssClasses";

export default function RatingBox({ name = "rating", extra_classes, value = 0, style, onChange }: {
   name?: string;
   extra_classes?: string;
   value?: number;
   style?: React.CSSProperties;
   onChange?: ( n: string, v: number ) => void;
}) {

   return (
      <div className={ mergeCssClasses( "rating-box", extra_classes )} style={ style }>
         {[ 1, 2, 3, 4, 5 ].map( num => (
            <StarSvg
               key={ num }
               fill={ num <= value ? "#A4344E" : "#EEEEEE" }
               style={{ cursor: onChange ? "pointer" : "" }}
               onClick={() => onChange && onChange( name, num )}
            />
         ))}
      </div>
   )
}