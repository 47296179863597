import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Toast from "utils/Toast";
import AuthAPI from "api/AuthAPI";

import Paths from "router/Paths";
import NotLoggedIn from "layouts/NotLoggedIn";
import FormInput from "components/form/FormInput";
import useFormData from "hooks/useFormData";

const inputs = [
	{ name: "password", label: "Nowe hasło" },
	{ name: "password_confirmation", label: "Powtórz hasło" }
]

export default function ResetPassword() {

    const navigate = useNavigate();
	
	const [ s ] = useSearchParams();
	const token = s.get( "token" );
	const email = s.get( "email" );

	const { data, errors, onDataChange, setErrors } = useFormData();

    const onSubmitForm = async() => {

        if ( !token || !email ) return; 

		try {
			await AuthAPI.resetPassword({
				token,
				email,
				password: data.password as String,
                password_confirmation: data.password_confirmation as String
			})

			Toast.success( "Nowe hasło zostało pomyślnie ustawione!" );
			navigate( Paths.Auth.Login );
		} catch ( e: any ) {

			const data = e.response?.data;
			setErrors( data?.errors || {});

			data.status === "passwords.token" && Toast.error( "Token wygasł!" )
		}
	}

    useEffect(() => {
		( !token || !email ) && navigate( Paths.Auth.ForgotPassword );
	}, [])

    return (
        <NotLoggedIn
            title="Resetuj hasło"
            submit_label="Wyślij"
            bottom_link={{
                to: Paths.Auth.Login,
                label: "Zaloguj się"
            }}

            onSubmitForm={ onSubmitForm }
        > 

            <p style={{ margin: "30px 0"}}> Wprowadź nowe hasło do systemu. </p>
            
            { inputs.map(({ name, label }) => (
				<FormInput
					key={ name }
					name={ name }
					value={ String( data[ name ] || "" )}
					label={ label }
					type="password"
					error={ errors[ name ]}
					onChange={ onDataChange }
				/>
			))}
           
        </NotLoggedIn>
    )
}