import { useCallback, useState } from "react";
import Toast from "utils/Toast";

interface useEndRequestProps<D> {
	APIMethod?: ( data: D ) => Promise<unknown>;
	success_message?: string;
	error_message?: string;
	onSuccess?: ( response: unknown ) => void;
	onError?: ( err: unknown ) => void;
}

export default function useSendRequest<D>({
	APIMethod,
	success_message,
	error_message,
	onSuccess,
	onError
}: useEndRequestProps<D>) {

	const [ querying, setQuerying ] = useState( false );

	const sendRequest = useCallback( async( data: D ) => {

		if ( !APIMethod || querying ) return;
		setQuerying( true );

		try {
			const resp = await APIMethod( data );
			
			success_message && Toast.success( success_message );
			onSuccess && onSuccess( resp );

		} catch ( err: unknown ) {
			
			const be_message = err?.['response']?.['data']?.['message'];
			be_message && Toast.error( be_message );

			!be_message && error_message && Toast.error( error_message );
			onError && onError( err );

			throw err;

		} finally {
			setQuerying( false );
		}
	}, [ querying ])


	return { 
		querying,
		sendRequest
	}
}  