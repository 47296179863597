import { DAY_NAME_SHORT, getDateString, getDayProperIndex, getWeek } from "utils/date";
import { addZero } from "utils/functions";
import { getDayLabelJSX } from "utils/calendarHelpers";

import ScheduleCalendarEvent from "../ScheduleCalendarEvent";
import time from "./time";

import "./day-week-table.scss";


export default function DayWeekTable({ mode, date, events, breaks, available_dates, available_items, onDayClick }: {
   mode: ScheduleCalendarMode;
   date: Date;
   events?: ScheduleCalendarEvents;
   breaks?: ScheduleCalendarBreaks;
   available_dates?: string[];
   available_items?: ScheduleCalendarAvailableItems;
	onDayClick?: ( date_iso: string ) => void;
}) {

    const week = getWeek( date );
    const day_date_iso = getDateString( date, "YYYY-MM-DD" );

    const renderEvents = ( date_iso: string, key?: string ) => (
        <div 
            key={ key }
            style={{ cursor: onDayClick ? "pointer" : "" }}
            onClick={() => {
               if ( available_dates && !available_dates.includes( date_iso )) return;
               onDayClick && onDayClick( date_iso );
            }}
        > 
            { events && events[ date_iso ] && 
                events[ date_iso ].map(( item, i ) => (
                    <ScheduleCalendarEvent                        
                        key={ date_iso + i }
                        calculate_top
                        calculate_height
                        {...item }
                        text={ mode === "day" ? item.text : "" }
                    />
                ))
            }

            { breaks && breaks[ date_iso ] && 
                breaks[ date_iso ].map(( item, i ) => (
                    <ScheduleCalendarEvent
                        id={0}
                        key={ date_iso + i }
                        is_break
                        calculate_top
                        calculate_height
                        start_time={ item.from }
                        end_time={ item.to }
                    />
                ))
            }

            { available_items && available_items[ date_iso ] && 
               available_items[ date_iso ].map(( item, i ) => (
                  <ScheduleCalendarEvent
                     id={0}
                     key={ date_iso + i }
                     calculate_top
                     calculate_height
                     start_time={ item.from }
                     end_time={ item.to }
                     style={{ background: "#EDF9ED", color: "#000" }}
                     onClick={ item.onClick }
                  />
               ))
            }
        </div>
    )

    return (
        <div className="day-week-table">
            <div className="day-week-table-top">
                { mode === "week" && 
                    week.map(({ date, date_iso }, i ) => (
                        <div key={ i }
                            style={{ cursor: onDayClick ? "pointer" : "" }}
                            onClick={() => onDayClick && onDayClick( date_iso )}
                        >
                            { addZero( date.getDate())}.
                            { addZero( date.getMonth() + 1 )}
                            { " " + DAY_NAME_SHORT[ getDayProperIndex( date )]}
                        </div>
                    ))
                }

                { mode === "day" && 
                    <div
                        style={{ cursor: onDayClick ? "pointer" : "" }}
                        onClick={() => onDayClick && onDayClick( day_date_iso )}
                    >
                        { getDayLabelJSX( date )}
                    </div>
                }
            </div>

            <div className="day-week-table-box">
                <div className="day-week-table-time">
                    { time.map(( t ) => (
                        <div key={ t }>
                            { t }
                        </div>
                    ))}
                </div>

                <div className="day-week-table-items">
                    { mode === "week" && 
                        week.map(({ date_iso }, i ) => {
                            return renderEvents( date_iso, date_iso + i )
                        })
                    }

                    { mode === "day" && renderEvents( day_date_iso )}
                </div>
            </div>
        </div>
    )
}