import mergeCssClasses from "utils/mergeCssClasses";
import "./info-lines.scss";

export default function InfoLines({ className, info }: {
    className?: string;
    info: {
        label: string;
        value: string | React.ReactNode;
    }[];
}) {

    return Array.isArray( info ) ? (
        <div className={ mergeCssClasses( "info-lines", className )}>
            { info.map(({ label, value }) => (
                <div key={ label }>
                    <span> { label } </span>
                    <strong> { value } </strong>
                </div>
            ))}
        </div>
    ) : null
}