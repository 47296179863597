import FormInput from "../FormInput";
import "./form-date-range.scss";

export default function FormDateRange( props: {
    name?: string;
	data: FData;
	label?: string;
	style?: React.CSSProperties;
	onChange: FDataOnChange;
}) {

    const {
        name = "date_from, date_to",
        data = {},
        label,
        style,
        onChange
    } = props;

    const names = name.split(",");

    return (
        <div 
            className="form-date-range" 
            style={ style }
        >
			
        { label && 
            <label> { label } </label>
        }

        <div className="form-date-range-inputs">
            {[ names[0], names[1]].map(( name ) => {

                name = name.trim();

                return (
                    <FormInput
                        key={ name }
                        name={ name }
                        value={ String( data[ name ] || "" )}
                        type="date"
                        onChange={ onChange }
                    />
                )
            })}
        </div>	
    </div>
    )
}