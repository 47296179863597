import { useEffect, useState } from "react";
import ParticipantAPI from "api/ParticipantAPI";
import SupportFormAPI from "api/SupportFormAPI";

import Storage from "utils/Storage";
import { Roles } from "constants/Roles";

export default function useMyParticipants( user: AuthUser | null ) {

   const user_role = user?.roles[0]?.name;
   const is_family = user_role === Roles.Family;
   const is_specialist = user_role === Roles.Specialist;

   const [ loading, setLoading ] = useState( true );

   const [ data, setData ] = useState<Participant[] | SpecialistSupportForm[] | null>( null );
   const [ item, setItem ] = useState<Participant | SpecialistSupportForm | null>( Storage.get( "item" ) || null );
   
   const getData = async( clear_on_update?: boolean ) => {
      
      setLoading( true );

      if ( clear_on_update ) {
         setData( null );
         setItem( null );

         if ( !user ) return;
      }

      if ( user_role === Roles.Family ) {
         const r = await ParticipantAPI.getAll({ limit: 100, column: "id", direction: "asc" });
         setData( r.data );
      }

      if ( user_role === Roles.Specialist ) {
         const r = await SupportFormAPI.getMyAssignments({ limit: 100, column: "id", direction: "asc" });
         setData( r.data );
      }

      setLoading( false );
   }

   useEffect(() => {
      getData( true );
   }, [ user ]);


   useEffect(() => {

      if ( !data ) return;
      const first_item = data[0] || null;

      if ( !item ) {
         setItem( first_item );
         return;
      } else {
         const exist = data.some( j => {
            return "id" in item 
               ? j.id === item?.id
               : j.id === item?.support_form?.id 
         });

         !exist && setItem( first_item );
      }

   }, [ data ]);


   const participant = ( is_family ? item : null ) as Participant | null;
   const participants = ( is_family ? data : null ) as Participant[] | null;
   
   const support_form_assignment = ( is_specialist ? item : null ) as SpecialistSupportForm | null;
   const support_forms_assignments = ( is_specialist ? data : null ) as SpecialistSupportForm[] | null;

   return {
      loading,

      items_data: data,
      participant,
      participants,

      support_form_assignment,
      support_forms_assignments,

      selectItem: ( item: Participant | SpecialistSupportForm ) => {
         Storage.set( "item", item );
         setItem( item );
      },

      updateItemsData: () => getData(),
   }
}