import React from "react";
import { Link } from "react-router-dom";
import Paths from "router/Paths";
import { HomeSvg, AngleRightSvg } from "svg/icons";

import "./breadcrumbs.scss";

const BREADCRUMB_ITEMS = {
	"HOME": {
		svg: <HomeSvg/>,
		label: "Strona główna",
		to: Paths.Home
	},

	"SUPPORT_FORMS": {
		label: "Formy wsparcia",
		to: Paths.SupportForm.List
	},

	"ARTICLES": {
		label: "Lista artykułów",
		to: Paths.Articles.PanelArticles
	}
}

export default function Breadcrumbs({ breadcrumbs, hide_home, style }: {
	breadcrumbs: BreadcrumbsItem[];
	hide_home?: boolean;
	style?: React.CSSProperties;
}) {

	const has_list = !!breadcrumbs && !!breadcrumbs.length;

	const getBreadcrumbItem = ( item: BreadcrumbsItem, divider: boolean, key?: number ) => {

		const breadcrumb_item = typeof item === "string" 
			? BREADCRUMB_ITEMS[ item ] 
			: item || null;

		const label = breadcrumb_item?.label || item;
		const svg = breadcrumb_item?.svg || "";
		const to = breadcrumb_item?.to || "";

		const is_link = !!to;
		const Component = is_link ? Link : "span";

		return (
			<React.Fragment key={ key }>
				<Component  
					to={ to }
					onClick={ typeof item !== "string" && item?.onClick ? item.onClick : undefined }
				>
					{ svg }
					<span> { label } </span>
				</Component>

				{ divider && <span className="breadcrumbs-divider"> <AngleRightSvg/> </span> }
			</React.Fragment>
		)
	} 

	return (
		<nav className="breadcrumbs" style={ style }>

			{ !hide_home && getBreadcrumbItem( "HOME", has_list )}

			{ has_list &&
				breadcrumbs.map(( item, i ) => (
					getBreadcrumbItem( item, i < breadcrumbs.length - 1, i )
				))
			}

		</nav>
	)
}