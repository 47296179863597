import SpecialistScheduleAPI from "api/SpecialistScheduleAPI";
import useFetchData from "hooks/useFetchData";
import { useMemo } from "react";

export default function useCalendarDays(
   params: Partial<SpecialistCalendarDaysParams>,
   selected_date?: string,
   r?: SpecialistReservation
) {

   const { d: calendar_days, e } = useFetchData({
      APIMethod: SpecialistScheduleAPI.getCalendarDays,
      params: [ params ],
      condition: (
         !!params.support_form_id &&
         !!params.date_from &&
         !!params.date_to
      ), 
      debounce: true
   })

   const available_dates = useMemo(() => {
      return calendar_days 
         ? Object.keys( calendar_days ).filter( k => calendar_days[k].available )
         : []
   }, [ calendar_days ])

   const time_options: Options = useMemo(() => {

      const items = calendar_days?.[ selected_date || "" ]?.items;
      if ( !items && !r ) return [];

      const options: Options = [];

      items && items.forEach( item => {
         item.frames
            .filter( frame => frame.available )
            .forEach( frame => options.push({
               value: frame.from + "-" + frame.to,
               label: frame.from + " - " + frame.to,
               extra: item.schedule_item_id
            }))
         })

      if ( r?.starting_time && r?.ending_time ) {
         options.push({
            value: r.starting_time.slice(0, 5) + "-" + r.ending_time.slice(0, 5),
            label: r.starting_time.slice(0, 5) + "-" + r.ending_time.slice(0, 5),
            extra: r.specialist_schedule_id
         })
      } 

      return options; 
   }, [ calendar_days, selected_date, r ]);

   return {
      e, 
      available_dates,
      time_options
   };
}