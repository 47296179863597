
import NotificationBell from "./components/NotificationBell";
import SupportFormsWidget from "./components/SupportFormsWidget";
import ParticipantsWidget from "./components/ParticipantsWidget";
import UserWidget from "./components/UserWidget";

import "./top-bar.scss";

export default function TopBar() {
   return (
      <div className="top-bar">
         
         <div className="top-widgets">
            <NotificationBell/>
            <SupportFormsWidget/>
            <ParticipantsWidget/>
            <UserWidget/>
         </div>
      </div>
   )
}