import API from "./API";
import { CancelToken } from "axios";

export default {

    getAll: async( params: PointChangeGETPaginatedParams = {}, cancelToken?: CancelToken ) => {
        const { data } = await API.get<GETPaginatedResponse<PointChange>>( `/participants/point-changes`, {
            params: { 
                ...params,
                description: params.description || undefined,
                points: params.points || undefined
            },
            cancelToken
        })

        return data;
    }
}