import { useEffect } from "react";
import { getDateString } from "utils/date";
import Modal from "components/modal/Modal";
import InfoLines from "components/general/InfoLines";
import NotificationAPI from "api/NotificationAPI";

export default function NotificationViewModal({ notification, onClose }: {
   notification: NotificationItem;
   onClose: () => void;
}) {

   useEffect(() => {
      if (!notification.read_at)
      {
         NotificationAPI.readOwn(notification.id).catch(e => {})
      }
   }, [notification])

   const info = [
      { label: "Tytuł:", value: notification.title },
      { label: "Pełna treść:", value: notification.content },
      { label: "Czas wysyłki:", value: getDateString( new Date( notification.created_at ), "DD.MM.YYYY hh:mm" )}
   ]

   return (
      <Modal
         title="Podgląd powiadomienia"
         onClose={ onClose }
      >
         <>
            <InfoLines info={ info }/>
         </>
      </Modal>
   )
}