import Paths from "router/Paths";

import { useAuthContext } from "context/AuthContext";
import PageHeader from "components/general/PageHeader";
import Button from "components/buttons/Button";
import Box from "components/general/Box";

import Tiles from "./components/Tiles";

export default function Home() {
   
   const { user_role } = useAuthContext();

   return (
      <>
         <PageHeader 
               title="Home"
               breadcrumbs={[]}
         />

         <Box heading="Witamy w aplikacji">
            Śląski Ośrodek Adopcyjny wita Państwa na platformie przeznaczonej do korzystania z form wsparcia w procesie post i pre adopcyjnym.
         </Box>

         { user_role === "family" && 
            <Box heading="Lista form wsparcia">
               <>
                  Klikając poniższy przycisk możesz w szybki sposób zarządzać formami wsparcia dostępnymi na portalu. Możesz zarządzać specjalistami oraz uczestnikami przypisując ich do wybranych form wsparcia. W razie problemów z obsługą systemu możesz skorzystać z dołączonej instrukcji. <br /> <br />

                  <Button to={ Paths.SupportForm.List }> Przeglądaj formy wsparcia </Button>
               </>
            </Box> 
         }

         <Tiles/>
      </>
   )
}