import { useLocation } from "react-router-dom";
import usePosts from "hooks/Post/usePosts";
import useFormData from "hooks/useFormData";
import withModal from "hoc/withModal";

import PageHeader from "components/general/PageHeader";
import GuestPageHeader from "components/general/GuestPageHeader";
import Pagination from "components/general/Pagination";
import Loader from "components/general/Loader";
import ArticleTile from "./components/ArticleTile";
import ArticleFilters from "./components/ArticleFilters";
import ArticleRequestModal from "./components/ArticleRequestModal";

export default withModal( function Articles({ openModal }) {

   const location = useLocation();
   const is_panel = /panel/.test( location.pathname );

   const { data: params, onDataChange: onParamsChange } = useFormData({
      page: 1,
      limit: 8,
      query: undefined,
      category_id: undefined,
      published_from: undefined,
      published_to: undefined
   });

   const { d } = usePosts( params );
   const articles = d?.data;

   return (
      <>
         { is_panel  
            ? <PageHeader
               title="Lista artykułów"
               breadcrumbs={[ "Lista artykułów" ]}
            /> : <GuestPageHeader title="Lista udostępnionych artykułów"/>
         }

         <div style={ !is_panel ? { padding: "0 15px" } : {}}> 
            <ArticleFilters
               data={ params }
               onChange={( n, v ) => {
                  const name = n as string; 
                  onParamsChange({
                     [ name ]: v,
                     page: 1
                  })
               }}
            />

            { !articles && <Loader/> }

            { articles && !!articles.length &&
               <div className="articles-items">
                  { articles.map( item => (
                     <ArticleTile
                        key={ item.id }
                        is_panel={ is_panel }
                        {...item }
                        openRequestModal={ props => openModal({
                           Component: ArticleRequestModal,
                           props: props
                        })}
                     />
                  ))}        
               </div>
            }

            { articles && !articles.length && 
               <div style={{ padding: "39px 0", textAlign: "center" }}> Brak treści do wyświetlenia. </div>
            }

            { d?.last_page && 
               <Pagination
                  page={ Number( params.page || 1 )}
                  pages_amount={ d?.last_page }
                  onChange={ onParamsChange }
               />
            }
         </div>
      </>
   )
})