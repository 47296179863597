import React from "react";
import Button from "components/buttons/Button";

import "./tile.scss";

export default function Tile({ title, text, svg, button }: {
    title: string;
    text: string;
	svg?: React.JSX.Element;
    button: {
        label: string;
        to: string;
    }
}) {
    return (
        <div className="tile">

            
            { svg }

            <h2> { title } </h2>

            <p> { text } </p>

            <div className="tile-bottom">
                <Button
                    to={ button.to }
                > { button.label } </Button>
            </div>
        </div>
    )
}