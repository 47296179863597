import SpecialistReviewAPI from "api/SpecialistReviewAPI";
import { getDateString } from "utils/date";
import useSpecialistReviews from "hooks/Review/useSpecialistReviews";
import useSpecialistReviewsForSpecialist from "hooks/Review/useSpecialistReviewsForSpecialist";

import HookedDataTable from "layouts/HookedDataTable";
import RatingBox from "components/form/RatingBox";

export default function SpecialistReviews({ participant_id, is_family, is_specialist, openFamilyReviewModal, openSpecialistReviewModal }) {

   return (
      <HookedDataTable
         box_wrapper={ false }

         default_filters={{ participant_id }}
      
         table_columns={[
            is_family ? { label: "Specjalista" } : { label: "Uczestnik wystawiający opinię" },
            { label: "Data wystawienia", width: 310 },
            { label: "Ocena" },
            { label: "Akcje", align: "center" as const, width: 100 }
         ]}

         filter_inputs={{
            0: is_family ? { name: "specialist_name" } : { name: "participant_name" },
            1: { name: "review_date_from, review_date_to", type: "date-range" },
            2: { name: "stars", type: "number" }
         }}

         dataHook={ is_family ? useSpecialistReviews : useSpecialistReviewsForSpecialist }
         getRowsFn={( item, update ) => ({
            id: item.id,
            cells: [
               { content: is_family ? item.specialist.full_name : item.participant.full_name },
               { content: getDateString( new Date( item.created_at ), "DD.MM.YYYY hh:mm" )},
               { content: <RatingBox value={ item.stars }/>},
               { actions: [
                  is_family ? { svg: "edit", onClick: () => openFamilyReviewModal({
                     type: "specialist",   
                     review: item,
                     onConfirm: async( d ) => {
                        await SpecialistReviewAPI.update( item.id, d );
                        update();
                     }
                  })} : null,

                  is_specialist ? { svg: "view", onClick: () => openSpecialistReviewModal({
                     type: "specialist",
                     review: item,
                     onConfirm: async( a ) => {
                        await SpecialistReviewAPI.response( item.id, a );
                        update();
                     }
                  })} : null
               ]}
            ]
         })}
      />
   )
}