import { useAuthContext } from "context/AuthContext";
import useSpecialistReviews from "hooks/Review/useSpecialistReviews";
import SpecialistReviewAPI from "api/SpecialistReviewAPI";
import withModal from "hoc/withModal";

import Button from "components/buttons/Button";
import Loader from "components/general/Loader";
import FamilyReviewModal from "components/modal/FamilyReviewModal";

export default withModal<{ specialist_id: number }>( function SpecialistBoxReview({ specialist_id, openModal }) {

   const { participant } = useAuthContext();

   const { d, update: updateReviews } = useSpecialistReviews({ participant_id: participant?.id, specialist_id });
   const review = d?.data?.[0];

   return d ? (
      <div> 
         <h3> Ocena specjalisty </h3>

         { !review &&
            <> 
               <p> Nie wystawiłeś jeszcze oceny dla tego specjalisty </p>
               <Button onClick={() => openModal({
                  Component: FamilyReviewModal,
                  props: {
                     type: "specialist",
                     onConfirm: async( d ) => {
                        await SpecialistReviewAPI.add({
                           ...d, 
                           specialist_id,
                           participant_id: participant?.id 
                        })

                        updateReviews();
                     }
                  }
               })}
               > Wystaw ocenę </Button>
            </>
         }

         { review &&
            <> 
               <p> Twoja ocena: { review.stars } </p>
               <Button onClick={() => openModal({
                  Component: FamilyReviewModal,
                  props: {
                     type: "specialist",
                     review: review
                  }
               })}
               > Zobacz ocenę </Button>
            </>
         }
      </div>
   ) : <Loader style={{ width: 80, margin: "auto 0"}}/>
})