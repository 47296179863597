import useReservations from "hooks/Reservation/useReservations";
import HookedDataTable from "layouts/HookedDataTable";
import { dateISOStrToMMDDYYYY } from "utils/date";

import StatusBox from "components/general/StatusBox";
import ReservationAPI from "api/ReservationAPI";

const columns = [
   { name: "support_form_id", label: "Forma wsparcia" },
   { name: "specialist_user_id", label: "Specjalista" },
   { name: "date", label: "Data" },
   { name: "starting_time", label: "Godzina rozpoczęcia" },
   { name: "ending_time", label: "Godzina zakończenia" },
   { name: "status", label: "Status" },
   { label: "Akcje", align: "center" as const }
]

export default function TableView({ participant_id, openReservationModal }) {
   return (
      <HookedDataTable
         box_wrapper={ false }
         table_columns={ columns }

         default_filters={{ participant_id }}

         dataHook={ useReservations }
         getRowsFn={( item, update ) => {

            return {
               id: item.id,
               cells: [
                  { content: item.support_form.name },
                  { content: item.specialist.full_name },
                  { content: dateISOStrToMMDDYYYY( item.date )},
                  { content: item.starting_time.slice( 0, 5 )},
                  { content: item.ending_time.slice( 0, 5 )},
                  { content: <StatusBox status={ item.status }/> },
                  { actions: [   
                     { svg: "edit", onClick: () => openReservationModal({
                        item,
                        update
                     })},
                     
                     { svg: "delete", onClick: async() => {
                        await ReservationAPI.delete( item.id );
                        update()
                     }},
                  ]}
               ]
            }
         }}
      />
   )
}