import { forwardRef } from "react";
import { Link } from "react-router-dom";
import Paths from "router/Paths";
import { getUserAvatarURL } from "utils/avatarHelpers";

import { useAuthContext } from "context/AuthContext";
import withInnerPopup from "hoc/withInnerPopup";
import { ProfileSvg, LogOutSvg, AngleDownSvg } from "svg/icons";

import default_avatar from "img/default-avatar.png";
import "./user-widget.scss";

const UserWidget = forwardRef<HTMLDivElement, any>(({ show_popup, setPopup }, ref ) => {

   const { user, logOut } = useAuthContext();
   const avatar = getUserAvatarURL( user.id, user.avatar_file_id );

   return (        
      <div ref={ ref } className="user-widget">

         <span onClick={() => setPopup( s => !s )}> 
            { user.full_name }
            <img src={ avatar || default_avatar } alt={ user.full_name }/>

            <AngleDownSvg style={{ transform: show_popup ? "rotate(180deg)" : "" }}/>
         </span>

         { show_popup && 
            <div className="user-widget-popup">
               <Link to={ Paths.MyProfile }> 
                  <ProfileSvg/>
                  Twój profil 
               </Link>

               <a href="#" onClick={ e => {
                  e.preventDefault();
                  logOut();
               }}> <LogOutSvg/> Wyloguj się </a>
            </div>
         }
      </div>
   )
})

export default withInnerPopup( UserWidget )