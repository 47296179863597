import HTMLReactParser from "html-react-parser";
import useSendRequest from "hooks/useSendRequest";
import RegulationAPI from "api/RegulationAPI";

import Modal from "components/modal/Modal";
import Button from "components/buttons/Button";
import "./regulation-modal.scss";

export default function RegistrationRegulationModal({ regulation, onClose }: {
   regulation: Regulation;
   onClose: () => void;
}) {

   const { querying, sendRequest } = useSendRequest({
      APIMethod: () => RegulationAPI.accept( regulation.slug ),
      onSuccess: () => onClose()
   })

   return (
      <Modal 
         className="regulation-modal"
         title={ regulation.title } 
         loading={ querying }
      >
         <>
            <div> 
               { HTMLReactParser( regulation.content )}
            </div>
            
            <div style={{ display: "flex" }}> 
               <Button 
                  style={{ margin: "20px auto 0" }}
                  disabled={ querying }
                  onClick={ sendRequest }
               > Zaakceptuj </Button>
            </div>
         </>
      </Modal>
   )
}