import { useEffect, useMemo } from "react";
import useFormData from "hooks/useFormData";
import { addZero } from "utils/functions";
import { getDateString } from "utils/date";
import Toast from "utils/Toast";
import SpecialistReportAPI from "api/SpecialistReportAPI";

import FormInput from "components/form/FormInput";
import Modal from "components/modal/Modal";
import Button from "components/buttons/Button";
import SvgBtn from "components/buttons/SvgBtn";

import inputs from "./inputs";

export default function ReportHoursModal({ id, onClose, onConfirm }: {
   id: number;
   onClose: () => void;
   onConfirm: ( d: SpecialistReportDays ) => Promise<void>;
}) {

   const { data, onDataChange } = useFormData();
   const days = data.days as SpecialistReportDays | undefined;

   const total_hours = useMemo(() => {
      return !days ? "" : days.reduce(( res, current ) => {
         const [ hours, minutes ] = current.hours.split(":");
         
         res[0] = Number( hours ) + res[0];
         res[1] = Number( minutes ) + res[1];
         
         if ( res[1] > 60 ) {
            res[0] += 1;
            res[1] -= 60
         }

         return res;
      }, [0, 0 ]).map( i => addZero( i )).join(":")
   }, [ days ])


   const addHours = () => {
      const date = String( data.date );
      const hours = String( data.hours );

      let days = ( data.days || []) as SpecialistReportDays;
      
      if ( days.some( i => i.date === date )) {
         Toast.error( "Godziny dla tego terminu zostały już dodane!" );
         return;
      }

      days.push({ date, hours });
      days.sort(( a, b ) => a.date > b.date ? 1 : -1 );

      onDataChange({
         date: undefined,
         hours: undefined,
         days
      })
   }


   const deleteItem = ( i: number ) => {
      let days = ( data.days || []) as SpecialistReportDays;
      days.splice( i, 1 );
      onDataChange({ days }); 
   }


   useEffect(() => {
      SpecialistReportAPI.daysById( id ).then( days => {
         days.sort(( a, b ) => a.date > b.date ? 1 : -1 );
         onDataChange({ days })
      })
   }, [])

   return (
      <Modal
         title="Zaraportuj godziny"
         confirm_label="Zapisz"
         loading={ !days }
         onClose={ onClose }
         onConfirm={ days?.length ? () => onConfirm( days ) : undefined }
      >
         <>
            { inputs.map(({ name, label, type }) => (
               <FormInput
                  key={ name }
                  name={ name }
                  value={ String( data[ name ] || "" )}
                  label={ label }
                  type={ type }
                  onChange={ onDataChange }
               />
            ))}

            <Button
               disabled={ !data.date || !data.hours }
               style={{ marginBottom: 20 }}
               onClick={ addHours }
            > Dodaj </Button>

            { days && !!days.length && 
               <table cellPadding={ 10 } cellSpacing={0} width="100%">
                  <tbody>
                     { days.map(({ date, hours }, i ) => {
                        return (
                           <tr key={ i }>
                              <td width="200" style={{ borderBottom: "1px solid #B4B4B4" }}> { getDateString( new Date( date ))} </td>
                              <td style={{ borderBottom: "1px solid #B4B4B4" }}> <strong> { hours } </strong> </td>
                              <td width="48" style={{ borderBottom: "1px solid #B4B4B4" }}>  
                                 <SvgBtn svg="delete" onClick={() => deleteItem( i )}/>
                              </td>
                           </tr>
                        )
                     })}

                     <tr>
                        <td> RAZEM </td>
                        <td colSpan={2}>
                           <strong>
                              { total_hours }
                           </strong>
                        </td>
                     </tr>
                  </tbody>
               </table>
            }
         </>
      </Modal>
   )
}