import { useAuthContext } from "context/AuthContext";

export default function SpecialistContent() {

   const { support_forms_assignments, selectItem, authorize } = useAuthContext();

   return (
      <>
         <p> Wybierz formę wsparcia, w ramach której pracujesz </p>
      
         { support_forms_assignments?.map( item => (
            <div 
               key={ item.support_form.id } 
               className="login-choose-btn"
               onClick={() => {
                  selectItem( item );
                  authorize();
               }}   
            >
               { item.support_form.name }
            </div>
         ))}
      </>
   )
}