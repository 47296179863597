export function getParticipantAvatarURL( participant_id?: string | number | null, file_id?: string | null ) {
    return participant_id && file_id
        ? `${ process.env.REACT_APP_API_URL }/participants/${ participant_id }/avatar/${ file_id }`
        : "";
}

export function getUserAvatarURL( user_id?: string | number | null, file_id?: string | null ) {
    return user_id && file_id
        ? `${ process.env.REACT_APP_API_URL }/users/${ user_id }/avatar/${ file_id }`
        : "";
}