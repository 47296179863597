import { getParticipantAvatarURL } from "utils/avatarHelpers";
import mergeCssClasses from "utils/mergeCssClasses";
import default_avatar from "img/default-avatar.png";
import "./participant-avatar.scss";

export default function ParticipantAvatar({ id, extra_classes, avatar_file_id, full_name, onClick }: {
   id?: number;
   extra_classes?: any;
   avatar_file_id?: string | null;
   full_name?: string;
   onClick?: () => void;
}) {

   const avatar = getParticipantAvatarURL( id, avatar_file_id );

   return (
      <div className={ mergeCssClasses( "participant-avatar", extra_classes )} onClick={ onClick }>
         <img src={ avatar || default_avatar } alt="avatar"/>
         { full_name && <span> { full_name } </span> }
      </div>
   )
}