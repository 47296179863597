import mergeCssClasses from "utils/mergeCssClasses";
import "./status-box.scss";

const Labels = {
    "accepted": "Zaakceptowany",
    "rejected": "Niezaakceptowany",
    "waiting": "Oczekujący",

    "upcoming": "Oczekujący",
    "confirmed": "Potwierdzono",
    "completed": "Zakończone",
    "canceled": "Odwołane",

    "reported": "Zaraportowano",
    "not-reported": "Niezaraportowano"
}

export default function StatusBox({ status }: {
    status: string;
}) {

    return status ? (
        <div className={ mergeCssClasses( "status-box", status )}>
            <span/>        
            { Labels[ status ] || "" }
        </div>
    ) : null
}