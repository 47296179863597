import PostAPI from "api/PostAPI";
import useFetchData from "hooks/useFetchData";

export default function usePosts( params?: PostsGETPaginatedParams ) {
   return useFetchData({
      APIMethod: PostAPI.getAll,
      params: [ params ],
      debounce: true,
      clear_data_on_update: true
   })
} 