import { useState, useEffect, useRef } from "react";
import useConversation from "hooks/Messenger/useConversation";
import MessengerAPI from "api/MessengerAPI";
import Storage from 'utils/Storage';
import { useNavigate } from "react-router-dom";
import Paths from "router/Paths";
import { useTriggerMessengerBellRefresh, useMessengerEvent } from "context/AuthContext";
import Loader from "components/general/Loader";
import ConversationBoxHead from "./components/ConversationBoxHead";
import SendMessageForm from "./components/SendMessageForm";
import ConversationMessage from "./components/ConversationMessage";

import "./conversation-box.scss";

export default function ConversationBox({ user, closeConversationBox, conversation_id }: { user: UserMain | null, closeConversationBox : () => void, conversation_id : number | null }) {

    const navigate = useNavigate();
    const messages_box_ref = useRef<HTMLDivElement>( null );
    const [ page, setPage ] = useState(0);
    const [ prevUserId, setPrevUserId] = useState(0);
    const [ singleMessageChanged, setSingleMessageChanged ] = useState(false);
    const [ doNotAdjustScroll, setDoNotAdjustScroll ] = useState(false);
    const [ prevScrollHeight, setPrevScrollHeight] = useState(0);

    const { d: messages, e: error, update: updateMessages } = useConversation( user?.id, page ); 
    const [ prevMessages, setPrevMessages ] = useState<Message[]>([]);
    const [ messagesToRender, setMessagesToRender] = useState<Message[]>([]);
    const [ loadingMore, setLoadingMore ] = useState(false);
    const [ isNothingToLoadMore, setIsNothingToLoadMore ] = useState(false);

    const triggerMessengerBellRefresh = useTriggerMessengerBellRefresh();
    const messengerEvent = useMessengerEvent();

    const sendMessage = async( data: MessageDTO ) => {
        
        if ( !user ) return;

        const response = await MessengerAPI.send( user.id, data );
        if (response?.data?.message?.user_id)
        {
            setSingleMessageChanged(true);
            setMessagesToRender([...messagesToRender, response.data.message])
        }
    }

    useEffect(() => {
        if (user?.id != prevUserId)
        {
            setPrevScrollHeight(0);
            setPrevMessages([])
            setMessagesToRender([])
            setIsNothingToLoadMore(false)
            setLoadingMore(false)
            setPage(0)
            setPrevUserId(user?.['id'] || 0)
        }
    }, [user])

    useEffect(() => {
        if (doNotAdjustScroll)
        {
            setDoNotAdjustScroll(false) // when deleted by reverb - keep
        } else {
            const currentScrollHeight = messages_box_ref.current?.scrollHeight || 9999 
            const scrollTo = page < 1 || singleMessageChanged // when first load or edited/added by reverb - to very end
                ? currentScrollHeight
                : (isNothingToLoadMore
                    ? 0 
                    : (prevScrollHeight? currentScrollHeight - prevScrollHeight - 60 : 10) // when loaded previous page - keep (simulate)
                );            
            currentScrollHeight != 9999 && setPrevScrollHeight(currentScrollHeight);
            messagesToRender && messages_box_ref.current?.scroll( 0, scrollTo );
            if (singleMessageChanged)
            {
                setSingleMessageChanged(false);
            }
        }
    }, [messagesToRender])

    useEffect(() => {
        const firstLoad = messagesToRender?.length <= 0;

        if (firstLoad)
        {
            triggerMessengerBellRefresh();
            setPrevMessages(messages ? messages : []);
            setMessagesToRender(messages ? messages : []);
            if (messages && messages?.['length'] < 20)
            {
                setIsNothingToLoadMore(true)
            }
            
        } else {
            let newMessages: Message[] = [];
            let was_deleted = false

            if (messages)
            {
                 for(let message of messages)
                {
                    let found = false
                    for(let prevMessage of prevMessages)
                    {
                        if (( prevMessage?.id == message?.id ) && ( prevMessage?.is_deleted !== message?.is_deleted )) {
                           prevMessage.content = message.content;
                           prevMessage.is_deleted = message.is_deleted;
                           was_deleted = true;
                        }

                        if (prevMessage?.['id'] == message?.['id'])
                        {
                            found = true
                            break
                        }
                    }
                    !found && newMessages.push(message);
                }    
            }

            if (newMessages.length > 0 || was_deleted )
            {
                let mixedMessages = [...newMessages, ...prevMessages];
                setPrevMessages(mixedMessages);
                triggerMessengerBellRefresh();   
                setMessagesToRender(mixedMessages);                
            }

            if (messages && messages?.['length'] < 20)
            {
                setIsNothingToLoadMore(true)
            }

            setLoadingMore(false);

        }

    }, [ messages ])

    useEffect(() => {
        if (typeof messengerEvent != 'boolean')
        {

            // Current conversation is deleted - need to close it
            if (messengerEvent?.['message']?.['is_conversation_deleted'] && messengerEvent?.['message']?.['conversation_id'] == conversation_id)
            {
                closeConversationBox()
            } 


            // Message belongs to current conversation - allowed to process it
            else if (messengerEvent?.['message']?.['conversation_id'] == conversation_id)
            {   

                // Message is created or updated - need to add or update if among loaded
                if(!messengerEvent?.['message']?.['deleted_at'])
                {
                    let found = false
                    for(let messageIdx in messagesToRender)
                    {
                        if (messagesToRender[messageIdx]?.['id'] == messengerEvent?.['message']?.['id'])
                        {
                            let newMessagesToRender = [...messagesToRender]
                            newMessagesToRender[messageIdx] = messengerEvent?.['message']
                            setSingleMessageChanged(true);
                            setMessagesToRender(newMessagesToRender)
                            found = true
                            break
                        }
                    }
                    if (!found)
                    {
                        setSingleMessageChanged(true);
                        setMessagesToRender([...messagesToRender, messengerEvent?.['message']])
                    }
                } 

                // Message is deleted - remove from loaded if among
                else
                {
                    for(let messageIdx in messagesToRender)
                    {
                        if (messagesToRender[messageIdx]?.['id'] == messengerEvent?.['message']?.['id'])
                        {
                            const newMessagesToRender = [...messagesToRender]
                            newMessagesToRender.splice(parseInt(messageIdx), 1)
                            setDoNotAdjustScroll(true);
                            setMessagesToRender(newMessagesToRender)
                            break
                        }
                    }
                }
            }
        }
    }, [messengerEvent])

    useEffect(() => {        
        error && closeConversationBox() // closing conversation on being unable to complete messages API request due to http error
    }, [error])

    const deleteConversation = async user_id => {
        if ( !user ) return;

        await MessengerAPI.deleteConversation( user_id );
        closeConversationBox()
    }

    const handleScroll = event => {
        if (!loadingMore && event.currentTarget.scrollTop <= 0 && !isNothingToLoadMore)
        {
            setLoadingMore(true)
            setPage(page + 1)
        }
    };

    return user ? (
        <div className="conversation-box">

            <ConversationBoxHead
                user={ user }
                deleteConversation={ user_id => deleteConversation(user_id)}
            />

            <div 
                ref={ messages_box_ref } 
                className="conversation-box-messages"
                onScroll={handleScroll}
            >
                { !!user && (loadingMore || !messages) && <Loader/> }

                { messagesToRender && messagesToRender.map(( item, i ) => {
                    return (
                        <ConversationMessage
                            user_to_id={user.id}
                            updateMessages={() => updateMessages()}
                            key={ item.id }
                            {...item }
                            show_avatar={ item.who_read !== messagesToRender[ i + 1 ]?.who_read }
                        />
                    )
                })}
            </div>

            <SendMessageForm
                sendMessage={ sendMessage }
            />

        </div>
    ) : <div className="conversation-box" />
}