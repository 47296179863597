import { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import mergeCssClasses from "utils/mergeCssClasses";
import validateFields from "utils/validateFields";
import useSendRequest from "hooks/useSendRequest";
import useFormData from "hooks/useFormData";
import useDidChange from "hooks/useDidChange";

import PageHeader from "components/general/PageHeader";
import Loader from "components/general/Loader";
import ErrorMessage from "components/general/ErrorMessage";
import Button from "components/buttons/Button";

import "./add-edit-layout.scss";

export default function AddEditPageLayout<D>({
	default_data,
	breadcrumbsFn,
	success_message,
	error_message,
	success_go_to,
	decline_go_to,
	extra_content_props = {},
	prepareDTOFn,
	dataHook,
	Content,
	...props
}: AddEditPageLayoutProps<D> ) {
	
	const route_params = useParams();
	const navigate = useNavigate();

	const id = Number( route_params.id ) || Number( default_data?.id );
	const action = default_data?.id || id ? "edit" : "add";

	const { data, errors, onDataChange, setErrors, clearData } = useFormData( default_data );
	
	const { querying, sendRequest } = useSendRequest<D>({
		APIMethod: props.APIMethod || props.APIMethods?.[ action ],
		success_message: success_message || "Zmiany zostały pomyślnie zapisane!",
		error_message: error_message || "Zmiany nie są zapisywane!"
	})

	const hookResult = dataHook ? dataHook( id ) : { d: null, e: "", update: () => {}};
	const button_label = action === "add" ? "Dodaj" : "Zapisz";

	const heading = useMemo(() => {

		let h = props.heading || props.headings?.[ action ];

		if ( hookResult.d && h ) {
			const matches = h.matchAll(/#([^ ]+)/g );

			for ( const m of Array.from( matches )) {
				h = h.replace( m[0], hookResult.d[ m[ 1 ]])
			}
		}

		return h;

	}, [ action, hookResult.d ])


	const onSubmit = async() => {

		const APIMethod = props.APIMethod || props.APIMethods?.[ action ];
		if ( !APIMethod ) return;

		if ( props.fields_to_validate ) {
			const check = validateFields( props.fields_to_validate, data );
			setErrors( check.errors );

			if ( !check.is_valid ) return;
		}

		const dto = prepareDTOFn ? prepareDTOFn( data ) : data;

		await sendRequest( dto as D );
		success_go_to && setTimeout(() => navigate( success_go_to ), 200 );
	}

	useDidChange(() => clearData(), [ action ])

	useEffect(() => {
		if ( !hookResult.d ) return;
		onDataChange({...hookResult.d })
	}, [ hookResult.d ])


	if ( hookResult.e ) return (
		<ErrorMessage message={ hookResult.e }/> 
	)

	if (( action === "edit" && !data.id ) || props.loading )
		return <Loader style={{ margin: "auto" }}/>

	return (
		<div className={ mergeCssClasses( "add-edit-layout", props.className )}>
			
			{ heading && 
				<PageHeader 
					title={ heading }
					breadcrumbs={ breadcrumbsFn ? breadcrumbsFn( action, data ) : undefined }
				/>
			}

			{ Content && 
				<Content
					{...extra_content_props }
					action={ action }
					data={ data }
					errors={ errors }
					update={ hookResult?.update }
					onDataChange={ onDataChange }
				/>
			}
			
			<div className="bottom-buttons">
				{ querying && <Loader/> }

				{ !querying && 
					<>
						<Button
							extra_classes="transparent gray"
							onClick={() => decline_go_to ? navigate( decline_go_to ) : navigate( -1 )}
						>
							Anuluj
						</Button>

						<Button
							onClick={ onSubmit }
						> { button_label } </Button>
					</>
				} 
			</div>
		
		</div>
	)
}