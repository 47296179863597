import API from "./API";
import { CancelToken } from "axios";

export default {

   getAll: async( params: SpecialistSchedulesGETPaginatedParams = {}, cancelToken?: CancelToken ) => {
      const { data } = await API.get<GETPaginatedResponse<SpecialistSchedule>>( `/specialist-schedule`, {
         params: {
            ...params,
            month: params.month || undefined,
            year: params.year || undefined,
            support_form_id: params.support_form_id || undefined
         }, cancelToken
      })

      return data 
   },

   getCalendarDays: async( params: SpecialistCalendarDaysParams, cancelToken?: CancelToken ) => {
      const { data } = await API.get<SpecialistCalendarDays>( `/specialist-schedule/calendar`, {
         params,
         cancelToken
      })
      return data;
   },

   byId: async( id: number, cancelToken?: CancelToken ) => {
      const { data } = await API.get<SpecialistSchedule>( `/specialist-schedule/${ id }`, { cancelToken });
      return data;
   },

   add: ( data: SpecialistScheduleDTO ) => API.post( `/specialist-schedule`, data ),

   update: ( id: number, data: SpecialistScheduleDTO ) => API.post( `/specialist-schedule/${ id }`, data ),

   delete: ( id: number, delete_repeats?: boolean ) => {
      return API.delete( `/specialist-schedule/${ id }`, {
         data: { also_delete_repeats: delete_repeats }
      })
   }
}