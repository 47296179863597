import { getUserAvatarURL } from "utils/avatarHelpers";

import Box from "components/general/Box";
import FormInput from "components/form/FormInput";
import AvatarBox from "components/form/AvatarBox";

import inputs from "./inputs";
import "./user-basic-info.scss";

export default function UserBasicInfo({ data, errors, onDataChange }: { 
   data: FData;
   errors: FDataErrors;
   onDataChange: FDataOnChange;
}) {

   return (
      <Box
         heading="Dane podstawowe"
         className="user-basic-info"
      >
         <>
            <div className="user-basic-info-inputs"> 
               { inputs.map(({ name, label, type }) => {
                  return (
                     <FormInput
                        key={ name }
                        name={ name }
                        value={ String( data[ name ] || "" )}
                        label={ label }
                        error={ errors[ name ]}
                        type={ type }
                        onChange={ onDataChange }
                     />
                  )
               })}
  
               <FormInput
                     name="current_password"
                     value={ String( data.current_password || "" )}
                     label="Obecne hasło"
                     type="password"
                     error={ errors.current_password }
                     autoComplete="new-password"
                     onChange={ onDataChange }
               />
            </div>

            <AvatarBox
               name="new_avatar"
               value={ data.new_avatar instanceof File 
                  ? data.new_avatar 
                  : getUserAvatarURL( String( data.id || "" ), String( data.avatar_file_id || "" ))}
               onChange={ onDataChange }
            />
         </>
      </Box>
    )
}