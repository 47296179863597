import useSchedule from "hooks/Schedule/useSchedule";
import { getTimeDuration } from "utils/date";
import { dateISOStrToMMDDYYYY } from "utils/date";

import HookedDataTable from "layouts/HookedDataTable";
import Button from "components/buttons/Button";

const columns = [
   { name: "date", label: "Data" },
   { name: "starting_time", label: "Godzina rozpoczęcia" },
   { name: "ending_time", label: "Godzina zakończenia" },
   { name: "", label: "Czas trwania" },
   { label: "Akcje", align: "center" as const, width: 130 }
]

export default function TableView({ support_form_id, openScheduleModal, openScheduleDeleteModal }) {
   return (
      <HookedDataTable
         box_wrapper={ false }
         table_columns={ columns }
         extra_filters={{ support_form_id }}
         dataHook={ useSchedule }
         getRowsFn={( item, update ) => ({
            id: item.id,
            cells: [
               { content: dateISOStrToMMDDYYYY( item.date )},
               { content: item.starting_time.slice( 0, 5 )},
               { content: item.ending_time.slice( 0, 5 )},
               { content: getTimeDuration( item.starting_time, item.ending_time ).time },
               { actions: [
                  { svg: "edit", onClick: () => openScheduleModal({ item }, update )},
                  { svg: "delete-no-modal", onClick: () => openScheduleDeleteModal( item.id, update )},
               ]}
            ]
         })}

         BeforeTableComponent={({ update }) => (
            <div style={{ display: "flex", alignItems: "center", gap: "16px", marginBottom: "30px" }}> 
               <h2 style={{ margin: 0 }}> Terminarz specjalisty </h2>
               <Button onClick={() => openScheduleModal({}, update )}> Dodaj termin </Button>
            </div>
         )}
   />
   )
}