import { CancelToken } from "axios";
import API from "./API";
import { generateAndDownloadFile } from "utils/functions";

const FileAPI = {

	getList: async({ query, shared, ...rest }: FilesGETPaginatedParams = {}, cancelToken?: CancelToken ) => {

		const url = !shared ? `/files/list` : `/files/list/shared`;

		const { data } = await API.get<Array<FileObj | FileDirectoryObj>>( url, {
			params: {
				filters: { query },
				...rest
			},
			cancelToken
		})

		return data;
	},

	byId: async( id: string, cancelToken?: CancelToken ) => {
		const { data } = await API.get( `/files/${ id }`, { cancelToken });
		return data;
	},

	download: async( id: string, name: string ) => {
		const { data } = await API.get( `/files/${ id }/content`, {
			responseType: "arraybuffer"
		})

		generateAndDownloadFile( data, name );
	},

	uploadTemp: async( file: File ) => {
		const { data } = await API.post<FileObj>( "/files/upload-temp", { file, name: file.name }, {
			headers: { "Content-Type": "multipart/form-data" }
		});
		
		return data;
	},


	updateFewTemp: async( files: File[]) => {
		if ( !files || !files.length ) return [];

		return await Promise.all( files.map( async( f ) => {
			const { id } = await FileAPI.uploadTemp( f );
			return id;
		}))
	},

	upload: async( data: NewFileDTO ) => {

		const { data: file_obj } = await API.post<FileObj>( "/files/upload", {
			file: data.file,
			name: data.name || data.file.name,
			parent_id: data.parent_id
		}, {
			headers: { "Content-Type": "multipart/form-data" }
		});

		return file_obj;
	},

	uploadFew: async( files: NewFileDTO[]) => {
		if ( !files || !files.length ) return [];
		
		return await Promise.all( files.map( async( f ) => {
			const { id } = await FileAPI.upload( f );
			return id;
		}));
	},

	update: ( id: string, data: FileObjDTO | FileDirectoryDTO ) => API.post( `/files/${ id }`, data ), 

	createDirectory: ( data: FileDirectoryDTO ) => API.post( `/files/create-directory`, data ),

	share: ( id: string, data: FileShareDTO ) => {
		return API.post( `/files/${ id }/shares`, { 
			shares: data.users.map( id => ({
				user_id: id,
				valid_to: data.valid_to
			}))}
		)
	},

	getShareInfo: async( id: string ) => {
		const { data } = await API.get<FileShareInfo[]>( `/files/${ id }/shares` );
		return data;
	},

	delete: ( id: string ) => API.delete( `/files/${ id }`)
}

export default FileAPI;