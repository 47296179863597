import { Link, Outlet } from "react-router-dom";
import Paths from "router/Paths";
import { ReactComponent as Logo } from "svg/logo.svg";

import "./guest-layout.scss";

export default function GuestLayout() {
   
   return (
      <div className="guest-layout">
         <header>
            <Logo/>

            <Link to={ Paths.Articles.List }> E-learning </Link>
            <Link to={ Paths.Auth.Login }> Panel uczestnika </Link>
         </header>

         <main>
            <Outlet/>
         </main>

         <footer>
            <Logo/>

            <Link to={ Paths.Auth.Login }> Panel uczestnika </Link>
            <Link to={ Paths.PrivacyPolicy }> Polityka prywatności </Link>
            <Link to={ Paths.ServiceRegulation }> Regulamin serwisu </Link>
            <Link to={ Paths.AccessibilityDeclaration }> Deklaracja dostępności </Link>
         </footer>
      </div>
   )
}