import { useState } from "react";
import useSchedulesEventsAndBreaks from "hooks/Schedule/useSchedulesEventsAndBreaks";

import ScheduleCalendar from "components/general/ScheduleCalendar";

export default function CalendarView({ support_form_id, openScheduleModal }: {
   support_form_id: number;
   openScheduleModal: ( d: { item?: SpecialistSchedule, date?: string }, update: () => Promise<void> ) => void;
}) {

   const [ date, setDate ] = useState( new Date());
   
   const { schedules, events, breaks, updateSchedules } = useSchedulesEventsAndBreaks({
      date,
      support_form_id
   }, ( item ) => openScheduleModal({ item }, updateSchedules ));


   return (
      <>
         <p> Kliknij na dzień w kalendarzu, aby dodać terminarz. </p>
         
         <ScheduleCalendar
            init_date={ date }
            loading={ !schedules }
            events={ events }
            breaks={ breaks }
            onDateChange={( date ) => setDate( date )}
            onDayClick={( date: string ) => openScheduleModal({ date }, updateSchedules )}
         />
      </>
   )
}