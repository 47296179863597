import API from "./API";
import { CancelToken } from "axios";

export default {

   getAll: async( params: SpecialistSessionReviewGETPaginatedParams = {}, cancelToken?: CancelToken ) => {
      const { data } = await API.get<GETPaginatedResponse<SpecialistSessionReview>>( `/reviews/sessions`, {
         params,
         cancelToken
      });

      return data; 
   },

   getAllAny: async( params: SpecialistSessionReviewGETPaginatedParams = {}, cancelToken?: CancelToken ) => {
      const { data } = await API.get<GETPaginatedResponse<SpecialistSessionReview>>( `/reviews/sessions/any`, {
         params,
         cancelToken
      });

      return data; 
   },

   getAllForSpecialist: async( params: SpecialistSessionReviewGETPaginatedParams = {}, cancelToken?: CancelToken ) => {
      const { data } = await API.get<GETPaginatedResponse<SpecialistSessionReview>>( `/specialist-reviews/sessions`, {
         params,
         cancelToken
      });

      return data; 
   },

   response: ( id: number, answer: string ) => API.post( `/specialist-reviews/sessions/${ id }`, { answer }), 

   byId: async( id: number, cancelToken?: CancelToken ) => {
      const { data } = await API.get( `/reviews/sessions/${ id }`, { cancelToken });
      return data;
   },

   add: ( data: SpecialistSessionReviewDTO ) => API.post (`/reviews/sessions`, data ),

   update: ( id: number, data: SpecialistSessionReviewDTO ) => API.post( `/reviews/sessions/${ id }`, data )
}