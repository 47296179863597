import { useState, useEffect } from "react";
import ReservationAPI from "api/ReservationAPI";
import { getDateString } from "utils/date";
import useSupportFormSpecialist from "hooks/SupportForm/useSupportFormSpecialist";
import useCalendarDaysForCalendar from "hooks/Schedule/useCalendarDaysForCalendar";
import withModal from "hoc/withModal";

import ErrorMessage from "components/general/ErrorMessage";
import Loader from "components/general/Loader";
import PageHeader from "components/general/PageHeader";
import Box from "components/general/Box";
import FormDropdown from "components/form/FormDropdown";
import ScheduleCalendar from "components/general/ScheduleCalendar";
import ReservationModal from "components/modal/ReservationModal";

export default withModal( function MakeReservation({ openModal }) {

   const { e, support_form, specialist: default_specialist } = useSupportFormSpecialist();
   const [ specialist_id, setSpecialistId ] = useState<number | undefined>( undefined );
   const specialist = support_form?.specialists?.find( i => i.user.id === specialist_id );
   
   const [ date, setDate ] = useState( new Date());

   const { available_dates, available_items, updateCalendarDays } = useCalendarDaysForCalendar({ 
      specialist_user_id: specialist_id,
      support_form_id: support_form?.id,
      date_from: getDateString( new Date( +date - 1000*60*60*24*30 ), "YYYY-MM-DD"),
      date_to: getDateString( new Date( +date + 1000*60*60*24*30 ), "YYYY-MM-DD")
   }, ( obj ) => openReservationModal( obj ))


   const openReservationModal = ({ date, from, to }: { 
      date: string;
      from?: string;
      to?: string;
   }) => specialist && support_form && openModal({
      Component: ReservationModal,
      props: {
         add_data: {
            specialist: specialist,
            support_form: { id: support_form.id, name: support_form.name },

            date,
            from,
            to
         },

         onConfirm: async( data ) => {
            await ReservationAPI.add( data );
            updateCalendarDays();
         }
      }
   })

   useEffect(() => {
      setSpecialistId( default_specialist?.id );
   }, [ default_specialist?.id ])

   if ( e ) return <ErrorMessage message={ e }/>

   return support_form ? (
      <>
         <PageHeader
            title={`Harmonogram dla formy wsparcia ${ support_form.name }`}
            breadcrumbs={[ "SUPPORT_FORMS", `Harmonogram dla formy wsparcia ${ support_form.name }` ]}
         />

         <Box>
            <FormDropdown
               name="specialist_id"
               value={ specialist_id }
               label="Specjalista"
               options={ support_form.specialists.map( item => ({
                  value: item.user.id,
                  label: item.user.full_name
               }))}
               style={{ margin: 0 }}
               onChange={( n, v ) => setSpecialistId( v as number )}
            />
         </Box>

         <Box> 
            <p>
               Kliknij na dzień w kalendarzu, aby wybrać termin spotkania. <br/>
               Terminy zaznaczone na zielono są dostępne dla wybranego specjalisty. 
            </p>

            <ScheduleCalendar
               available_dates={ available_dates }
               available_items={ available_items }
               onDayClick={ date => openReservationModal({ date })}
               onDateChange={ date => setDate( date )}
            />
         </Box>
      </>
   ) : <Loader/>
})