import DirectorySvg from "../../svg/DirectorySvg";
import DirectoryFilledSvg from "../../svg/DirectoryFilledSvg";

export default function DirectorySidebar({ shared, onChange }: {
    shared: boolean;
    onChange: ( s: boolean ) => void;
}) {

    const folders = [
        { shared: false, label: "Moje dokumenty" },
        { shared: true, label: "Udostęponione" }
    ]

    return (
        <div className="documents-repository-sidebar">
            <div className="documents-repository-sidebar-head"> 
                <h2> Foldery </h2>
            </div>

            <div className="documents-repository-sidebar-main">
                
                { folders.map(( item, i ) => (
                    <div 
                        key={ i } 
                        className="directory" 
                        style={{ marginBottom: i === 0 ? "16px" : 0 }}
                        onClick={() => onChange( item.shared )}
                    > 
                        { shared 
                            ? item.shared ? <DirectoryFilledSvg/> : <DirectorySvg/> 
                            : item.shared ? <DirectorySvg/> : <DirectoryFilledSvg/>
                        }

                        <span> { item.label } </span> 
                    </div>
                ))}
            </div>
        </div>
    )
}