export const isObject = ( o: unknown ): o is object => toString.call( o ) === '[object Object]'

export const addZero = ( num: number | string ) => +num > 9 ? String( num ) : "0" + num;

export const getDependenciesForHook = ( p: Array<unknown>) => {

	if ( !Array.isArray( p )) return []

	return p.reduce(( all_params: Array<any>, current ) => {

		const parse = ( c: unknown ) => {
			isObject( c ) 
				? Object.values( c ).forEach( p => parse( p ))
				: all_params.push( c )
		}
		
		parse( current );
		return all_params;

	}, [])
}

export const getDebounceFn = ( fn: Function, delay = 300 ) => {

	let timer;

	return function( ...args ) {

		clearTimeout( timer ); 	
		timer = setTimeout(() => {
			fn && fn( ...args )
		}, delay )
	}
}

export const cutText = ( text: string, size: number ) => {
	if ( text.length <= size ) return text;
	return text.slice( 0, size - 3 ) + "...";
}

export const toggleArrValue = ( value: any, arr: any[] ) => {
	return arr.includes( value )
		? arr.filter( v => v !== value )
		: [...arr, value ]
}

export const filterArrByRegExp = <T>( arr: T[], search_str: string, param_name: string = "" ) => {
	return arr.filter( i => {
		
		const item = param_name ? i[ param_name ] : i;

		const reg_exp = new RegExp( search_str.trim().replace(/[-\/\\^$*+?.()|[\]{}]/gi, "\\$&" ), "gi" )
		return reg_exp.test( item )
	})
}

export const getFileAsDataUrl = ( file: File ) => new Promise<string>( resolve => {
	
	const reader = new FileReader();
	
	reader.onload = function () {
		const result = typeof reader.result === "string" ? reader.result : "";
		resolve( result )
	};
	
	file && reader.readAsDataURL( file );	
})

export const generateAndDownloadFile = ( data: BlobPart, file_name: string, type?: string ) => {
	
	const blob = new Blob([ data ], { type: type || "application/octet-stream" });
    const url = window.URL.createObjectURL( blob );
    const a = document.createElement( "a" );
    
	a.setAttribute( "href", url );
    a.setAttribute( "download", file_name );
 	a.click();

	window.URL.revokeObjectURL( url );
}

export const getFileSize = ( size_b: number ) => {
	const size_kb = Math.floor( size_b / 1024 );

	const size = size_kb >= 1024
		? ( size_kb / 1024 ).toFixed(2) + "Mb"
		: ( size_b / 1024 ).toFixed(2) + "Kb";

	return size
}