import { useMemo } from "react";
import useFormData from "hooks/useFormData";

import FormInput from "components/form/FormInput";
import Checkbox from "components/form/Checkbox";
import FormDropdown from "components/form/FormDropdown";
import Table from "components/general/Table";
import Modal from "components/modal/Modal";
import Button from "components/buttons/Button";

import time_inputs from "./time-inputs";
import validateFields from "utils/validateFields";

export default function SpecialistScheduleModal({ date, values, onClose, onConfirm }: {
    date?: string;
    values?: SpecialistSchedule;
    onClose: () => void;
    onConfirm: ( d: SpecialistScheduleFData ) => Promise<void>;
}) {

    const is_edit = !!values;
    const title = is_edit ? "Edytuj termin" : "Dodaj termin";
    
    const { data, errors, onDataChange, setErrors } = useFormData( values ? {
        date: values.date,
        starting_time: values.starting_time,
        ending_time: values.ending_time,
        is_repeating: Boolean( values.is_repeating ),
        repeat_frequency: values.repeat_frequency,
        breaks: values.breaks
    } : date ? { date } : undefined );
    
    const rows = useMemo(() => {
        
        if ( !data.breaks ) return [];
        const breaks = data.breaks as SpecialistScheduleBreak[];

        return breaks.map(( item, i ) => ({
            id: i + 1,
            cells: [
                { content: item.from },
                { content: item.to },
                { actions: [
                    { svg: "delete", onClick: () => deleteBreak( i )}
                ]}
            ]
        }))

    }, [ data.breaks ]);


    const validation = () => {

        const check = validateFields([
            { name: "date" },
            { name: "starting_time" },
            { name: "ending_time" },
            { name: "repeat_frequency", condition: [{
                name: "is_repeating",
                value: true
            }]}
        ], data )


        setErrors( check.errors );
        return check.is_valid;
    }


    const addBreak = () => {

        const breaks = ( data.breaks || []) as SpecialistScheduleBreak[];

        const from = data.break_starting_time;
        const to = data.break_ending_time;

        onDataChange({
            break_starting_time: undefined,
            break_ending_time: undefined,
            breaks: [...breaks, { from, to }]
        })
    }


    const deleteBreak = ( i: number ) => {
        let breaks = ( Array.isArray( data.breaks ) ? [...data.breaks ] : []) as SpecialistScheduleBreak[];
        breaks.splice( i, 1 );
        onDataChange({ breaks });
    }
    

    return (
        <Modal
            title={ title }
            confirm_label={ title }
            validationFn={ validation }
            onClose={ onClose }
            onConfirm={() => onConfirm({
                date: String( data.date ),
                starting_time: String( data.starting_time ).slice( 0, 5),
                ending_time: String( data.ending_time ).slice( 0, 5),
                is_repeating: !!data.is_repeating,
                repeat_frequency: !!data.is_repeating ? String( data.repeat_frequency ) : undefined,
                also_edit_repeats: !!data.also_edit_repeats,
                breaks: ( data.breaks || []) as SpecialistScheduleBreak[]
            })}
        >
            <>
                <FormInput
                    name="date"
                    value={ String( data.date || "" )}
                    type="date"
                    error={ errors.date }
                    disabled={ !!date }
                    label="Data"
                    onChange={ onDataChange }
                />

                <div style={{ display: "flex", flexWrap: "wrap", gap: "0 16px" }}> 
                    { time_inputs.map(({ name, label }) => (
                        <FormInput
                            key={ name }
                            name={ name }
                            value={ String( data[ name ] || "" )}
                            error={ errors[ name ]}
                            label={ label }
                            style={{ flex: "1 1 155px" }}
                            type="time"
                            onChange={ onDataChange }
                        />
                    ))}
                </div>

                <Checkbox
                    name="is_repeating"
                    checked={ !!data.is_repeating }
                    onClick={ onDataChange }
                > Powtarzanie </Checkbox>

                { !!data.is_repeating && 
                    <>
                        <FormDropdown
                            name="repeat_frequency"
                            value={ data.repeat_frequency }
                            error={ errors.repeat_frequency }
                            label="Częstotliwość powtarzania"
                            options={[
                                { value: "day", label: "Codziennie" },
                                { value: "week", label: "Co tydzień" },
                                { value: "month", label: "Każdego miesiąca" }
                            ]}
                            onChange={ onDataChange }
                        />
                    </>
                }

                { is_edit && 
                    <Checkbox
                        name="also_edit_repeats"
                        checked={ !!data.also_edit_repeats }
                        onClick={ onDataChange }
                    > Edytuj także powtórzenia </Checkbox>
                }

                <h2> Przerwy </h2>
                <p> Dodaj informacje o przerwach </p>

                <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center", gap: "0 16px"  }}>
                    { time_inputs.map(({ name, label }) => {

                        const name_ = "break_" + name;

                        return (
                            <FormInput
                                key={ name_ }
                                name={ name_ }
                                value={ String( data[ name_ ] || "" )}
                                label={ label }
                                style={{ flex: "1 1 155px" }}
                                type="time"
                                onChange={ onDataChange }
                            />
                        )
                    })}

                    <Button 
                        disabled={ !data.break_starting_time || !data.break_ending_time } 
                        onClick={ addBreak }
                    > Dodaj przerwę </Button>

                </div>

                <Table
                    columns={[
                        { label: "Godzina rozpoczęcia" },
                        { label: "Godzina zakończenia" },
                        { label: "Akcje", align: "center" as const }
                    ]}

                    rows={ rows }
                />
            </>
        </Modal>
    )
}