import Modal from "components/modal/Modal";
import "./delete-modal.scss";

export default function DeleteModal({ text, children, ...props }: {
    text?: string;
    children?: React.ReactNode;
    onClose: () => void;
	onConfirm: () => void | Promise<void>;
}) {
    return (
        <Modal
           className="delete-modal"
            title="Usuwanie"    
            confirm_label="Usuń" 
            {...props }
        >
            <>
                { text || "Jesteś pewien, że chcesz usunąć?" }

                { children }
            </>
        </Modal>
    )
}