import { useEffect } from "react";

import { useAuthContext } from "context/AuthContext"
import useRegulationForUser from "hooks/Regulation/useRegulationForUser";
import withModal from "hoc/withModal";

import RegistrationRegulationModal from "./components/RegistrationRegulationModal";

export default withModal<{ children: any }>( function RegulationChecker({ children, openModal }) {
   
   const { user_role, authorized } = useAuthContext();
   const slug = user_role && authorized ? `registration_${ user_role }_regulation` : "";
   
   const { d } = useRegulationForUser( slug );

   useEffect(() => {
      if ( !d?.regulation ) return;

      !d.acceptance && openModal({
         Component: RegistrationRegulationModal,
         props: { regulation: d.regulation }
      })

   }, [ d?.regulation ])

   return children;
})