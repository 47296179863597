import HTMLReactParser from "html-react-parser";
import { useLocation, useNavigate } from "react-router-dom";
import useRegulation from "hooks/Regulation/useRegulation";
import Paths from "router/Paths";
import NotLoggedIn from "layouts/NotLoggedIn";
import Loader from "components/general/Loader";

const getSlug = pathname => {
   switch ( pathname ) {
      case Paths.PrivacyPolicy: return "privacy_policy";
      case Paths.ServiceRegulation: return "general_regulations";
      case Paths.AccessibilityDeclaration: return "accessibility_declaration"
   }
}

export default function GuestRegulationView() {

   const navigate = useNavigate();
   const location = useLocation();

   const slug = getSlug( location.pathname );

   const { d: regulation } = useRegulation( slug );

   return (
      <NotLoggedIn
         no_form
         title={ regulation?.title || ''}
         links={[
            { 
               to: "#", 
               onClick: () => navigate(-1), 
               label: "Powrót na stronę główną" 
            }
         ]}
      > 
         <>
            { !!regulation?.content && 
               <div>
						{ HTMLReactParser( regulation.content )}
					</div>
            }

				{ !regulation && <Loader/> }
         </>
      </NotLoggedIn>
    )
}