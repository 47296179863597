import React from "react";
import "./loader.scss";

export default function Loader({ height, scale = 1.0, ...props }: {
	height?: number;
	scale?: number;
	style?: React.CSSProperties;
}) {
	
	const style = {
		height: height ? height + "px" : "60px",
		transform: `scale(${ scale })`,
		...( props.style || {})
	}

	return (
		<div className="loader-wrap" style={ style }>
			<div className="loader"> 
				<div/><div/><div/>
			</div>
		</div>
	)
}