import { useMemo, useState } from "react";

import { NavLink } from "react-router-dom";
import { useUserRole } from "context/AuthContext";
import mergeCssClasses from "utils/mergeCssClasses";
import useUnreadNotificationsCount from "hooks/Notification/useUnreadNotificationsCount";
import withModal from "hoc/withModal";
import Paths from "router/Paths";

import PollModal from "components/modal/PollModal";
import { ReactComponent as Logo } from "./logo.svg";
import { AngleLeftSvg } from "svg/icons";

import navigation from "./navigation";
import "./sidebar.scss";

export default withModal<{
   open: boolean;
   toggleSidebar: () => void;
}>( function Sidebar({ open, toggleSidebar, openModal }) {

   const user_role = useUserRole();

   const [refreshUnread, setRefreshUnread] = useState(false);
   const unread = useUnreadNotificationsCount(refreshUnread);
   console.log(unread)

   return useMemo(() => (
      <div className={ mergeCssClasses( "sidebar", open && "open" )}>

         <div className="sidebar-logo"> 
            <Logo/>
         </div>

         <button 
            aria-label="Toggle sidebar"
            className="sidebar-toggler"
            onClick={ toggleSidebar }
         >
            <AngleLeftSvg/>
         </button>

         <nav>
            { navigation.map( item => {

               if ( item.role && item.role !== user_role ) return null;

               return "group" in item
                  ? <span key={ item.group }> { item.group } </span>
                  : !item.polls && item.path ? (
                     <NavLink
                        end={ item.path === Paths.Home }
                        key={ item.path }
                        to={ item.path }
                        className={({ isActive }) => isActive ? "active" : "" }   
                        onClick={ () => {
                           setRefreshUnread(!refreshUnread);
                           window.innerWidth < 1330 && toggleSidebar()
                        }}
                     >
                        <span> 
                           { item.svg }
                        </span>

                        { item.title }

                        {unread > 0 && item.path == Paths.NotificationList && <div className="item-count">{unread}</div>}
                     </NavLink>
                  ) : (
                     <a 
                        href="/panel/polls"
                        key="polls" 
                        onClick={ e => {
                           e.preventDefault();

                           openModal({
                              Component: PollModal,
                              props: {}
                           })
                        }}
                     >
                        <span> { item.svg } </span>
                        { item.title }
                     </a>
                  )
            })}
         </nav>
      </div>
   ), [unread, refreshUnread, open])
})