import AddEditPageLayout from "layouts/AddEditPageLayout";
import { useAuthContext } from "context/AuthContext";

import Content from "./components/Content";

export default function MyProfile() {
    
   const { user, updateMe } = useAuthContext();

   return (
      <AddEditPageLayout<UpdateMeDTO>
         className="my-profile"
         heading="Twój profil"
         breadcrumbsFn={() => [ "Twój profil" ]}

         default_data={{
            id: user.id,
            firstname: user.firstname,
            lastname: user.lastname,
            email: user.email,
            phone: user.phone,
            avatar_file_id: user.avatar_file_id,
            description: user.description
         }}

         fields_to_validate={[
            { name: "firstname" },
            { name: "lastname" },
            { name: "email" },
            { name: "current_password" }
         ]}

         APIMethod={ data => updateMe( data )}
         Content={ Content }
      />
   )
}