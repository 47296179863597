import useFetchData from "./useFetchData";

interface useOptionsParams<D> {
	APIMethod: (...args: any ) => Promise<D>;
    resultDataHandler: ( r: D ) => Options;
    condition?: boolean;
    params?: any[]
}

export default function useOptions<D>( query: string = "", { 
    APIMethod,
    resultDataHandler,
    condition,
    params = [{ query, limit: 20 }] 
}: useOptionsParams<D> ) {

    const { d } = useFetchData<D, Options>({
        APIMethod,
        params,
        condition,
        debounce: true,
        resultDataHandler
    })

    return d;
}