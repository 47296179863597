import API from "./API";
import { CancelToken } from "axios";

export default {

   getUnfilled: async( cancelToken?: CancelToken ) => {
      const { data } = await API.get<PollUserUnfilled[]>( `/polls/unfilled`, { cancelToken })
      return data;
   },

   fill: ( id: number, data: string ) => API.post( `/polls/${ id }`, { data })
}