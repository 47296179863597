import SpecialistScheduleAPI from "api/SpecialistScheduleAPI";
import useFetchData from "hooks/useFetchData";

export default function useSchedule( params: SpecialistSchedulesGETPaginatedParams = {}) {
   return useFetchData({
      APIMethod: SpecialistScheduleAPI.getAll,
      params: [ params ],
      clear_data_on_update: true,
      debounce: true
   })
}