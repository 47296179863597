import { useAuthContext } from "context/AuthContext";
import ReservationAPI from "api/ReservationAPI";
import withModal from "hoc/withModal";

import PageHeader from "components/general/PageHeader";
import Tabs from "components/general/Tabs";

import { NoteSvg, CalendarSvg } from "svg/icons";
import ReservationModal from "components/modal/ReservationModal";

import TableView from "./tabs/TableView";
import CalendarView from "./tabs/CalendarView";
import "./reservations.scss";

export default withModal( function Reservations({ openModal }) {

   const { participant } = useAuthContext();

   const openReservationModal = ( opt: { item?: SpecialistReservation, update: () => void }) => {
        
      const { item, update } = opt;

      openModal({
         Component: ReservationModal,
         props: {
            reservation_item: item,
            onConfirm: async( d ) => {
               item 
                  ? await ReservationAPI.update( item.id, d )
                  : await ReservationAPI.add( d );
                  
               update();
            }
         }
      })
   }

   return (
      <div className="reservations-page">
         <PageHeader
            title="Twoje spotkania"
            breadcrumbs={[ "Twoje spotkania" ]}
         />

         <Tabs
            general_props={{ participant_id: participant?.id, openReservationModal }}
            tabs={[
               {
                  value: 1,
                  SVG: <NoteSvg/>,
                  label: "Widok tabeli",
                  Component: TableView
               },
               {
                  value: 2,
                  SVG: <CalendarSvg/>,
                  label: "Widok kalendarza",
                  Component: CalendarView
               }
            ]}
         />
      </div>
   )
})