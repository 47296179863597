export const format_options = [
    { value: ".jpg", label: "JPG" },
    { value: ".png", label: "PNG" },
    { value: ".jpeg", label: "JPEG" },
    { value: ".pdf", label: "PDF" },
    { value: ".docx", label: "DOCX" },
]

export const order_options = [
    { value: "asc", label: "Nazwa A-Z" },
    { value: "desc", label: "Nazwa Z-A" }
]