import { useRef, useState, useEffect } from "react";
import { createPortal } from "react-dom";
import { ReactComponent as TooltipSvg } from "./tooltip.svg";
import "./tooltip.scss";

export default function Tooltip({ children }: {
    children: React.ReactNode;
}) {

    const tooltip_ref = useRef<HTMLDivElement>( null );
    const text_el_ref = useRef<HTMLDivElement>( null )
    const [ hovered, setHovered ] = useState( false );

    useEffect(() => {

        if ( !tooltip_ref.current || !text_el_ref.current ) return;

        const tooltip_rect = tooltip_ref.current.getBoundingClientRect();
        const text_el = text_el_ref.current;
        
        text_el.style.top = ( window.scrollY + tooltip_rect.top - 10 - text_el.offsetHeight ) + "px"; 
        text_el.style.left = ( window.scrollX + tooltip_rect.left - text_el.offsetWidth / 2 ) + "px";

    }, [ hovered ])

    return (
        <div 
            ref={ tooltip_ref }
            className="tooltip" 
            
            onMouseLeave={() => {
                setHovered( false );
            }}
            
            onMouseEnter={() => {
                setHovered( true );
            }}
        >
            <TooltipSvg/>

            { hovered && 
                createPortal(
                    <div ref={ text_el_ref } className="tooltip-text">
                        { children }
                    </div>,
                    document.body
                )
            }
        </div>
    )
}