import { PointChangeTypeLabel } from "constants/PointChangeTypeLabels";
import HookedDataTable from "layouts/HookedDataTable";
import usePointChanges from "hooks/PointChange/usePointChanges";
import { getDateString } from "utils/date";
import { useAuthContext } from "context/AuthContext";

import PointsSummary from "./components/PointsSummary";

export default function PointsHistory() {

   const { participant } = useAuthContext();

   return (
      <>
         <HookedDataTable
            page_header={{
               title: "Historia punktów",
               breadcrumbs: [ "Historia punktów" ]
            }}

            extra_filters={{ participant_id: participant?.id }}

            table_columns={[
               { name: "type", label: "Rodzaj punktów" },
               { name: "", label: "Forma wsparcia" },
               { name: "created_at", label: "Data" },
               { name: "points", label: "Liczba punktów" }
            ]}

            dataHook={ usePointChanges }
            getRowsFn={( item, update, i ) => ({
               id: i,
               cells: [
                  { content: PointChangeTypeLabel[ item.type ]},
                  { content: item.support_form?.name || "" },
                  { content: getDateString( new Date( item.created_at ), "DD.MM.YYYY" )},
                  { content: item.points },
               ]
            })}

            BeforeBoxComponent={({ rows }) => (
               <PointsSummary amount={ rows?.reduce(( sum, item ) => sum + Number( item.cells[3].content ), 0 )}/>
            )}

            TFoot={ rows => rows?.length ? (
               <tr style={{ fontWeight: 600 }}>
                  <td style={{ textAlign: "right" }} colSpan={3}> OBECNA LICZBA PUNKTÓW </td>
                  <td> { rows.reduce(( sum, item ) => sum + Number( item.cells[3].content ), 0 )} </td>
               </tr>
            ) : null }
         />
      </>
   )
}