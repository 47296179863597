import { useState } from "react";
import useReservationsEvents from "hooks/Reservation/useReservationsEvents";
import ScheduleCalendar from "components/general/ScheduleCalendar";

export default function CalendarView({ participant_id, openReservationModal }) {

   const [ date, setDate ] = useState( new Date());
   const { reservations, events, updateReservations } = useReservationsEvents({
      date_obj: date,
      participant_id
   }, ( item ) => openReservationModal({ item, update: updateReservations }));

   return (
      <ScheduleCalendar
         init_date={ date }
         loading={ !reservations }
         events={ events }
         onDateChange={( date ) => setDate( date )}
      />
   )
}