import HTMLReactParser from "html-react-parser";
import useRegulation from "hooks/Regulation/useRegulation";

export default function SystemRegulation() {

   const { d } = useRegulation( "general_regulations" );

   return (
      <>
         <h2> Regulamin systemu </h2>

         { HTMLReactParser( d?.content || "" )}
      </>
   )
}