import mergeCssClasses from "utils/mergeCssClasses";
import "./box.scss";

export default function Box({ heading, className, children }: {
    heading?: string;
    className?: string;
    children: React.ReactNode;
}) {
    return (
        <div className={ mergeCssClasses( "box", className )}>

            { heading && 
                <div className="box-head"> 
                    <h2> { heading } </h2> 
                </div>
            }

            <div className="box-body"> 
                { children }
            </div>
        </div>
    )
}