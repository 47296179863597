import SupportFormAPI from "api/SupportFormAPI";
import useOptions from "hooks/useOptions";

export default function useSupportFormsOptions( query: string) {
   return useOptions( query, {
      APIMethod: SupportFormAPI.getAll,
      params: [{ name: query, limit: 20 }],
      resultDataHandler: r => r.data.map( form => ({
         value: form.id,
         label: form.name
      }))
   })
}