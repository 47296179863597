import { ReactComponent as ActiveSvg } from "./svg/active.svg";
import { ReactComponent as InactiveSvg } from "./svg/inactive.svg";

import "./activity-status.scss";

export default function ActivityStatus({ active, label }: {
    active: boolean;
    label?: string;
}) {
    return (
        <div className="activity-status">
            { active ? <ActiveSvg/> : <InactiveSvg/> }
            { label }
        </div>
    )
}