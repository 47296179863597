import { useEffect } from 'react';
import mergeCssClasses from 'utils/mergeCssClasses';
import { ArrowLeftSvg, ArrowRightSvg } from "svg/icons";
import "./pagination.scss";

export default function Pagination( props: {
	page: number;
	pages_amount: number;
	onChange: ( d: FData ) => void;
}) {

	const { page, pages_amount, onChange } = props;

	const getMiddleItems = () => {

		let start = 
			page - 1 <= 1 
				? 2 
				: page + 1 >= pages_amount
					? pages_amount - 3 > 1
						? pages_amount - 3
						: 2
					: page - 1;

		let end = start + 2 >= pages_amount ? pages_amount - 1 : start + 2;


		const pagination_items: JSX.Element[] = [];
		for ( let num = start ; num <= end; num++ ) {
			pagination_items.push( getOneItem( num ));
		}

		const prev_empty_item = start > 2 ? <span key="prev_empty" > ... </span> : null;
		const next_empty_item = end + 1 < pages_amount ? <span key="next_empty"> ... </span> : null;

		return [ prev_empty_item, ...pagination_items, next_empty_item ];
	}


	const getOneItem = ( num: number, action?: "prev" | "first" | "next" | "last", disabled?: boolean ) => {
		
		return (
			<button
				key={ action || num }
				type="button"
				disabled={ disabled }
				className={ mergeCssClasses( action, num === page && "active" )}
				onClick={ e => { 
					e.preventDefault(); 
					onChange({ page: num }); 
				}}> 
					{ !action && num } 

					{ action === "prev" && <ArrowLeftSvg/> }
					{ action === "next" && <ArrowRightSvg /> }
			</button>
		)
	}


	useEffect(() => {
		page > pages_amount && onChange({ page: pages_amount });
	}, [ page, pages_amount ])


	return pages_amount > 1 ? (
		<div className="pagination">

			{ getOneItem( page - 1, "prev", page <= 1 )}
				
			{ getOneItem( 1 )}

			{ getMiddleItems()}
				
			{ getOneItem( pages_amount )}
				
			{ getOneItem( page + 1, "next", page >= pages_amount )}
		</div>
	) : null
} 