import { useMemo } from "react";
import { DAY_NAME_SHORT, getDaysAmountInMonth, getDayProperIndex, getDateString } from "utils/date";

import ScheduleCalendarEvent from "../ScheduleCalendarEvent";
import "./tile-calendar.scss";

export default function TileCalendar({ calendar_data, events, available_dates, onDayClick }: {
   calendar_data: ScheduleCalendarData;
	events?: ScheduleCalendarEvents;
   available_dates?: string[];
	onDayClick?: ( date_iso: string ) => void;
}) {

    const days = useMemo(() => {

		const { month, year } = calendar_data;
		if ( !month || !year ) return null;

		const days_amount = getDaysAmountInMonth( month, year );

		const days: ScheduleTileCalendarDayItem[] = [];

		let days_before = getDayProperIndex( new Date(`${ year }/${ month }/01`)); 
		let days_after = 6 - getDayProperIndex( new Date(`${ year }/${ month }/${ days_amount }`));
		
        
      for (; days_before > 0; days_before-- ) {
         days.push( "placeholder" );
      }

		for ( let num = 1; num <= days_amount; num++ ) {
			
			const obj = { day: num, month, year };
			const date = getDateString( obj, "DD-MM-YYYY" );
			const date_iso = getDateString( obj, "YYYY-MM-DD" );

			days.push({ num, date, date_iso });
		}

		for (; days_after > 0; days_after-- ) {
			days.push( "placeholder" );
		}

		return days;

	}, [ calendar_data.month, calendar_data.year ])


    return (
        <div className="tile-calendar">

            <div className="tile-calendar-days">
				{ DAY_NAME_SHORT.map( name => ( <div key={ name } > { name } </div> ))}
			</div>

            <div className="tile-calendar-box">
                { days && !!days.length &&
                  days.map(( day, i ) => day !== "placeholder" ? (
                     <div 
                        key={ day.date_iso }
                        style={{ 
                           cursor: onDayClick ? "pointer" : "",
                           background: available_dates?.includes( day.date_iso ) ? "#EDF9ED" : ""
                        }}
                        onClick={() => {
                           if ( available_dates && !available_dates.includes( day.date_iso )) return;
                           onDayClick && onDayClick( day.date_iso )
                        }}
                     >
                        <span> { day.num } </span>

                        { events && events[ day.date_iso ] &&
                           events[ day.date_iso ].map(( item, i ) => (
                              <ScheduleCalendarEvent
                                 key={ day.date_iso + i }
                                 {...item }
                              />
                           ))
                        }
                     </div>
                  ) : <div key={ i }/> 
               )} 
            </div>
        </div>
    )
}