import React, { useEffect, useState, useRef } from "react";
import { getFileAsDataUrl } from "utils/functions";
import getImgFileUrl from "utils/getImgFileUrl";
import withModal from "hoc/withModal";

import FileLines from "components/general/FileLines";
import DocumentsRepoModal from "components/modal/DocumentsRepoModal";
import Button from "components/buttons/Button";
import { ReactComponent as UploadFileSvg } from "./upload-file.svg";

import "./form-attachment.scss";

interface FormAttachmentProps {
	name: string;
	value?: Array<File | FileObj>;
	multiple?: boolean;
	accept?: string;
	style?: React.CSSProperties;
	label?: string;
	text?: string | JSX.Element;
	error?: string;
	hide_doc_repo_btn?: boolean;
	onChange: ( n: string, v: Array<File | FileObj> ) => void;
}

export default withModal<FormAttachmentProps>( function FormAttachment( props ) {

	const {
		name,
		value = [],
		multiple = true,
		accept = "*",
		style,
		label,
		text,
		error,
		hide_doc_repo_btn,
		onChange,
		openModal
	} = props;

	const box_ref = useRef<HTMLDivElement>( null );
	const input_ref = useRef<HTMLInputElement>( null );
	const [ preview, setPreview ] = useState( "" );


	const dropFiles = ( e: React.DragEvent ) => {
		e.preventDefault();
		e.stopPropagation();

		if ( !e.dataTransfer.files.length ) return;
		const new_files = Array.from( e.dataTransfer.files );
		onChange( name, multiple ? [...value, ...new_files ] : new_files );
	}


	const addFromRepo = ( files: FileObj[]) => {
		
		if ( !multiple ) {
			onChange( name, [ files[0]])
		} else {
			const new_files = files.filter( f => !value.find( i => "id" in i && i.id === f.id ))
			onChange( name, [...value, ...new_files ]);
		}
	}

	const removeFile = ( i: number ) => {
		const f = [...value ];
		f.splice( i, 1 );
		onChange( name, f );

		if ( input_ref.current ) input_ref.current.value = "";
	}


	useEffect(() => {

		if ( multiple || !box_ref.current ) return;

		const file = value[0];
		if ( !file ) {
			setPreview( "" );
			return;
		}

		const is_file_img = file instanceof File && /image/.test( file.type );
		const is_file_obj_img = "id" in file && /image/.test( file.mime_type );

		if ( is_file_img ) {
			getFileAsDataUrl( file ).then( p => setPreview( p ));
			return;
		}

		if ( is_file_obj_img ) {
			const w = box_ref.current.offsetWidth;
			setPreview( getImgFileUrl( file.id, w, w / 16 * 9 ));
			return;
		}

		setPreview( "" );		

	}, [ value ])

	return (
		<div 
			className="form-attachment" 
			style={ style }
		>
			
			{ label && 
				<label>
					{ label }
				</label>
			}

			{ error && <div className="error"> { error } </div> }
			 
			<div
				ref={ box_ref }
				className="form-attachment-box"
				style={{ position: "relative" }}
				onClick={() => input_ref.current?.click()}
				onDrop={ dropFiles }
				onDragOver={ e => { 
					e.preventDefault(); 
					e.stopPropagation();
				}}	
			>

				{ preview && 
					<img src={ preview }/>
				}

				{ !preview && 
					<>
						<UploadFileSvg/>

						<div> 
							<strong> Wybierz plik z dysku </strong> <br/> 
							<span> { text } </span>
						</div>

						{ !hide_doc_repo_btn && 
							<Button onClick={ e => {
								e.preventDefault();
								e.stopPropagation();

								openModal({
									Component: DocumentsRepoModal,
									props: {
										multiple,
										onConfirm: addFromRepo
									}
								})
							}}> Wybierz z repozytorium plików </Button>
						}
					</>
				}

				<input
					ref={ input_ref }
					multiple={ multiple }
					type="file" 
					accept={ accept }
					style={{ position: "absolute", opacity: 0, width: "1px", height: "1px" }}
					onChange={ e => {
						const input_files = e.target.files;
						input_files?.length && onChange( name, multiple ? [...value, ...Array.from( input_files )] : Array.from( input_files ));

						if ( input_ref.current ) input_ref.current.value = "";
					}} 
				/>
			</div>

			{ value && !!value.length &&
				<FileLines 
					list={ value }
					onDelete={ removeFile }
				/>
			}
		</div>
	)
})