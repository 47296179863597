import API from "./API";
import { CancelToken } from "axios";

export default {

   getAll: async( params: SpecialistReservationsGETPaginatedParams = {}, cancelToken?: CancelToken ) => {
      const { data } = await API.get<GETPaginatedResponse<SpecialistReservation>>( `/reservations`, {
         params: {
            ...params,
            participant_id: params.participant_id || undefined,
            support_form_id: params.support_form_id || undefined,
            date: params.date || undefined,
            month: params.month || undefined,
            year: params.year || undefined,
            time_from: params.time_from || undefined,
            time_to: params.time_to || undefined,
            status: params.status || undefined
         }, cancelToken
      })

      return data
   },

   byId: async( id: number, cancelToken?: CancelToken ) => {
      const { data } = await API.get<SpecialistReservation>( `/reservations/${ id }`, { cancelToken });
      return data;
   },

   add: ( data: SpecialistReservationDTO ) => API.post( `/reservations`, data ),
    
   update: ( id: number, data: SpecialistReservationDTO ) => API.post( `/reservations/${ id }`, data ),
    
   delete: ( id: number ) => API.delete( `/reservations/${ id }`)
}