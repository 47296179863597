import { getUserAvatarURL } from "utils/avatarHelpers";
import default_avatar from "img/default-avatar.png";
import "./user-avatar.scss";

export default function UserAvatar({ user_id, avatar_id, full_name }: {
    user_id?: number;
    avatar_id?: string | null;
    full_name?: string;
}) {

    const user_avatar = getUserAvatarURL( user_id, avatar_id );

    return (
        <div className="user-avatar">
            <img src={ user_avatar || default_avatar } alt="avatar"/>
            { full_name && <span> { full_name } </span> }
        </div>
    )
}