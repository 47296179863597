import PostAPI from "api/PostAPI";
import useFetchData from "hooks/useFetchData";

export default function usePost( params: Partial<GETPostParams> ) {
   return useFetchData<{ post: BEPost | null, access: PostAccess }, Post | null>({
      APIMethod: PostAPI.byId,
      params: [ params ],
      condition: !!params.slug && !!params.id,
      resultDataHandler: data => data.post
   })
}