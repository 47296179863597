import getImgFileUrl from "utils/getImgFileUrl";
import Paths from "router/Paths";
import { cutText } from "utils/functions";

import Button from "components/buttons/Button";
import default_image from "./default-image.png";
import "./article-tile.scss";

export default function ArticleTile({ 
   id, 
   slug, 
   title, 
   short, 
   categories, 
   featuredImage,
   is_panel,
   openRequestModal
}: PostItem & { is_panel: boolean; openRequestModal: ( props ) => void; }) {

   return (
      <div className="article-tile">

         <div className="article-tile-thumb">
            <img src={ featuredImage?.access_token 
               ? getImgFileUrl( featuredImage?.access_token, 288, 192 )
               : default_image
            }/>
         </div>

         <div className="article-tile-categories">
               { categories && categories.map( c => (
                  <div key={ c.category_id }> { c.category.name } </div>
               ))}
         </div>

         <h3> { title } </h3>
         <p> { cutText( short || "", 140 )}</p>

         <Button
            to={ is_panel ? Paths.Articles.PanelArticleView( slug, id ) : undefined }
            extra_classes="transparent"
            onClick={ !is_panel ? () => openRequestModal({ slug, id }) : undefined }
         > 
            Czytaj więcej 
         </Button>
      </div>
   )
}