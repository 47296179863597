import { Roles } from "constants/Roles";
import withModal from "hoc/withModal";
import { useUserRole } from "context/AuthContext";

import Button from "components/buttons/Button";
import Box from "components/general/Box";
import FormInput from "components/form/FormInput";
import UserBasicInfo from "../UserBasicInfo";
import ChangePasswordModal from "../ChangePasswordModal";
import ParticipantsBox from "../ParticipantsBox";
import SpecialistWorkBox from "../SpecialistWorkBox";


export default withModal<AddEditPageLayoutContentProps>( function Content({ data, errors, onDataChange, openModal }) {

   const role = useUserRole();
   const is_family = role === Roles.Family;
   const is_specialist = role === Roles.Specialist;

   return (
      <>
         <UserBasicInfo
            data={ data }
            errors={ errors }
            onDataChange={ onDataChange }
         />

         { is_specialist && 
            <>   
               <Box heading="Notatka">
                  <>
                     <p> Opisz Specjalistę, jego zainteresowania, zakończone projekty... </p>

                     <FormInput
                        name="description"
                        value={ String( data.description || "" )}
                        label="Notatka, opis, bio"
                        type="textarea"
                        style={{ margin: 0 }}
                        input_style={{ height: "220px" }}
                        onChange={ onDataChange }
                     />
                  </>
               </Box>

               <SpecialistWorkBox/>
            </>
         }

         { is_family &&
            <ParticipantsBox/>
         }

         <Box heading="Zmień hasło">
            <p> 
               Pamiętaj, aby nie podawać swojemu hasła innym osobom. Zmieniaj swoje hasło regularnie, aby zapobiec dostępowi do Twoich danych osobom niepowołanym.
            </p>

            <Button onClick={() => openModal({
               Component: ChangePasswordModal,
               props: {}
            })}> Zmień hasło </Button>
         </Box>
      </>
   )
})