import { CalendarSvg, TimerSvg } from "svg/icons";
import { useMemo } from "react";

import Tabs from "components/general/Tabs";
import ReviewsTab from "./components/ReviewsTab";
import useSpecialistReviewsAny from "hooks/Review/useSpecialistReviewsAny";
import useSpecialistSessionReviewsAny from "hooks/Review/useSpecialistSessionReviewsAny";

export default function SpecialistViewReviews({ specialist_id, support_form_id }: {
   specialist_id: number;
   support_form_id: number;
}) {

   return useMemo(() => (
      <Tabs      
         general_props={{ specialist_id, support_form_id }}
         tabs={[
            {
               value: 1,
               label: "Oceny specjalisty",
               SVG: <TimerSvg/>,
               Component: props => (
                  <ReviewsTab
                     {...props }
                     dataHook={ useSpecialistReviewsAny }   
                  />
               )
            },
            { 
               value: 2,
               label: "Oceny Aktywności",
               SVG: <CalendarSvg/>, 
               Component: props => (
                  <ReviewsTab
                     {...props }
                     dataHook={ useSpecialistSessionReviewsAny }
                  />
               )
            }
         ]}
      />
   ), [])
}