import { useState, useMemo, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { cutText, toggleArrValue, filterArrByRegExp } from 'utils/functions';
import mergeCssClasses from 'utils/mergeCssClasses';

import { AngleDownSvg, CrossSvg } from "svg/icons";
import "./form-dropdown.scss";

export default function FormDropdown( props: FormDropdownProps ) {

	const {
		name,
		value,
		label,
		error,
		extra_classes,
		multiple,
		style, 
		disabled,
		placeholder,
		no_results_placeholder,
		max_value_label_length = 30,
		optionsHook,
		onChange
	} = props

	const dropdown_el = useRef<HTMLDivElement>( null );
	const options_box_ref = useRef<HTMLDivElement>( null );

	const [ search, setSearch ] = useState( "" );
	const [ open, setOpen ] = useState( false );

	const hook_options = optionsHook ? optionsHook( search ) : null;
	const options = hook_options && !!hook_options.length
		? hook_options
		: props.options || [];

	const value_label = useMemo(() => value 
		? Array.isArray( value )
			? options
				.filter( i => value.includes( i.value ))
				.map( i => i.label )
				.join(", ")
			: options.find( i => i.value === value )?.label 
		: ""
	, [ options, value ]);


	const filtered_options = useMemo(() => {
		if ( !search || optionsHook ) return options;
		return filterArrByRegExp( options, search, "label" );
	}, [ options, search ]);


	useEffect(() => {
		
		const checkDocumentClick = e => {
			const dropdown = dropdown_el.current;
			if ( !dropdown ) return;
			( !options_box_ref.current?.contains( e.target ) && !dropdown.contains( e.target )) && setOpen( false );
		};

		window.document.addEventListener( "click", checkDocumentClick );
		return () => window.document.removeEventListener( "click", checkDocumentClick );
	}, []);

	
	useEffect(() => {
		
		if ( !dropdown_el.current || !options_box_ref.current ) return;

		setTimeout(() => {

			if ( !dropdown_el.current || !options_box_ref.current ) return;

			const dropdown_rect = dropdown_el.current.getBoundingClientRect();
			const options_box = options_box_ref.current;
			
			options_box.style.top = window.scrollY + dropdown_rect.y + dropdown_rect.height + "px";
			options_box.style.left = window.scrollX + dropdown_rect.x + "px";
			options_box.style.width = dropdown_el.current.offsetWidth + "px";
			options_box.style.display = "block";
		}, 60 )
        
	}, [ open ])


	if ( !Array.isArray( options )) return null;

	return (
		<div 
			ref={ dropdown_el } 
			className={ mergeCssClasses( "form-dropdown", extra_classes, disabled && "disabled", error && "with-error" )}
			style={ style }
		>

			{ label && <label> { label } </label> }

			<div 
				title={ value_label || "" }
				className="form-dropdown-main"
				onClick={() => {
					if ( disabled ) return;
					
					!open && setSearch("");
					setOpen( open => !open );
				}}
			>
			
				{ !open && 
					<span style={{ opacity: value_label ? 1 : 0.6 }}> 
						{ value_label 
							? cutText( value_label, max_value_label_length ) 
							: typeof placeholder === "string" ? placeholder : "Wybierz opcję" 
						} 
					</span>
				}

				{ open && 
					<input 
						autoFocus
						placeholder={ value_label }
						value={ search }
						onKeyDown={ e => e.key === "\\" && e.preventDefault()}
						onChange={ e => setSearch( e.target.value )} 
					/> 
				}
				
				<div
					className="dropdown-main-icon"
					onClick={ e => {
						if ( disabled ) return;

						e.stopPropagation();
						!value_label ? setOpen( open => !open ) : onChange( name, multiple ? [] : undefined );
					}}
				> 
					{ value_label ? <CrossSvg/> : <AngleDownSvg/> } 
				</div>
			</div>

			{ open && 
				createPortal(
					<div ref={ options_box_ref } className="form-dropdown-options">
						{ filtered_options && !!filtered_options.length &&
							filtered_options.map(( item, index ) => {
								
								const is_active = Array.isArray( value )
									? value.includes( item.value )
									: value === item.value 

								return (
									<div 
										key={ index }
										className={ is_active ? "active": "" }
										onClick={() => {
											onChange( name, !multiple 
												? item.value 
												: toggleArrValue( item.value, Array.isArray( value ) ? value : []),
												item
											);

											!multiple && setTimeout(() => setOpen( false ), 100 );
										}}
									> 
										{ item.label } 
									</div>
								)
							})
						}

						{ !filtered_options.length && 
							<div style={{ padding: "8px 10px" }}> 
								{ no_results_placeholder || "Nie ma opcji..." } 
							</div> 
						}
					</div>, 
					document.body 
				)
			}

			{ error && <div className="error">{ error } </div> }
		</div>
	)
}