import { getDateString } from "utils/date";

import RatingBox from "components/form/RatingBox";
import "./review-box.scss";

export default function ReviewBox( props: SpecialistReview | SpecialistSessionReview ) {

   const { review, stars, created_at, specialist_answer } = props;

   return (
      <div className="review-box">
                  
         <div className="review-box-main"> 
            { "specialist_reservation" in props && 
               <div>
                  Forma wsparcia: 
                  <strong> { props.specialist_reservation?.support_form?.name } </strong>
               </div>
            }
            
            <RatingBox value={ stars }/>
            
            <div> 
               <span> { getDateString( new Date( created_at ), "DD.MM.YYYY hh:mm" )} </span>
            </div>
            
            { review }
         </div>

         { specialist_answer && 
            <div className="review-box-answer">
               <strong> Komenatrz specjalisty: </strong> <br />
               { specialist_answer }
            </div>
         }
      </div>
   )
}