import Box from "components/general/Box";
import "./points-summary.scss";

export default function PointsSummary({ amount }) {
   return (
      <Box className="points-summary">
         <> 
            <h2> Liczba wszystkich punktów: </h2> 
            <span> { amount } </span>
         </>
      </Box>
   )
}