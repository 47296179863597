import { useState, forwardRef } from "react";
import getImgFileUrl from "utils/getImgFileUrl";
import withInnerPopup from "hoc/withInnerPopup";

import Loader from "components/general/Loader";
import DirectorySvg from "../../svg/DirectorySvg";
import DocSvg from "../../svg/DocSvg";
import PDFSvg from "../../svg/PDFSvg";
import { EditSvg, DeleteSvg, DownloadSvg, ShareSvg } from "svg/actions";
import mergeCssClasses from "utils/mergeCssClasses";
import Checkbox from "components/form/Checkbox";

type ItemTileProps = ( FileObj | FileDirectoryObj ) & { 
    show_popup: boolean;
    selected?: boolean;
    setPopup: ( s: boolean ) => void;
    onOpen: () => void;
    onDownload?: () => Promise<void>;

    onEdit?: () => void;
    onShare?: () => void;
    onDelete?: () => void;
    onClick?: () => void;
}

const ItemTile = forwardRef<HTMLDivElement, ItemTileProps>(( props, ref ) => {

    const [ downloading, setDownloading ] = useState( false );

    const { show_popup, setPopup } = props;
    const { id, name, type, mime_type, selected, onOpen, onEdit, onDownload, onShare, onDelete, onClick } = props;
    const is_directory = type === "directory";

    let Preview: React.ReactNode = <DirectorySvg/>;
    if ( typeof mime_type === "string" ) {
        if ( /image/.test( mime_type )) Preview = <img src={ getImgFileUrl( id, 225 )}/>;
        else if ( mime_type === "application/pdf" ) Preview = <PDFSvg/>;
        else Preview = <DocSvg/>
    }

    const downloadFile = async() => {
        if ( !onDownload || downloading ) return;
        setDownloading( true );

        await onDownload();
        setDownloading( false );
    } 

    return (
         
        <div 
            ref={ ref }
            className={ mergeCssClasses( "item-tile", selected && "selected" )}
            onContextMenu={ e => {
                e.preventDefault();
                selected === undefined && setPopup( !show_popup ); 
            }}

            onDoubleClick={() => {
                !is_directory && downloadFile()
            }}

            onClick={() => {
                is_directory && onOpen();
                !is_directory && onClick && onClick();
            }}
        >
            <div className="item-tile-main"> 
                <div> { downloading ? <Loader/> : Preview } </div>
                <span> { name } </span>

                { selected && <Checkbox checked/>}
            </div>

            { !downloading && show_popup && 
                <div 
                    className="item-tile-popup" 
                    onClick={ e => { e.stopPropagation()}}
                >
                    
                    { onEdit && 
                        <div onClick={ onEdit }> 
                            <EditSvg/> 
                            Edytuj 
                        </div>
                    }

                    { !is_directory && 
                        <>
                            <div onClick={ downloadFile }>
                                <DownloadSvg/>
                                Pobierz    
                            </div>

                            { onShare &&
                                <div onClick={ onShare }>
                                    <ShareSvg/>
                                    Udostępnij
                                </div>
                            }
                        </>
                    }
                    
                    { onDelete && 
                        <div onClick={ onDelete }> 
                            <DeleteSvg/> 
                            Usuń 
                        </div>
                    }    
                </div>
            }
        </div>
    )
})

export default withInnerPopup( ItemTile );