import API from "./API";
import { CancelToken } from "axios";

export default {

   getAll: async({ query, role_id, ...rest }: UsersGETPaginatedParams = {}, cancelToken?: CancelToken ) => {
      const { data } = await API.get<GETPaginatedResponse<UserItem>>( `/users`, {
         params: {
            filters: {
               query,
               role_id
            }, ...rest
         }, cancelToken
      })

      return data;
   },

   byId: async( id: number, cancelToken?: CancelToken ) => {
      const { data } = await API.get<User>( `/users/${ id }`, { cancelToken });
      return data;
   }
}