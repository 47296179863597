import { SearchSvg } from "svg/icons";
import "./search-form.scss";

export default function SearchForm( props: {
    placeholder?: string;
    value?: string;
    onChange?: ( s: string ) => void;
    onSubmit?: ( form: HTMLFormElement ) => void; 
}) {

    const { placeholder, value, onChange } = props;

    const onSubmit = e => {
        e.preventDefault();
        props.onSubmit && props.onSubmit( e.target );
    }

    return (
        <form 
            className="search-form" 
            onSubmit={ onSubmit }>

            <input 
                name="s"
                value={ value }
                aria-label={ placeholder }
                placeholder={ placeholder }
                onChange={ e => onChange && onChange( e.target.value || "" )}
            />

            <button
                type="submit"
                aria-label="submit search form"
            > 
                <SearchSvg/> 
            </button>
        </form>
    )
}