import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuthContext } from "context/AuthContext";
import Paths from "router/Paths";

import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import Storage from 'utils/Storage';
import mergeCssClasses from "utils/mergeCssClasses";
import MessengerAPI from "api/MessengerAPI";

import { NotificationSvg } from "svg/icons";

window[ "Pusher" ] = Pusher;

export default function NotificationBell() {

   const { user, messengerBellTrigger, triggerMessengerRefresh } = useAuthContext();
   const [ newMessages, setNewMessages ] = useState(0);

   const access_token = Storage.get( "access_token" );

   const getNewMessages = async(messengerEvent = false) => {
      const data = await MessengerAPI.getUnread();
      setNewMessages(data?.['length'] || 0)
      messengerEvent && triggerMessengerRefresh(messengerEvent);
  }

  useEffect(() => {
      if (access_token)
      {
          window[ "Echo" ] = new Echo({
              broadcaster: 'reverb',
              forceTLS: true,
              key: process.env.REACT_APP_KEY,
              wsHost: process.env.REACT_APP_WS_HOST,
              wsPort: process.env.REACT_APP_WS_PORT || '8080',
              wssHost: process.env.REACT_APP_WSS_HOST || process.env.REACT_APP_WS_HOST,
              wssPort: process.env.REACT_APP_WSS_PORT || '443',
              enabledTransports: ['ws', 'wss'],
              authEndpoint: process.env.REACT_APP_API_URL + "/broadcasting/auth",
              auth: {
                  headers: {
                      Authorization: "Bearer " + access_token
                  }
              }
          });
      }
  }, [access_token])

  useEffect(() => {
      user && getNewMessages(true)
  }, [messengerBellTrigger])

  useEffect(() => {

      if ( !user ) return;

      getNewMessages();

      const channel = "user." + user?.id;

      window[ "Echo" ].private( channel )
      .listen('.messenger', e => {
          getNewMessages(e)
      })

      return () => {
          window[ "Echo" ]?.leaveChannel && window[ "Echo" ].leaveChannel( channel );
      }
  }, [ user ])

   return (
      <>
         <Link to={ Paths.Messenger }>
            <NotificationSvg className={ mergeCssClasses(newMessages ? 'icon-red' : 'icon-gray', ' icon-in-link') } />
            {newMessages > 0 && <div className="new-messages-count">{newMessages}</div>}
         </Link>

         <div className="separator"/>
      </>
   )
}
