import { useMemo } from "react";
import useFormData from "hooks/useFormData";
import { getDateString } from "utils/date";
import validateFields from "utils/validateFields";

import Modal from "components/modal/Modal";
import RatingBox from "components/form/RatingBox";
import FormInput from "components/form/FormInput";
import InfoLines from "components/general/InfoLines";

export default function FamilyReviewModal({ type, review, onClose, onConfirm }: {
   type: "activity" | "specialist"; 
   review?: SpecialistSessionReview | SpecialistReview;
   onClose: () => void;
   onConfirm?: ( d: SpecialistReviewDTO | SpecialistSessionReviewDTO ) => Promise<void>;
}) {

   const is_edit = !!review || !!onConfirm;

   const { data, errors, onDataChange, setErrors } = useFormData({
      review: review?.review,
      stars: review?.stars
   });

   const info = useMemo(() => {

      if ( !review ) return [];

      const info = [
         "specialist_reservation" in review 
               ? { label: "Forma wsparcia:", value: review?.specialist_reservation?.support_form?.name }
               : { label: "Specjalista:", value: review.specialist?.full_name },
         { label: "Uczestnik:", value: review?.participant?.full_name },
         { label: "Data wystawienia:", value: getDateString( new Date( review.created_at ), "DD.MM.YYYY hh:mm" )},
         { label: "Ocena:", value: <RatingBox value={ review.stars }/> },
         { label: "Opis:", value: review.review }
      ]

      review.specialist_answer && info.push({ label: "Komenatrz specjalisty:", value: review.specialist_answer })
      return info;
   }, [])

   const validation = () => {
      const check = validateFields([
         { name: "review" },
         { name: "stars" }
      ], data )

      setErrors( check.errors );
      return check.is_valid;
   }

   return (
      <Modal
         title={ type === "activity" ? "Ocena aktywności" : "Ocena specjalisty" }
         confirm_label={ is_edit ? "Zapisz zmiany" : "Dodaj ocenę" }
         validationFn={ validation }
         onClose={ onClose }
         onConfirm={ onConfirm
            ? () => onConfirm({
               review: String( data.review ),
               stars: Number( data.stars )
            })
            : undefined }
      >
         <>
            { !onConfirm && <InfoLines info={ info }/> }

            { !!onConfirm && 
               <>
                  <RatingBox
                     name="stars"
                     value={ Number( data.stars )}
                     style={{ justifyContent: "center" }}
                     extra_classes="big-stars"
                     onChange={ onDataChange }
                  />

                  <FormInput
                     name="review"
                     value={ String( data.review || "" )}
                     error={ errors.review }
                     label="Opis"
                     style={{ marginBottom: 0, marginTop: 20 }}
                     input_style={{ height: 132 }}
                     type="textarea"
                     onChange={ onDataChange }
                  />
               </>
            }
         </>
      </Modal>
   )
}