import { useMemo } from "react";
import { useAuthContext } from "context/AuthContext";
import withModal from "hoc/withModal";

import Box from "components/general/Box";
import Table from "components/general/Table";
import ActivityStatus from "components/general/ActivityStatus";

export default withModal( function ParticipantsBox({ openModal }) {

   const { participants } = useAuthContext();

   const rows = useMemo(() => {

      if ( !participants ) return null;

      return participants 
         .map( item => {
            return {
               id: item.id,
               cells: [
                  { content: item.firstname },
                  { content: item.lastname },
                  { content: <ActivityStatus active={ item.is_child === 1 }/> }
               ]
            }
         })
   }, [ participants ])


   return (
      <Box heading="Członkowie rodziny">
         <p> Dodaj informacje o członkach rodziny. Po dodaniu dane będą widoczne w poniższej tabeli. Dane można edytować w każdym momencie. </p>

         <Table
            columns={[
               { name: "", label: "Imię" },
               { name: "", label: "Nazwisko" },
               { name: "", label: "Dziecko", align: "center" as const, width: 90 }
            ]}

            rows={ rows }
         />
      </Box>
   )
})