export const family_filter_inputs = {
   0: { name: "support_form_name" },
   1: { name: "specialist_name" },
   2: { name: "review_date_from, review_date_to", type: "date-range" },
   3: { name: "stars", type: "number" }
} as TableFilterInputs

export const specialist_filter_inputs = {
   0: { name: "participant_name" },
   1: { name: "review_date_from, review_date_to", type: "date-range" },
   2: { name: "stars", type: "number" }
} as TableFilterInputs