import { DAY_NAMES, getDayProperIndex, MONTH_NAMES } from "utils/date";
import { addZero } from "utils/functions";

export const dateToCalendarData = ( date: Date ) => ({
    day: date.getDate(),
    month: date.getMonth() + 1,
    year: date.getFullYear()
})

export const calendarDataToDate = ( cd: {
    day?: number;
    month: number;
    year: number;
}) => {
    return new Date(`${ cd.year }/${ cd.month }/${ cd.day || "01" }`)
}

export const getDayLabelJSX = ( date: Date ) => (
    <>
        { DAY_NAMES[ getDayProperIndex( date )]}
        { ", " + addZero( date.getDate()) + " "}
        { MONTH_NAMES[ date.getMonth() + 1 ]}
    </>
)