
export default function NotFound() {

	return (
		<div style={{
			paddingTop: 50,
			textAlign: "center"
		}}>
			<h1>
				Błąd 404. <br /> Strona nie istnieje.
			</h1>
		</div>
	);
}