import { useState, useMemo } from "react";
import { cutText } from "utils/functions";
import Paths from "router/Paths";

import Box from "components/general/Box";
import FormInput from "components/form/FormInput";
import UserAvatar from "components/general/UserAvatar";
import ErrorMessage from "components/general/ErrorMessage";
import Button from "components/buttons/Button";

import "./specialists-box.scss";

export default function SpecialistsBox({ support_form_id, items }: {
   support_form_id: number;
   items: SupportFormSpecialist[];
}) {

   const [ search, setSearch ] = useState("");

   const specialists = useMemo(() => {
      if ( !search ) return items;

      return items.filter( item => {
         const reg = new RegExp( search, "gi" ); 
         return reg.test( item.user.full_name );
      })
   }, [ items, search ])

   return (
      <Box className="specialists-box">

         <div className="specialists-box-search">
            <FormInput
               name="s"
               value={ search }
               label="Wyszukiwarka słowna"
               placeholder="Wpisz imię, nazwisko, słowa kluczowe..."
               onChange={( n, v ) => setSearch( String( v ))}
            />
         </div>

         <div className="specialists-box-items">
            { specialists.map( item => (
               <div key={ item.user.id }>
                  <UserAvatar user_id={ item.user.id } avatar_id={ item.user.avatar_file_id }/>

                  <h4> { item.user.full_name } </h4>

                  <p> { cutText( item.user.description || "", 140 )} </p>

                  <div>
                     <Button 
                        extra_classes="transparent"
                        to={ Paths.SupportForm.SpecialistView( support_form_id, item.user.id )}   
                     > Informacje </Button>
                     
                     <Button
                        to={ Paths.SupportForm.MakeReservation( support_form_id, item.user.id )}
                     > Zapisz się </Button>

                  </div>
               </div>
            ))}
         </div>

         { !specialists.length && <ErrorMessage message="Brak treści do wyświetlenia."/>}
      </Box>
   )
}