import { useState, useMemo } from "react";
import withModal from "hoc/withModal";

import Button from "components/buttons/Button";
import SearchForm from "components/form/SearchForm";
import AddConversationModal from "../AddConversationModal";
import ConversationItem from "./components/ConversationItem";

import { useMessengerEvent } from "context/AuthContext";

import "./conversations-sidebar.scss";

export default withModal<{
    user_id?: number;
    conversations: Conversation[] | null;
    createConversation: ( d: { user_id: number; content: string }) => Promise<void>;
    onConversationClick: ( u: UserMain ) => void;
}>( function ConversationsSidebar({ user_id, onConversationClick, createConversation, openModal, ...props }) {

    const [ search, setSearch ] = useState( "" );


    const messengerEvent = useMessengerEvent();

    const conversations = useMemo(() => {

        if ( !props.conversations ) return null;
        if ( !search ) return props.conversations;

        return props.conversations.filter( item => {
            return item.user.full_name.search( search ) !== -1
        })

    }, [ props.conversations, search, messengerEvent ])


    return (
        <div className="conversations-sidebar">

            <div className="conversations-sidebar-head"> 
                <h2> Użytkownicy </h2>

                <Button onClick={() => openModal({
                    Component: AddConversationModal,
                    props: {
                        onConfirm: createConversation
                    }
                })}> Dodaj Nowego </Button>
            </div>

            <SearchForm
                placeholder="Wyszukaj użytkownika"
                onChange={ str => setSearch( str )}
            />

            <div className="conversations-sidebar-main">
                { !!conversations && conversations.map( item => (
                    <ConversationItem 
                        key={ item.id } 
                        {...item }
                        active={ item.user.id === user_id }
                        onClick={ onConversationClick }    
                    />
                ))}
            </div>
        </div>
    )
})