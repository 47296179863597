import FileAPI from "api/FileAPI";
import { getFileSize } from "utils/functions";
import { DownloadSvg } from "svg/actions";

import "./download-file-line.scss";

export default function DownloadFileLine( props: FileObj ) {

    const { id, name, file_size } = props;

    return (
        <div
            className="download-file-line"
            onClick={() => {
                FileAPI.download( id, name );
            }}
        >
            <DownloadSvg/>
            <span> 
                { name } 
                { file_size && "( " + getFileSize( file_size ) + " )"}    
            </span>
        </div>
    )
}