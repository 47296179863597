import API from "./API";
import { CancelToken } from "axios";

export default {

   bySlug: async( slug: string, cancelToken?: CancelToken ) => {
      const { data } = await API.get<Regulation>( `/regulations/${ slug }`, { cancelToken })
      return data;
   },

   bySlugForUser: async( slug: string, cancelToken?: CancelToken ) => {
      const { data } = await API.get<{
         regulation: Regulation,
         acceptance: null | { created_at: string } 
      }>( `/regulations/user/${ slug }`, { cancelToken });
      
      return data;
   },

   accept: ( slug: string ) => API.post( `/regulations/user/${ slug }/accept` )
}