import SvgBtn from "components/buttons/SvgBtn";
import "./file-lines.scss";

export default function FileLines({ list, onDelete }: {
    list: Array<File | FileObj>;
    onDelete?: ( i: number ) => void;
}) {

    return list && !!list.length ? (
        <div className="file-lines"> 
            { list.map(( item, i ) => {
                return (
                    <div key={ i }>
                        <span> { item.name } </span>
                        { onDelete && 
                            <SvgBtn 
                                svg="delete" 
                                onClick={() => onDelete( i )}
                            />
                        }
                    </div>
                )
            })}
        </div>
    ) : null
}