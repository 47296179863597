import { useParams } from "react-router-dom";
import SupportFormAPI from "api/SupportFormAPI";
import useFetchData from "hooks/useFetchData";

export default function useSupportFormSpecialist() {
   
   const params = useParams();
   const { id, specialist_id } = params;

   const { d, e } = useFetchData({
      APIMethod: SupportFormAPI.byId,
      params: [ id ],
      condition:!!id
   })

   return {
      e,
      support_form: d,
      specialist: d && specialist_id 
         ? d.specialists.find( item => item.user.id === Number( specialist_id ))?.user || null
         : null
   }
}