import { useState } from "react";
import Checkbox from "components/form/Checkbox";
import DeleteModal from "components/modal/DeleteModal";

export default function SpecialistScheduleDeleteModal({ onClose, onConfirm }: {
    onClose: () => void;
    onConfirm: ( d: boolean ) => Promise<void>;
}) {

    const [ delete_repeats, setDeleteRepeats ] = useState( false );

    return (
        <DeleteModal
            onClose={ onClose }
            onConfirm={() => onConfirm( delete_repeats )}
        >
            <Checkbox
                name="delete_repeats"
                checked={ !!delete_repeats }
                style={{ margin: "20px 0 0" }}
                onClick={( n, v ) => setDeleteRepeats( v )}
            > 
                Usuń także powtórzenia
            </Checkbox>
        </DeleteModal>
    )
}