import mergeCssClasses from "utils/mergeCssClasses";
import "./form-radio.scss";

export default function FormRadio({ label, checked, disabled, style = {}, onClick }: {
	label?: string;
	checked: boolean;
	disabled?: boolean;
	style?: React.CSSProperties;
	onClick: () => void;
}) {

	return (
		<div 
			className={ mergeCssClasses( "form-radio", checked && "checked", disabled && "disabled" )} 
			style={ style } 
			onClick={ onClick }
		>
			<div className="form-radio-btn"/>

			{ label &&
				<label> { label } </label>
			}
		</div>
	)
}