
import Loader from "components/general/Loader";
import Pagination from "components/general/Pagination";
import SvgBtn from "components/buttons/SvgBtn";
import TableFilters from "./components/TableFilters";

import { ReactComponent as AngleTopSVg } from "./svg/angle-top.svg";
import { ReactComponent as AngleDownSvg } from "./svg/angle-down.svg";

import "./table.scss";

export default function Table( props: TableProps ) {

	const {
		columns,
		rows,
		params = {},
		filters = {},
		filter_inputs,
		pages_amount,
      TFoot,
		onParamsChange,
		onFiltersChange
	} = props;


	const onSortChange = ( name: string ) => {

		let direction = "asc";

		if ( params.column === name )
			direction = params.direction === "asc" ? "desc" : "asc"

		onParamsChange && onParamsChange({
			page: 1,
			column: name,
			direction: direction
		})
	}


	return (
		<>			
			<table className="table">
				<thead>
					<tr>
						{ !!columns && columns.map(({ name, label, align, width }, i ) => {

							return (
								<th 
									key={ label + i }
									style={{ textAlign: align, width: width ? width + "px" : undefined }}
									onClick={() => name && onSortChange( name )}
								>
									<span style={{ 
										cursor: name && "pointer",
										display: name && "flex",
										justifyContent: name && align 
									}}> 
										{ label }

									 	{ name && params.column !== name && 
											<div>
												<AngleTopSVg/>
												<AngleDownSvg/>
											</div> 
										}

										{ name && params.column === name &&  
											<>
												{ params.direction === "asc" && <AngleDownSvg/> }
												{ params.direction === "desc" && <AngleTopSVg/> }
											</>
										}
									</span>
								</th>
							)
						})}
					</tr>

					{ !!filter_inputs && onFiltersChange &&
						<TableFilters
							columns={ columns }
							filters={ filters }
							filter_inputs={ filter_inputs }
							onFiltersChange={( n, v ) => { 
								onFiltersChange( n, v );
								onParamsChange && onParamsChange({ page: 1 }) 
							}}
						/>
					}
				</thead>
				
				<tbody>
					{ !!rows && !!rows.length && 
						rows.map(({ id, cells }) => {

							return ( 
								<tr key={ id }>
									{ cells && cells.map(({ content, actions }, i ) => {

										return (
											<td key={ i }> 
                                 
                                    <div className="table-td-column"> { columns[i].label } </div>
												
                                    { content } 

												{ Array.isArray( actions ) && 
													<div className="table-actions">
														{ actions.map(( btn, j ) => {
															return btn ? <SvgBtn key={ j } {...btn }/> : null
														})}
													</div>
												}	
											</td>
										)
									})}
								</tr>
							)
						})
					}
				</tbody>

            { TFoot && 
               <tfoot>
                  { TFoot( rows )}
               </tfoot>
            }
			</table>

			{ !!rows && !rows.length &&
				<div style={{ padding: "30px 0", textAlign: "center" }}> Brak treści do wyświetlenia. </div>
			}

			{ !rows && <Loader height={ 120 }/> }

			{( !!rows && params.page && pages_amount && onParamsChange ) &&
				<Pagination
					page={ params.page }
					pages_amount={ pages_amount }
					onChange={ onParamsChange }
				/>
			}
		</>
	)
}