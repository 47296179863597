import { useMemo, useState, useEffect } from "react";
import useConversations from "hooks/Messenger/useConversations";
import MessengerAPI from "api/MessengerAPI";
import PageHeader from "components/general/PageHeader";
import ConversationsSidebar from "./components/ConversationsSidebar";
import ConversationBox from "./components/ConversationBox";
import { useMessengerEvent } from "context/AuthContext";

import "./messenger.scss";

export default function Messenger() {
    
    const { d: conversations, update: updateConversations } = useConversations();
    const [ user_id, setUserId ] = useState( 0 );

    const messengerEvent = useMessengerEvent();

    const createConversation = async( d: { user_id: number, content: string }) => {
        await MessengerAPI.send( d.user_id, { content: d.content });
        await updateConversations();

        setUserId( d.user_id );
    }

    const closeConversationBox = async () => {
        await updateConversations();
        setUserId(0)
    }


    const user = useMemo<UserMain | null>(() => {

        if ( !conversations ) return null;

        const user = conversations.find( item => item.user.id === user_id )?.user;

        return user
            ? { 
                id: user.id,
                full_name: user.full_name,
                avatar_file_id: user.avatar_file_id
            } : null
    }, [ conversations, user_id ]);

    const conversation_id = useMemo<number | null>(() => {

        if ( !conversations ) return null;

        const conversation_id = conversations.find( item => item.user.id === user_id )?.id;

        return conversation_id || null
    }, [ conversations, user_id ]);

    useEffect(() => {
        updateConversations()
    }, [messengerEvent])
   
    return (
        <>
            <PageHeader
                title="Komunikator"
                breadcrumbs={[ "Komunikator" ]}
            />
            
            <div className="messenger">
                <ConversationsSidebar
                    user_id={ user?.id }
                    conversations={ conversations }
                    createConversation={ createConversation }
                    onConversationClick={ u => setUserId( u.id )}    
                />

                <ConversationBox user={ user } conversation_id={ conversation_id } closeConversationBox={ closeConversationBox }/>
            </div>
        </>
    )
}