import FormInput from "components/form/FormInput";
import FormDropdown from "components/form/FormDropdown";
import FormDateRange from "components/form/FormDateRange";

export default function TableFilters({ columns, filters, filter_inputs, onFiltersChange }: TableFiltersProps ) {
    return (
        <tr>
            { columns.map(({ label, width }, i ) => {

                const filter = filter_inputs[ i ];
                const name = filter?.name;

                const w = width ? width + "px" : undefined;

                return (
                    <th 
                        key={ label } 
                        style={{ width: w, maxWidth: w }}
                    >
                        { filter &&
                            <>
                                { filter.type !== "date-range" && !filter.options && !filter.optionsHook && 
                                    <FormInput
                                        name={ name }
                                        value={ String( filters[ name ] || "")}
                                        type={ filter.type }
                                        placeholder={ filter.placeholder }
                                        onChange={ onFiltersChange }
                                    />
                                }

                                { filter.type === "date-range" && 
                                    <FormDateRange
                                        name={ name }
                                        data={ filters }
                                        onChange={ onFiltersChange }
                                    />
                                }

                                {( filter.options || filter.optionsHook ) && 
                                    <FormDropdown
                                        name={ name }
                                        value={ filters[ name ]}
                                        placeholder={ filter.placeholder }
                                        options={ filter.options }
                                        optionsHook={ filter.optionsHook }
                                        onChange={ onFiltersChange }
                                    />
                                }
                            </>
                        }
                    </th>
                )
            })}
        </tr>
    )
}