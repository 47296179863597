import { useState } from "react";

import mergeCssClasses from "utils/mergeCssClasses";
import Sidebar from "./components/Sidebar";
import TopBar from "./components/TopBar";

import "./main-layout.scss";

export default function MainLayout({ children }) {
    
    const [ sidebar_open, setSidebarOpen ] = useState( window.innerWidth > 1330 );
    
    return (
        <div className={ mergeCssClasses( "main-layout", sidebar_open && "sidebar-open" )}>
            <Sidebar
                open={ sidebar_open }
                toggleSidebar={() => setSidebarOpen( s => !s )}
            />

            <div className="main-layout-box">

                <TopBar/>

                <main>
                    { children }
                </main>

            </div>
        </div>
    )
}