import PageHeader from "components/general/PageHeader";
import useSupportForm from "hooks/SupportForm/useSupportForm";

import Loader from "components/general/Loader";
import ErrorMessage from "components/general/ErrorMessage";
import SpecialistsBox from "./components/SpecialistsBox";

export default function SupportFormSpecialists() {

   const { d: support_form, e } = useSupportForm( "route_param" );
   const title = `Lista specjalistów - ${ support_form?.name }`;
   
   if ( e ) return (
      <ErrorMessage message={ e }/>
   )

   return support_form ? (
      <>
         <PageHeader
            title={ title }
            breadcrumbs={[ "SUPPORT_FORMS", title ]}
         />

         { !!support_form.specialists.length  
            ? <SpecialistsBox 
               support_form_id={ support_form.id } 
               items={ support_form.specialists }
            />
            : <ErrorMessage message="Ta forma wsparcia nie ma specjalistów"/> 
         }
      </>
   ) : <Loader/>
}