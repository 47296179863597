
import ErrorMessage from "components/general/ErrorMessage";
import Loader from "components/general/Loader";
import ReviewBox from "../ReviewBox";

export default function ReviewsTab({ specialist_id, support_form_id, dataHook }: {
   specialist_id: number;
   support_form_id: number;
   dataHook: ( filters: {
      specialist_id?: number;
      support_form_id?: number;
      limit: number;
   }) => ({
      d: GETPaginatedResponse<SpecialistReview | SpecialistSessionReview> | null;
      e: string;
   })
}) {

   const { d, e } = dataHook({ specialist_id, support_form_id, limit: 100 });
   const reviews = d?.data;

   if ( e ) return <ErrorMessage message={ e }/>

   return d ? (
      <>
         { reviews && !!reviews.length && 
            reviews.map( item => (
               <ReviewBox key={ item.id } {...item }/>
            ))
         }

         { !reviews?.length && <ErrorMessage message="Brak ocen"/>}
      </>
   ) : <Loader/>
}