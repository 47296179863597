export default {
   "request_access": [
      { name: "email", label: "Wpisz swój adres e-mail" },
      { name: "request_description", label: "Prośba o dostęp" }
   ],

   "have_pin": [
      { name: "email", label: "Adres e-mail" },
      { name: "pin", label: "PIN" }
   ]
}