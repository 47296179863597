import UserAPI from "api/UserAPI";
import useOptions from "hooks/useOptions";

export default function useUserOptions( query: string ) {
   return useOptions( query, {
      APIMethod: UserAPI.getAll,
      resultDataHandler: r => r.data.map( user => ({
         value: user.id,
         label: user.full_name
      }))
   })
}