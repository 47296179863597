import API from "./API";
import { CancelToken } from "axios";

export default {

   getAll: async( params: SupportFormsGETPaginatedParams = {}, cancelToken?: CancelToken ) => {
      const { data } = await API.get<GETPaginatedResponse<SupportForm>>( `/reservations/forms`, {
         params,
         cancelToken
      })

      return data;
   },

   byId: async( id: number, cancelToken?: CancelToken ) => {
      const { data } = await API.get<SupportForm>( `/reservations/forms/${ id }`, { cancelToken });
      return data;
   },

   getMyAssignments: async( params: SpecialistSupportFormsGETPaginatedParams = {}, cancelToken?: CancelToken ) => {
      const { data } = await API.get<GETPaginatedResponse<SpecialistSupportForm>>( `/support/assignments`, {
         params,
         cancelToken
      })

      return data;
   },

   addMe: ( form_id: number, dto: SupportFormSpecialistDTO ) => API.post( `/support/assignments/${ form_id }`, dto ),

   removeMe: ( form_id: number ) => API.delete( `/support/assignments/${ form_id }` )
}