import { useRef, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function withInnerPopup( Component ) {

	return function( props ) {
	
		const location = useLocation();

		const main_el_ref = useRef<HTMLDivElement>( null ); 
		const [ show_popup, setPopup ] = useState( false );

		const definePopupPosition = () => {

			if ( !main_el_ref.current ) return;
			const popup_el = main_el_ref.current?.children?.[1] as HTMLDivElement;
			if ( !popup_el ) return;

			const main_rect = main_el_ref.current.getBoundingClientRect();
			const window_bottom = window.innerHeight + window.scrollY;
			const popup_right = main_rect.left + popup_el.offsetWidth;
			const popup_bottom = main_rect.bottom + 10 + popup_el.offsetHeight + window.scrollY;

			let top = "calc(100% + 15px)";

			if ( popup_bottom > window_bottom ) {
				
				const top_num = -( popup_bottom - window_bottom - main_rect.height + 10 ); 

				if ( main_rect.top < -top_num ) {
					top = -main_rect.top + 10 + "px" 
				} else {
					top = top_num + "px"
				}
			}

			const left = popup_right > window.innerWidth ? -( popup_right - window.innerWidth + 35 + 10 ) + "px" : "0px";  
			popup_el.style.left = left;
			popup_el.style.top = top;
		}

		useEffect(() => {
			if ( !show_popup ) return;
			definePopupPosition()
		}, [ show_popup ]);

		useEffect(() => {
			setPopup( false );
		}, [ location.pathname ])

		useEffect(() => {

			const check = e => {
				if ( !main_el_ref?.current ) return;
				!( main_el_ref.current.contains( e.target )) && setPopup( false );
			}

			window.addEventListener( "click", check );
			window.addEventListener( "contextmenu", check );
			
			return () => {
				window.removeEventListener( "click", check )
				window.removeEventListener( "contextmenu", check );
			}
		})

		return (
			<Component 
				{...props } 
				show_popup={ show_popup } 
				setPopup={ setPopup } 
				ref={ main_el_ref } 
			/>
		)
	}
} 

