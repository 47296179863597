import { useState, useMemo, useEffect } from "react";
import { dateToCalendarData } from "utils/calendarHelpers";
import { getDaysAmountInMonth, getDayProperIndex, getDateString, MONTH_NAMES } from "utils/date";
import { addZero } from "utils/functions";
import mergeCssClasses from "utils/mergeCssClasses";

import { ArrowLeftSvg, ArrowRightSvg } from "svg/icons";
import "./calendar.scss";

export default function Calendar({ init_date, available_dates, selected_date, onDateChange, onDayClick }: {
    init_date?: Date;
    available_dates?: string[];
    selected_date?: string;
    onDateChange?: ( date: Date ) => void;
    onDayClick?: ( date_iso: string ) => void;
}) {

    const [ date, setDate ] = useState<Date>( init_date || new Date());
    const [ calendar_data, setCalendarDate ] = useState<ScheduleCalendarData | null>( null );

    const days = useMemo(() => {

        if ( !calendar_data ) return;

		const { month, year } = calendar_data;
		if ( !month || !year ) return null;

		const days_amount = getDaysAmountInMonth( month, year );

		const days: ScheduleTileCalendarDayItem[] = [];

		let days_before = getDayProperIndex( new Date(`${ year }/${ month }/01`)); 
		let days_after = 6 - getDayProperIndex( new Date(`${ year }/${ month }/${ days_amount }`));
		
        
        for (; days_before > 0; days_before-- ) {
            days.push( "placeholder" );
        }

		for ( let num = 1; num <= days_amount; num++ ) {
			
			const obj = { day: num, month, year };
			const date = getDateString( obj, "DD-MM-YYYY" );
			const date_iso = getDateString( obj, "YYYY-MM-DD" );

			days.push({ num, date, date_iso });
		}

		for (; days_after > 0; days_after-- ) {
			days.push( "placeholder" );
		}

		return days;

	}, [ calendar_data?.month, calendar_data?.year ])


    const changeDate = ( action: string ) => {

        if ( !calendar_data ) return;

		const cd = calendar_data;

        let new_date: Date;
        let month: number, year: number;

        switch ( action ) {
            case "prev":
                month = cd.month === 1 ? 12 : cd.month - 1;
                year = month === 12 ? cd.year - 1 : cd.year;
                break;

            case "next":
                month = cd.month === 12 ? 1 : cd.month + 1;
                year = month === 1 ? cd.year + 1: cd.year;
                break;	

            default: return;	
        }

        new_date = new Date(`${ year }/${ addZero( month )}/01`);
        setDate( new_date ); 
	}


    const onClick = ( date_iso: string ) => {
        
        if ( !onDayClick ) return;

        if ( available_dates ) {
            available_dates.includes( date_iso ) && onDayClick( date_iso );
        } else onDayClick( date_iso );
    }


    useEffect(() => {
        setCalendarDate( dateToCalendarData( date ));
        onDateChange && onDateChange( date );
    }, [ date ])


    return calendar_data ? (
        <div className="calendar">

            <div className="calendar-head">

                <button onClick={() => changeDate( "prev" )}> 
                    <ArrowLeftSvg/>
                </button>
                
                <span> { MONTH_NAMES[ calendar_data.month ]} </span>
                
                <button onClick={() => changeDate( "next" )}>
                    <ArrowRightSvg/>
                </button>
			</div>

            <div className="calendar-body">
                { days && !!days.length &&
					days.map(( day, i ) => day !== "placeholder" ? (
						<div 
							key={ day.date_iso }
                            className={ mergeCssClasses(
                                !!available_dates && !available_dates.includes( day.date_iso ) && "not-available", 
                                !!available_dates && available_dates.includes( day.date_iso ) && "available",
                                selected_date === day.date_iso && "selected" 
                            )}
							onClick={() => onClick( day.date_iso )}
						>
                            <span> { day.num } </span>
                        </div>
					) : <div key={ i }/> )
				} 
            </div>
        </div>
    ) : null
}