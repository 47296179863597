import { useMemo } from "react";
import withModal from "hoc/withModal";
import useFormData from "hooks/useFormData";

import Breadcrumbs from "components/general/Breadcrumbs";
import Button from "components/buttons/Button";
import Loader from "components/general/Loader";
import AddFilesModal from "components/modal/AddFilesModal";
import DeleteModal from "components/modal/DeleteModal";

import ItemTile from "../ItemTile";
import ItemModal from "../ItemModal";
import Filters from "../Filters";
import ItemShareModal from "../ItemShareModal";


export default withModal<{
    list: Array<FileObj | FileDirectoryObj> | null;
    shared: boolean;
    breadcrumbs: DocRepoBreadcrumbsItem[];

    createDirectory: ( d: FileDirectoryDTO ) => Promise<void>;
    updateItem: ( d: FileObjDTO & { id?: string }) => Promise<void>;
    addFiles: ( f: File[]) => Promise<void>;
    downloadFile: ( id: string, name: string ) => Promise<void>;
    shareFile: ( id: string, data: FileShareDTO ) => Promise<void>;
    deleteItem: ( id: string ) => Promise<void>;

    openDirectory: ( d: { id: string, name: string }) => void;
    onBreadcrumbClick: ( id: string ) => void;

    selected_files?: FileObj[];
    onFileSelect?: ( f: FileObj ) => void;  
}>( function Main( props ) {

    const {
        list,
        shared,
        breadcrumbs,
        createDirectory,
        updateItem,
        addFiles,
        downloadFile,
        shareFile,
        deleteItem,
        openDirectory,
        onBreadcrumbClick,
        openModal,

        selected_files,
        onFileSelect
    } = props;


    const select_mode = !!selected_files && !!onFileSelect;
    const { data: filters, onDataChange: filtersOnChange } = useFormData({
        query: "",
        format: undefined,
        order: undefined
    });
   
    const items = useMemo(() => {
        
        if ( !list ) return null;

        const directories = list.filter( item => item.type === "directory" );
        const files = list.filter( item => item.type === "file" )
        
        function sortFn( a, b ) {
            return filters.order === "desc" 
                ? a.name > b.name ? -1 : 1
                : a.name > b.name ? 1 : -1
        }

        filters.order && directories.sort( sortFn ); 
        filters.order && files.sort( sortFn );

        let items = [...directories, ...files ];

        const query = filters.query as string;
        if ( query ) {
            items = items.filter( item => {
                return new RegExp( query, "ig" ).test( item.name )
            })
        }

        const format = filters.format as string;
        if ( format ) {
            items = items.filter( item => {
                if ( !item.mime_type ) return false ;
                
                const reg = new RegExp( format + "$" );
                return reg.test( item.mime_type ) || reg.test( item.name );
            })
        }

        return items;

    }, [ filters.query, filters.order, filters.format, list ])

    
    return (
        <div className="documents-repository-box">

            <Breadcrumbs 
                hide_home 
                breadcrumbs={ breadcrumbs.map( item => ({
                    svg: item.svg,
                    label: item.label,
                    onClick: () => onBreadcrumbClick( item.id )
                }))}
            />

            <Filters
                data={ filters }
                onDataChange={ filtersOnChange }
            />

            <div className="documents-repository-box-head">
                <h2> { shared ? "Udostępnione pliki" : "Twoje pliki" } </h2>
                
                { !shared && !select_mode &&
                    <>
                         <Button onClick={() => openModal({
                            Component: AddFilesModal,
                            props: {
                                hide_doc_repo_btn: true,
                                onConfirm: addFiles
                            }
                        })}> Dodaj Pliki </Button>
                        
                        <Button onClick={() => openModal({
                            Component: ItemModal,
                            props: { onConfirm: createDirectory }
                        })}> Dodaj folder </Button>
                    </>
                }
               
            </div>

            <div className="documents-repository-box-main">
                { !!items && items.map( item => {
                    
                    return (
                         <ItemTile 
                            key={ item.id } 
                            {...item }

                            selected={ selected_files ? !!selected_files.find( j => item.id === j.id ) : undefined }

                            onOpen={() => openDirectory({ id: item.id, name: item.name })}    
                            onDownload={ !select_mode ? () => downloadFile( item.id, item.name ) : undefined }

                            onClick={ item.type === "file" && onFileSelect ? () => onFileSelect( item ) : undefined }

                            onEdit={ shared || select_mode ? undefined : () => openModal({
                                Component: ItemModal,
                                props: {
                                    values: {...item },
                                    onConfirm: updateItem
                                }
                            })}

                            onShare={ shared || select_mode ? undefined : () => openModal({
                                Component: ItemShareModal,
                                props: { 
                                    id: item.id,
                                    onConfirm: ( d ) => shareFile( item.id, d )
                                }
                            })}

                            onDelete={ shared || select_mode ? undefined : () => openModal({
                                Component: DeleteModal,
                                props: {
                                    onConfirm: () => deleteItem( item.id )
                                }
                            })}
                        />
                    )
                })}
            </div>

            { items && !items.length &&
                <div> 
                    {( filters.query || filters.format ) 
                        ? "Brak treści do wyświetlenia."
                        : shared 
                            ? "Brak udostępnionych plików!" 
                            : "Ten katalog jest pusty! Dodaj kilka plików, aby je wypełnić!" 
                    } 
                </div>
            }

            { !items && <Loader/> }
        </div>
    )
})