import API from "./API";
import { CancelToken } from "axios";

export default {

   getCalendarDays: async( params: SpecialistCalendarDaysParams & { specialist_id: number }, cancelToken?: CancelToken ) => {
      const { data } = await API.get<SpecialistCalendarDays>( `/reservations/schedule/calendar`, {
         params,
         cancelToken
      })
      return data;
   },
}