import { Roles } from "constants/Roles";
import Paths from "router/Paths";

import { 
   MonitorSvg, NoteSvg, ClockSvg, MapSvg, CalendarSvg, DocumentTextSvg, TaskSquareSvg,
   MessagesSvg, FolderOpenSvg, Note2Svg, LikeDislikeSvg, SecuritySafeSvg, ArchiveSvg
} from "svg/icons";

export default [
   {
      path: Paths.Home,
      title: "Strona główna",
      svg: <MonitorSvg/>
   },
   
   { group: "Formy wsparcia" },
   {
      path: Paths.Reservations,
      title: "Twoje spotkania",
      svg: <DocumentTextSvg/>,
      role: Roles.Family
   },
   {
      path: Paths.SupportForm.List,
      title: "Formy wsparcia",
      svg: <NoteSvg/>,
      role: Roles.Family
   },
   {
      path: Paths.Schedule,
      title: "Teminarz",
      svg: <CalendarSvg/>,
      role: Roles.Specialist
   },
   { 
      path: Paths.SpecialistReservations,
      title: "Zapisy uczestników",
      svg: <DocumentTextSvg/>,
      role: Roles.Specialist
   },
   {
      path: Paths.WorkingTime,
      title: "Czas pracy",
      svg: <ClockSvg/>,
      role: Roles.Specialist
   },
   
   { group: "Punkty", role: Roles.Family },
   {
      path: Paths.PointsHistory,
      title: "Historia punktów",
      svg: <MapSvg/>,
      role: Roles.Family
   },

   { group: "Komunikator" },
   {
      path: Paths.Messenger,
      title: "Komunikator",
      svg: <MessagesSvg/>
   },

   { group: "Dokumenty" },
   {
      path: Paths.DocumentsRepository,
      title: "Repozytorium dokumentów",
      svg: <FolderOpenSvg/>
   },

   { group: "Powiadomienia" },
   {
      path: Paths.NotificationList,
      title: "Lista powiadomień",
      svg: <Note2Svg/>,
   },

   { group: "Opinie i ankiety" },
   {
      path: Paths.Opinions,
      title: "Opinie",
      svg: <LikeDislikeSvg/>
   },
   {
      polls: true,
      title: "Ankiety",
      svg: <TaskSquareSvg/>,
      role: Roles.Family
   },
   { group: "E-learning" },
   {
      path: Paths.Regulations,
      title: "Regulaminy",
      svg: <SecuritySafeSvg/>,
      role: Roles.Specialist
   },
   {
      path: Paths.Articles.PanelArticles,
      title: "Lista artykułów",
      svg: <ArchiveSvg/>
   }
]