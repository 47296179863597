import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import AuthContextProvider from 'context/AuthContext';
import Routes from 'router/Routes';

import "styles/main.scss";
import "styles/toast.scss";

export default function App() {

   const location = useLocation();

   useEffect(() => {
	   const setVh = () => {
			const vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty('--vh', `${ vh }px`);
		}

		setTimeout(() => setVh(), 10 );		
		window.addEventListener( "resize", setVh );
		return () => window.removeEventListener( "resize", setVh )
	}, [])

   useEffect(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
   }, [ location.pathname ])

   return (
      <AuthContextProvider>
         <Routes/>
      </AuthContextProvider>
   )
}