export const family_columns = [
   { label: "Forma wsparcia" },
   { label: "Specialista"}
]

export const specialist_columns = [
   { label: "Uczestnik wystawiający opinię" }
]

export const columns = [
   { label: "Data wystawienia", width: 310 },
   { label: "Ocena" },
   { label: "Akcje", align: "center" as const, width: 100 }
]