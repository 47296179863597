import { useState, useRef, useEffect, useMemo } from "react";
import { createPortal } from "react-dom";
import { dateISOStrToMMDDYYYY, getDaysAmountInMonth } from "utils/date";
import { addZero } from "utils/functions";

import Calendar from "components/general/Calendar";
import { CalendarSvg } from "svg/icons";
import "./form-calendar-input.scss";

export default function FormCalendarInput( props: {
    name: string;
    init_date?: Date;
    value?: string;
    label?: string;
    available_dates?: string[];
    onChange: ( n: string, v: string ) => void;
    onDateRangeChange?: ( from: string, to: string ) => void;
}) {

    const {
        name,
        init_date,
        value,
        label,
        available_dates,
        onChange,
        onDateRangeChange
    } = props;

    const form_calendar_ref = useRef<HTMLDivElement>( null );
    const popup_ref = useRef<HTMLDivElement>( null );

    const [ date, setDate ] = useState( init_date || new Date());
	const [ open, setOpen ] = useState( false );

    const placeholder = useMemo(() => {
        return value ? dateISOStrToMMDDYYYY( value ) : "Wybierz datę"
    }, [ value ])


    useEffect(() => {
		
		setTimeout(() => {

    		if ( !form_calendar_ref.current || !popup_ref.current ) return;

			const rect = form_calendar_ref.current.getBoundingClientRect();
			const popup = popup_ref.current;
			
			popup.style.top = window.scrollY + rect.y + rect.height + "px";
			popup.style.left = window.scrollX + rect.x + "px";
			popup.style.display = "block";
		}, 60 )
	}, [ open ])


    useEffect(() => {
		
		const checkDocumentClick = e => {
			( !popup_ref.current?.contains( e.target ) && !form_calendar_ref.current?.contains( e.target )) && setOpen( false );
		};

		window.document.addEventListener( "click", checkDocumentClick );
		return () => window.document.removeEventListener( "click", checkDocumentClick );
	}, []);


    useEffect(() => {

        if ( !onDateRangeChange ) return;

        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const days_amount = getDaysAmountInMonth( month, year );

        onDateRangeChange( 
            `${ year }-${ addZero( month )}-01`, 
            `${ year }-${ addZero( month )}-${ days_amount }`
        )

    }, [ date ])


    return (
        <div ref={ form_calendar_ref } className="form-calendar-input">
            
            { label && 
				<label> 
					{ label }
				</label>
			}
			
			<div className="form-calendar-input-box" onClick={() => setOpen( o => !o )}> 
				<span style={{ opacity: value ? 1 : 0.7 }}> { placeholder } </span>
				<CalendarSvg/>
			</div>

            { open && createPortal(
                <div ref={ popup_ref } className="form-calendar-input-popup">
                    <Calendar
                        init_date={ date }
                        selected_date={ value }
                        available_dates={ available_dates }
                        onDateChange={ d => setDate( d )}
                        onDayClick={( date_iso ) => onChange( name, date_iso )}
                    />
                </div>, document.body
            )}
        </div>
    )
}