import React, { useState } from "react";
import mergeCssClasses from "utils/mergeCssClasses";
import { ReactComponent as EyeSvg } from "./svg/eye.svg";
import { ReactComponent as EyeCrossedOutSvg } from "./svg/eye-crossed-out.svg";
import Tooltip from "components/general/Tooltip";


import "./form-input.scss";

export default function FormInput({ label, error, style, tooltip, input_style, ...input_props }: FormInputProps ) {
	
	const [ show_password, setShowPassword ] = useState( false );
	const is_password = input_props.type === "password";
	const InputTag = input_props.type === "textarea" ? "textarea" : "input";

	const onChange = e => input_props.onChange && input_props.onChange( input_props.name, e.target.value );

	return (
		<div 
			className={ mergeCssClasses( "form-input", error && "with-error" )}
			style={ style }
		>
			
			{ label && 
				<label> 
					{ label }

					{ tooltip && <Tooltip> { tooltip } </Tooltip> }
				</label>
			}
			
			<div className="form-input-box"> 
				<InputTag 
					{...input_props }
					type={ is_password 
						? show_password ? "text" : "password"
						: input_props.type 
					}
					aria-label={ label }
					style={ input_style }
					onChange={ onChange }
				/>

				{ is_password && 
					<button 
						type="button" 
						aria-label="show/hide password"
						onClick={() => setShowPassword( s => !s )}
					>
						{ show_password ? <EyeCrossedOutSvg/> : <EyeSvg/> }
					</button>
				}
			</div>

			{ error && 
				<div className="error"> { error } </div>
			}
	
		</div>
	)
}