import { forwardRef } from "react";
import { useAuthContext } from "context/AuthContext";
import ParticipantAvatar from "components/general/ParticipantAvatar";
import withInnerPopup from "hoc/withInnerPopup";

import { AngleDownSvg } from "svg/icons";
import "./participants-widget.scss";

const ParticipantsWidget = forwardRef<HTMLDivElement, any>(({ show_popup, setPopup }, ref ) => {

   const { participants, participant, selectItem } = useAuthContext();

   return !!participants?.length ? (
      <>
         <div ref={ ref } className="participants-widget" onClick={() => setPopup( s => !s )}>

            <span> 
               <span> Uczestnik: </span>
               <ParticipantAvatar {...participant }/>
               <AngleDownSvg style={{ transform: show_popup ? "rotate(180deg)" : "" }}/>
            </span>

            { show_popup && 
               <div className="participants-widget-popup">
                  <strong> Zmień uczestnika </strong>

                  { participants && participants.map( item => (
                     <ParticipantAvatar 
                        key={ item.id }
                        id={ item.id }
                        full_name={ item.full_name }
                        avatar_file_id={ item.avatar_file_id }
                        extra_classes={ item.id === participant?.id && "active" }
                        onClick={() => selectItem( item )}
                     />
                  ))}
               </div>
            }
         </div>

         <div className="separator"/>
      </>
   ) : null
})

export default withInnerPopup( ParticipantsWidget );