enum Roles {
    Admin = "super_admin",
    Individual = "individual",
    Specialist = "specialist",
    Family = "family"
}

enum RolesLabels {
    "super_admin" = "Administrator",
    "individual" = "Konto indywidualne",
    "specialist" = "Specjalista",
    "family" = "Konto rodzinne"
}

export { Roles, RolesLabels }