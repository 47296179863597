import { useMemo } from "react";
import { MONTH_NAMES, getWeek } from "utils/date";
import { addZero } from "utils/functions";
import { getDayLabelJSX } from "utils/calendarHelpers";

import DateSwitch from "../DateSwitch";
import ModeSwitch from "../ModeSwitch";

export default function ScheduleCalendarHead({ hide_date_switch, hide_mode_switch, mode, date, cd, onDateChange, onModeChange }: {
    hide_date_switch?: true;
    hide_mode_switch?: true;
    mode: ScheduleCalendarMode;
    date: Date;
    cd: ScheduleCalendarData;
    onDateChange: ( date: Date ) => void;
    onModeChange: ( m: ScheduleCalendarMode ) => void;
}) {
    
    const DateLabel = useMemo(() => {

        const week = getWeek( date );

        switch ( mode ) {
            case "month": return <> { MONTH_NAMES[ cd.month ]} { cd.year } </>
            case "week": return (
                <>
                    { addZero( week[0].date.getDate())}.
                    { addZero( week[0].date.getMonth() + 1 )}
                    {" - "}
                    { addZero( week[6].date.getDate())}.
                    { addZero( week[6].date.getMonth() + 1 )}
                </>
            )

            case "day": return getDayLabelJSX( date );
        }

    }, [ date, cd, mode ])

    return (
        <div className="schedule-calendar-head"> 
            <div className="schedule-calendar-head-main">
                { !hide_date_switch &&
                    <DateSwitch
                        mode={ mode }
                        date={ date }
                        calendar_data={ cd }
                        onChange={ onDateChange }
                    />
                }

                <h2> { DateLabel }  </h2>
            </div>

            { !hide_mode_switch &&
                <ModeSwitch
                    mode={ mode }
                    onClick={ onModeChange }
                />
            }
        </div>
    )
}