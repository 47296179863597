import Paths from "router/Paths";
import UserAvatar from "components/general/UserAvatar";
import Button from "components/buttons/Button";
import SpecialistBoxReview from "../SpecialistBoxReview";

import { PhoneSvg, EnvelopeSvg } from "svg/icons";
import "./specialist-box.scss";

export default function SpecialistBox({ specialist, support_form_id, support_form_name }: {
   specialist: User;
   support_form_id: number;
   support_form_name: string;
}) {

   const { id, avatar_file_id, description, phone, email } = specialist;   
   
   return (
      <div className="specialist-box">

         <UserAvatar 
            user_id={ id } 
            avatar_id={ avatar_file_id }
         />

         <div className="specialist-box-main">
            <p style={{ marginTop: 0 }}> <strong> { support_form_name } </strong> </p>

            { description }

            <div className="specialist-box-bottom">
               <div> 
                  <h3> Kontakt </h3>

                  { phone && 
                     <a href={`tel:${ phone.replace(/[ -]/g, "" )}`}>
                        <PhoneSvg/> { phone }
                     </a>
                  }

                  { email &&
                     <a href={`mailto:${ email }`}>
                        <EnvelopeSvg/> { email }
                     </a>
                  }

                  <Button
                     to={ Paths.SupportForm.MakeReservation( support_form_id, id )}
                  > Zapisz się na spotkanie </Button>
               </div>
               
               <SpecialistBoxReview specialist_id={ id }/>
               
            </div>
         </div>
      </div>
   )
}