import API from "./API";
import { CancelToken } from "axios";

export default {

   getAll: async( params: SpecialistReviewsGETPaginatedParams = {}, cancelToken?: CancelToken ) => {
      const { data } = await API.get<GETPaginatedResponse<SpecialistReview>>( `/reviews/specialists`, {
         params,
         cancelToken
      });

      return data;
   },

   getAllAny: async( params: SpecialistReviewsGETPaginatedParams = {}, cancelToken?: CancelToken ) => {
      const { data } = await API.get<GETPaginatedResponse<SpecialistReview>>( `/reviews/specialists/any`, {
         params,
         cancelToken
      });

      return data;
   },

   getAllForSpecialist: async( params: SpecialistReviewsGETPaginatedParams = {}, cancelToken?: CancelToken ) => {
      const { data } = await API.get<GETPaginatedResponse<SpecialistReview>>( `/specialist-reviews/specialists`, {
         params,
         cancelToken
      });

      return data;
   },

   response: ( id: number, answer: string ) => API.post( `/specialist-reviews/specialists/${ id }`, { answer }),

   byId: async( id: number, cancelToken?: CancelToken ) => {
      const { data } = await API.get<SpecialistReview>( `/reviews/specialists/${ id }`, { cancelToken });
      return data;
   },

   add: ( data: SpecialistReviewDTO ) => API.post( `/reviews/specialists`, data ),

   update: ( id: number, data: SpecialistReviewDTO ) => API.post( `/reviews/specialists/${ id }`, data )
}