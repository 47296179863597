import { Fragment } from "react";
import FileAPI from "api/FileAPI";
import MessengerAPI from "api/MessengerAPI";
import UserAvatar from "components/general/UserAvatar";
import { useUser } from "context/AuthContext";
import mergeCssClasses from "utils/mergeCssClasses";
import getImgFileUrl from "utils/getImgFileUrl";
import { getDateString } from "utils/date";
import { DeleteSvg } from "svg/actions";

import { DocumentTextSvg } from "svg/icons";
import "./conversation-message.scss";

export default function ConversationMessage( props: Message & { show_avatar: boolean; user_to_id: number; updateMessages: () => void}) {
    
    const user = useUser();
    const { content, attachments, show_avatar, user_to_id, id, updateMessages } = props; 

    return content ? (
        <div className={ mergeCssClasses( "conversation-message", user.id !== props.user_id && "reverse" )}>

            { user.id === props.user_id   
                ? <span className={"delete"}>
                    <div 
                        className={"delete-icon"}
                        onClick={() => MessengerAPI.deleteMessage(user_to_id, id).then(() => updateMessages())}
                    >
                        <DeleteSvg/>
                    </div>
                    <div className={"delete-date"}>{getDateString( new Date( props.created_at ), "DD.MM.YYYY hh:ss" )}</div>
                </span>
                : <span/>
            }

            <div className="conversation-message-content">  
                { content }
            </div>

            { show_avatar  
                ? <UserAvatar avatar_id=""/>
                : <span/>
            }

            { attachments && !!attachments.length &&
                <div className="conversation-message-attachments"> 
                    { attachments.map( att => {

                        const is_image = /^image/.test( att.mime_type );

                        return (
                            <Fragment key={ att.id }>
                                { is_image && <img src={ getImgFileUrl( att.id, 400, 400 )}/> }

                                <div 
                                    className="conversation-message-attach" 
                                    onClick={() => FileAPI.download( att.id, att.name )}
                                >
                                    
                                    <div> <DocumentTextSvg/> </div>
                                    <span> { att.name } </span>
                                </div>
                            </Fragment>
                        )
                    })}
                </div>
            }
        </div>
    ) : null
}