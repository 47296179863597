import withModal from "hoc/withModal";
import DeleteModal from "components/modal/DeleteModal";

import { EditSvg, DeleteSvg, DownloadSvg, ViewSvg, ShareSvg } from "svg/actions";
import { ProfileSvg, CalendarSvg } from "svg/icons";
import "./svg-btn.scss";

const getLabel = ( svg: string ) => {
	switch ( svg ) {
		case "add": return "Dodaj";
		case "edit": return "Edytuj";
		case "view": return "Podgląd";
		case "download": return "Pobierz";
		case "profile": return "Profil";
		case "share": return "Udostępnij";
		case "calendar": return "";

		case "delete-no-modal":
		case "delete": return "Usuń";

		default: return "";
	}
}

const getSvg = ( svg: string ) => {
	switch ( svg ) {
		case "edit": return <EditSvg/>;
		case "view": return <ViewSvg/>;
		case "download": return <DownloadSvg/>;
		case "profile": return <ProfileSvg/>;
		case "share": return <ShareSvg/>;
		case "calendar": return <CalendarSvg/>;

		case "delete-no-modal":
		case "delete": return <DeleteSvg/>;

		default: return "";
	}
}

export default withModal<SvgBtnProps>(( props ) => {

	const { svg, title, modal_text, openModal, onClick, ...rest } = props;

	return (
		<button 
			type="button" 
			className="svg-btn"
			aria-label={ title || getLabel( svg )}
			title={ title || getLabel( svg )}
			{...rest }
			onClick={ e => {
				e.stopPropagation();
				
				svg === "delete" || svg === "cross"
					? openModal({
						Component: DeleteModal,
						props: {
							text: modal_text,
							onConfirm: onClick
						}
					})
					: onClick();
			}}	
		>
			{ getSvg( svg )}
		</button>
	)
});