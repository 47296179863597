import { useState, useEffect } from "react";
import { dateToCalendarData, calendarDataToDate } from "utils/calendarHelpers";

import ScheduleCalendarHead from "./components/ScheduleCalendarHead";
import TileCalendar from "./components/TileCalendar";
import DayWeekTable from "./components/DayWeekTable";

import "./schedule-calendar.scss";

export default function ScheduleCalendar({
    init_date,
    init_mode,
    init_calendar_data,
    loading,
    events,
    breaks,
    hide_date_switch,
    hide_mode_switch,
    available_dates,
    available_items,
    onDayClick,
    onDateChange
}: ScheduleCalendarProps ) {

    const [ mode, setMode ] = useState<ScheduleCalendarMode>( init_mode || "month" ); 

    const [ date, setDate ] = useState<Date>( init_date || ( init_calendar_data ? calendarDataToDate( init_calendar_data ) : new Date()));
    const [ calendar_data, setCalendarDate ] = useState<ScheduleCalendarData | null>( null );


    useEffect(() => {
        setCalendarDate( dateToCalendarData( date ))
    }, [ date ])


    return calendar_data ? (
        <div className="schedule-calendar">
            
            <ScheduleCalendarHead
                hide_date_switch={ hide_date_switch }
                hide_mode_switch={ hide_mode_switch }
                mode={ mode }
                date={ date }
                cd={ calendar_data }
                onDateChange={ d => {
                    setDate( d );
                    onDateChange && onDateChange( d );
                }}
                onModeChange={ m => setMode( m )}
            />

            { loading && <div className="schedule-calendar-loading"> Ładowanie danych... </div> }

            { mode === "month" && 
                <TileCalendar
                    events={ events }
                    calendar_data={ calendar_data }
                    available_dates={ available_dates }
                    onDayClick={ onDayClick }
                />
            }

            { mode !== "month" && 
                <DayWeekTable
                    mode={ mode }
                    date={ date }
                    events={ events }
                    breaks={ breaks }
                    available_dates={ available_dates }
                    available_items={ available_items }
                    onDayClick={ onDayClick }
                />
            }
        </div>
    ) : null
}