import API from "./API";
import { CancelToken } from "axios";

export default {

   getAll: async( params: SpecialistReportsGETPaginatedParams = {}, cancelToken?: CancelToken ) => {
      const { data } = await API.get<GETPaginatedResponse<SpecialistReport>>( `/specialist-reports`, {
         params,
         cancelToken
      })

      return data;
   },

   daysById: async( id: number, cancelToken?: CancelToken ) => {
      const { data } = await API.get<SpecialistReportDays>( `/specialist-reports/${ id }`, { cancelToken });
      return data; 
   },

   fillReport: ( id: number, days: SpecialistReportDays ) => API.post( `/specialist-reports/${ id }`, { days })
}