import { useState, useEffect, useCallback } from "react";
import useCancelToken from "./useCancelToken";
import { getDependenciesForHook, getDebounceFn } from "utils/functions";

const cache = {};

interface useFetchDataProps<D, R> {
	APIMethod: (...args: any ) => Promise<D>;
	params?: Array<any>;
	cache_name?: string;
	condition?: boolean;
	debounce?: boolean;
	error_message?: string;
	clear_data_on_update?: boolean;
   extra_dependencies?: any[];
	resultDataHandler?: ( data: D ) => R;
}

const defaultResultDataHandler = data => data;

export default function useFetchData<D, R = D>({
	APIMethod,
	params = [],
	cache_name,
	debounce,
	condition = true,
	error_message,
	clear_data_on_update,
   extra_dependencies = [],
	resultDataHandler = defaultResultDataHandler,
}: useFetchDataProps<D, R>) {
	
	const [ getCancelToken ] = useCancelToken();
	const [ data, setData ] = useState<D | null>( null );
	const [ result, setResult ] = useState<R | null>( null );
	const [ error, setError ] = useState( "" );

	const getData = async( params: any[] ) => {


		if ( !condition ) return;
		clear_data_on_update && setData( null );

		try {
			const data = await APIMethod( ...params, getCancelToken());
			if ( cache_name ) cache[ cache_name ] = data;

			setError( "" );
			setData( data );
		} catch ( e: any ) {
			const error = error_message || e?.response?.data?.message || "Błąd podczas pobierania danych!";	
			setError( error )
		}
	}


	const getDataDebounced = useCallback( getDebounceFn( getData ), [ condition ])


	const prepareResult = ( data: D ) => {
		const res = resultDataHandler( data );
  		setResult( res );
	}


	useEffect(() => {

		if ( cache_name && cache[ cache_name ]) {
			setData( cache[ cache_name ]);
			return;
		}

		debounce ? getDataDebounced( params ) : getData( params )
	}, getDependenciesForHook([ ...params, ...extra_dependencies ]));

	
	useEffect(() => { 
		data ? prepareResult( data ) : setResult( null );
	}, [ data ])

	
	return {
		d: result,
		e: error,
		update: () => getData( params ),
		set: ( r: R | null ) => setResult( r )
	};
}