import { Link } from "react-router-dom";
import mergeCssClasses from "utils/mergeCssClasses";

import Loader from "components/general/Loader";
import "./button.scss";

export default function Button({ children, loading, extra_classes, style, onClick, ...props }: ButtonProps ) {
	
	const className = mergeCssClasses( "button", extra_classes );

	if ( loading ) return <Loader scale={ 0.65 }/>;

	if ( props.href ) return (
		<a 
			className={ className } 
			href={ props.href } 
			style={ style }
			target="_blank"
		> { children } </a>
	)

	else if ( props.to ) return (
		<Link 
			to={ props.to }
			className={ className }
			style={ style }
         onClick={ onClick }
		> { children } </Link>
	)

	return (
		<button
			type={ props.type || "button" }
			className={ className }
			style={ style }
			disabled={ props.disabled || !!loading }
			onClick={ onClick }
		> { children } </button>
	)
}