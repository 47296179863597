import { ArrowLeftSvg, ArrowRightSvg } from "svg/icons";
import { addZero } from "utils/functions";

export default function DateSwitch({ mode, date, calendar_data, onChange }: {
    mode: ScheduleCalendarMode;
    date: Date;
    calendar_data: ScheduleCalendarData;
    onChange: ( d: Date ) => void;
}) {
 
    const changeDate = ( action: string ) => {

        let new_date: Date;
		const cd = calendar_data;
        const current_date = new Date( date );

        switch ( mode ) {
            case "month":

        		let month: number, year: number;

                switch ( action ) {
                    case "prev":
                        month = cd.month === 1 ? 12 : cd.month - 1;
                        year = month === 12 ? cd.year - 1 : cd.year;
                        break;
        
                    case "next":
                        month = cd.month === 12 ? 1 : cd.month + 1;
                        year = month === 1 ? cd.year + 1: cd.year;
                        break;	
        
                    default: return;	
                }

                new_date = new Date(`${ year }/${ addZero( month )}/01`);
            break;

            case "week":
                new_date = action === "next"
                    ? new Date( current_date.setDate( cd.day + 7 ))
                    : new Date( current_date.setDate( cd.day - 7 ));
            break;
            
            case "day":
                new_date = action === "next"
                    ? new Date( current_date.setDate( cd.day + 1 ))
                    : new Date( current_date.setDate( cd.day - 1 ));
            break;
        }

        onChange( new_date ); 
	}

    return (
        <div className="schedule-calendar-date-switch">
            {[ "prev", "next" ].map( action => (
                <button 
                    key={ action } 
                    aria-label={ action }
                    onClick={() => changeDate( action )}
                >
                    { action === "prev" && <ArrowLeftSvg/> }
                    { action === "next" && <ArrowRightSvg/> }
                </button>
            ))}
        </div>
    )
}