import { Routes as Switch, Route } from 'react-router-dom';

import { Roles } from 'constants/Roles';
import Paths from './Paths';
import { useUserRole } from 'context/AuthContext';

import GuestLayout from 'layouts/GuestLayout';
import Articles from 'pages/Article/Articles';
import ArticleView from 'pages/Article/ArticleView';

import LogIn from 'pages/Auth/LogIn';
import ForgotPassword from 'pages/Auth/ForgotPassword';
import ResetPassword from 'pages/Auth/ResetPassword';
import GuestRegulationView from 'pages/GuestRegulationView';

import Home from 'pages/Home';
import Reservations from 'pages/Reservations';
import SupportForms from 'pages/SupportForm/SupportForms';
import SupportFormSpecialists from "pages/SupportForm/SupportFormSpecialists";
import SupportFormSpecialistView from 'pages/SupportForm/SupportFormSpecialistView';
import MakeReservation from "pages/SupportForm/MakeReservation";
import Schedule from 'pages/Schedule';
import SpecialistReservations from 'pages/SpecialistReservations';
import WorkingTime from 'pages/WorkingTime';
import MyProfile from 'pages/MyProfile';
import PointsHistory from 'pages/PointsHistory';
import Messenger from 'pages/Messenger';
import DocumentsRepositoryPage from 'pages/DocumentsRepositoryPage';
import Notifications from 'pages/Notifications';
import Opinions from 'pages/Opinions';
import Regulations from 'pages/Regulations';

import NotFound from 'pages/NotFound';
import ProtectedRoute from './ProtectedRoute';

const Pages = [
   {
      path: Paths.Articles.List,
      element: <Articles/>
   },
   {
      path: Paths.Articles.View(),
      element: <ArticleView/> 
   },
   {
      path: "*",
      element: <NotFound/>
   }
];

const NotAuthPages = [
	{
		path: Paths.Auth.Login,
      element: <LogIn/>
   },
   {
      path: Paths.Auth.ForgotPassword,
      element: <ForgotPassword/>
   },
   {
      path: Paths.Auth.ResetPassword,
      element: <ResetPassword/>
   },
	{
		path: Paths.ServiceRegulation,
      element: <GuestRegulationView/>,
	},
   {
		path: Paths.PrivacyPolicy,
      element: <GuestRegulationView/>,
	},
   {
      path: Paths.AccessibilityDeclaration,
      element: <GuestRegulationView/>
   }
];

const NeedAuthPages = [
   {
      path: Paths.Home,
      element: <Home/>
   },
   {
      path: Paths.Reservations,
      element: <Reservations/>,
      role: Roles.Family
   },
   {
      path: Paths.SupportForm.List,
      element: <SupportForms/>,
      role: Roles.Family
   },
   {
      path: Paths.SupportForm.Specialists(),
      element: <SupportFormSpecialists/>,
      role: Roles.Family
   },
   {
      path: Paths.SupportForm.SpecialistView(),
      element: <SupportFormSpecialistView/>,
      role: Roles.Family
   },
   {
      path: Paths.SupportForm.MakeReservation(),
      element: <MakeReservation/>,
      role: Roles.Family
   },
   {
      path: Paths.Schedule,
      element: <Schedule/>,
      role: Roles.Specialist
   },
   {
      path: Paths.SpecialistReservations,
      element: <SpecialistReservations/>,
      role: Roles.Specialist
   },
   {
      path: Paths.WorkingTime,
      element: <WorkingTime/>,
      role: Roles.Specialist
   },
   {
      path: Paths.MyProfile,
      element: <MyProfile/>
   },
   {
      path: Paths.PointsHistory,
      element: <PointsHistory/>,
      role: Roles.Family
   },
   {
      path: Paths.Messenger,
      element: <Messenger/>
   },
   {
      path: Paths.DocumentsRepository,
      element: <DocumentsRepositoryPage/>
   },
   {
      path: Paths.NotificationList,
      element: <Notifications/>
   },
   {
      path: Paths.Opinions,
      element: <Opinions/>
   },
   {
      path: Paths.Regulations,
      element: <Regulations/>,
      role: Roles.Specialist
   },
   {
      path: Paths.Articles.PanelArticles,
      element: <Articles/>
   },
   {
      path: Paths.Articles.PanelArticleView(),
      element: <ArticleView/> 
   },
   {
      path: "*",
      element: <NotFound/>
   }
];


export default function Routes() {

   const user_role = useUserRole();

	return (
		<Switch>

         <Route element={ <ProtectedRoute/> }>
            { NotAuthPages.map( item  => (
               <Route
                  key={ item.path }
                  {...item }
               />
            ))}

            <Route element={ <GuestLayout/> }> 
               { Pages.map( item  => (
                  <Route
                     key={ item.path }
                     {...item }
                  />
               ))}
            </Route>
         </Route>

         <Route path="/panel" element={ <ProtectedRoute need_auth/> }>
            { NeedAuthPages.map( item => ( item.role && item.role !== user_role ) ? null : (
               <Route
                  key={ item.path }
                  {...item }
               />
            ))}
         </Route>
		</Switch>
	)
}