import { useState, useEffect, useMemo } from "react";

import NotificationAPI from "api/NotificationAPI";

export default function useAccessRequestWaitingCount(refresh = false) {

   const [unread, setUnread] = useState(0);
   const [timer, setTimer] = useState(false);

   useEffect(() => {
      NotificationAPI.unread().then(data => applyUnread(data.unread))
   }, [timer, refresh])

   const applyUnread = newUnread => {
      setUnread(newUnread);
      setTimeout(() => setTimer(!timer), 60 * 1000)
   }

   return useMemo(() => unread, [unread]);
}