import API from "./API";
import { getAvatarFileIdForDTO } from "./helpers";

export default {
    
    logIn: ( data: LogInDTO ) => API.post( "/auth/login", data ),

	refreshToken: () => API.post( "/auth/refresh-token" ),

	me: () => API.get<AuthUser>( "/auth/me" ),

	updateMe: async( data: UpdateMeDTO ) => {
		const avatar_file_id = await getAvatarFileIdForDTO( data );
		return API.post( "/auth/me", {...data, avatar_file_id })
	},

	updatePassword: ( data: UpdatePasswordDTO ) => API.post( "/auth/update-password", data ),

	forgotPassword: ( email: string ) => API.post( "/auth/forgot-password", { email }),

	resetPassword: ( data: ResetPasswordDTO ) => API.post( "/auth/reset-password", data )
}