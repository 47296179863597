import React, { useContext, useEffect, useState } from 'react';

import API from "api/API";
import AuthAPI from 'api/AuthAPI';
import Storage from 'utils/Storage';
import Loader from 'components/general/Loader';
import { AuthContextValue } from './AuthContext.model';
import Toast from "utils/Toast";

import RegulationChecker from 'components/general/RegulationChecker';
import useUserItems from "./useUserItems";

const AuthContext = React.createContext({} as AuthContextValue );

let refresh_timer;

export default function AuthContextProvider({ children }) {

	const [ loading, setLoading ] = useState( true );
   const [ authorized, setAuthorized ] = useState( false );
	const [ user, setUser ] = useState<AuthUser | null>( null );

   const { 
      loading: items_loading,
      items_data,
      participant,
      participants,
      support_form_assignment,
      support_forms_assignments,
      updateItemsData,
      selectItem
   } = useUserItems( user );

	const [ messengerBellTrigger, setMessengerBellTrigger] = useState( false );
	const [ messengerEvent, setMessengerEvent] = useState( false );

	const triggerMessengerBellRefresh = () => {
		setMessengerBellTrigger(messengerBellTrigger => !messengerBellTrigger);
	}

	const triggerMessengerRefresh = (e) => {
		setMessengerEvent(messengerEvent => e);
	}

	const getUser = async() => {

		try {
			const { data } = await AuthAPI.me();		
         
         if ( data.roles.some( role => role.name == "super_admin" )) {
            logOut();
            Toast.error( "Nieprawidłowe dane uwierzytelniające!" );
            return Promise.reject();
         } else { 
            setUser( data );
         }
         
			setLoading( false );
         return data;
		} catch ( e ) {
			logOut()
			return Promise.reject( e );
		}
	}


	const refreshUser = async() => {
		const { data } = await AuthAPI.me();
		setUser( data );
	}


	const updateMe = async( data: UpdateMeDTO ) => {
		const { data: updated_user } = await AuthAPI.updateMe( data );
		setUser({...user, ...updated_user });
	}


	const runRefreshTimer = () => {
		const expires_at = +( Storage.get( "token_expires_at" ));
		if ( !expires_at || isNaN( expires_at )) return;

		const timeout = Number( new Date( expires_at )) - Number( new Date()) - 10000;
		refresh_timer = setTimeout(() => refreshToken(), timeout );
	}


	const updateTokens = ({ access_token, refresh_token }) => {

		Storage.set( "access_token", access_token.token );
		Storage.set( "refresh_token", refresh_token.token );

		document.cookie = "auth_token=" + access_token.token + "; path=/";

		clearTimeout( refresh_timer );

		const expires_at = +new Date( access_token.expires_at );
		if ( expires_at ) {
			Storage.set( "token_expires_at", expires_at );
			runRefreshTimer();	
		}
	}


	const logIn = async({ email, password }) => {

		const { data } = await AuthAPI.logIn({ email, password });

		updateTokens( data );
		return getUser();
	}


	const logOut = () => {

		document.cookie = "auth_token=; path=/; Max-Age=0;";

		Storage.remove( "access_token" );
		Storage.remove( "refresh_token" );
		Storage.remove( "token_expires_at" );
		Storage.remove( "item" );

		setUser( null );
      setAuthorized( false );
		setLoading( false );
	}


	const refreshToken = async() => {

		const refresh_token = Storage.get( "refresh_token" );
		if ( !refresh_token ) return;

		const { data } = await AuthAPI.refreshToken();
		updateTokens( data );
	}


	useEffect(() => {

		API.interceptors.response.use( res => {
			if ( res?.data?.code === 401 ) logOut();
			return res;
		}, err => {
			const resp = err?.response;
			if ( resp?.status === 401 && !/\/auth/.test( resp?.config?.url )) logOut();
			return Promise.reject( err );
		});
	}, [])


	useEffect(() => {

		document.cookie = "auth_token=; path=/; Max-Age=0;";
		const access_token = Storage.get( "access_token" );
		
		if ( access_token ) {
			getUser()
			.then(() => {
				document.cookie = "auth_token=" + access_token + "; path=/";
            setAuthorized( true );
				runRefreshTimer();
			})
		} else {
			setLoading( false );
		}
	}, [])


	useEffect(() => {
		return () => clearTimeout( refresh_timer );
	}, [])


	return loading ? <Loader style={{ height: "100vh" }}/> : (
		<AuthContext.Provider value={{
         authorized,
         authorize: () => setAuthorized( true ),

			user: (user || {}) as AuthUser,
         user_role: user?.roles?.[0]?.name || "",

         items_data,
         participant,
         participants,
         support_form: support_form_assignment?.support_form || null, 
         support_form_assignment,
         support_forms_assignments,

         items_loading,
         selectItem,
         updateItemsData,

			messengerEvent,
			messengerBellTrigger,
			triggerMessengerBellRefresh,
			triggerMessengerRefresh,
			logIn,
			logOut,
			updateMe,
			refreshUser
		}}>
         <RegulationChecker> 
			   { children }
         </RegulationChecker>
		</AuthContext.Provider>
	);
};

export const useAuthContext = () => useContext( AuthContext );

export const useUser = () => useAuthContext().user;

export const useAuthorized = () => useAuthContext().authorized;

export const useUserRole = () => useAuthContext().user_role;

export const useLogOut = () => useAuthContext().logOut;

export const useLogIn = () => useAuthContext().logIn;

export const useMessengerBellTrigger = () => useAuthContext().messengerBellTrigger;

export const useMessengerEvent = () => useAuthContext().messengerEvent;

export const useTriggerMessengerBellRefresh = () => useAuthContext().triggerMessengerBellRefresh;

export const useTriggerMessengerRefresh = () => useAuthContext().triggerMessengerRefresh;

