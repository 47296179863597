import Breadcrumbs from "components/general/Breadcrumbs";
import Button from "components/buttons/Button";

import "./page-header.scss";

export default function PageHeader({ title, breadcrumbs, buttons }: PageHeaderProps ) {
    return (
        <div className="page-header">

            <div className="page-header-top">

                <h1> { title } </h1>

                { buttons && !!buttons.length &&
                    buttons.map(( btn, i ) => (
                        <Button key={ i } {...btn }/>
                    ))
                }

            </div>

            { breadcrumbs && <Breadcrumbs breadcrumbs={ breadcrumbs }/> }
        </div>
    )
}