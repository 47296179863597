import { useState } from "react";
import mergeCssClasses from "utils/mergeCssClasses";
import Toast from "utils/Toast";

import Button from "components/buttons/Button";
import Loader from "components/general/Loader";

import { CrossSvg } from "svg/icons";

import "./modal.scss";

export default function Modal({
	title,
	close_label,
	confirm_label,
	children,
	validationFn,
	onClose,
	onConfirm,
	...props
}: ModalProps ) {

	const [ loading, setLoading ] = useState( false );

	const _onConfirm_ = async() => {
		
		if ( !onConfirm ) return;
		if ( validationFn && !validationFn()) return;

		setLoading( true );

		try {
			await onConfirm();
			onClose && onClose();
		} catch ( e: any ) {
			const be_message = e?.response?.data?.message;
			be_message && Toast.error( be_message );
		} finally {
			setLoading( false );
		}
	}
		

	return (
		<div className={ mergeCssClasses( "modal", props.className )}>
			
			{ props.loading && <Loader/> }

			{ !props.loading && 
				<div className="modal-box">
					
					<div className="modal-box-head"> 
						<h3> { title } </h3>

						{ onClose && 
                     <button 
                        aria-label="close modal"
                        onClick={ onClose }
                     >
                        <CrossSvg/>
                     </button>
                  }
					</div>

					<div className="modal-box-body">
						{ children }
					</div>

               { loading && <Loader/> }			
               
               { !loading && !!onConfirm &&
                  <div className="modal-box-buttons">
                     { onClose && 
                        <Button 
                           extra_classes="transparent"
                           onClick={ onClose }
                        > { close_label || "Anuluj" } </Button>
                     }

                     { confirm_label && 
                        <Button 
                           onClick={ _onConfirm_ }
                        > { confirm_label } </Button>
                     }
                  </div>
               }
				</div>
			}
		</div>
	)
}