import ScheduleAPI from "api/ScheduleAPI";
import useFetchData from "hooks/useFetchData";
import { useMemo } from "react";

export default function useCalendarDaysForCalendar( 
   params: Partial<SpecialistCalendarDaysParams> & { specialist_user_id?: number },
   onItemClick?: ( obj: { date: string, from: string, to: string }) => void
) {

   const { d: calendar_days, e, update } = useFetchData({
      APIMethod: ScheduleAPI.getCalendarDays,
      params: [ params ],
      condition: (
         !!params.specialist_user_id &&
         !!params.support_form_id &&
         !!params.date_from &&
         !!params.date_to
      ), 
      debounce: true
   })

   const available_dates = useMemo(() => {
      return calendar_days 
         ? Object.keys( calendar_days ).filter( k => calendar_days[k].available )
         : []
   }, [ calendar_days ])


   const available_items = useMemo(() => {

      if ( !calendar_days ) return {}

      const available_items = {} as ScheduleCalendarAvailableItems;

      available_dates.forEach( date => {
         available_items[ date ] = [];

         calendar_days[ date ].items?.forEach( item => {
            item.frames
               .filter( i => i.available )
               .forEach( frame => available_items[ date ].push({
                  from: frame.from,
                  to: frame.to,
                  onClick: onItemClick ? () => onItemClick({
                     date,
                     from: frame.from,
                     to: frame.to
                  }) : undefined
               }))
         })
      })

      return available_items;
   }, [ calendar_days, available_dates ])


   return {
      e,
      available_dates,
      available_items,
      updateCalendarDays: update
   }
}