type RouteParam = number | string | undefined;

export default {

   Articles: {
      List: "/",
      View: ( slug: RouteParam = ":slug", id: RouteParam = ":id" ) => `/articles/${ slug }/${ id }`,

      PanelArticles: "/panel/articles",
      PanelArticleView: ( slug: RouteParam = ":slug", id: RouteParam = ":id" ) => `/panel/articles/${ slug }/${ id }`
   },

   Home: "/panel",

   Auth: {
      Login: "/login",
      ForgotPassword: "/forgot-password",
      ResetPassword: "/reset-password"
   },

   Reservations: "/panel/reservations",

   SupportForm: {
      List: "/panel/support-forms",
      Specialists: ( id: RouteParam = ":id" ) => `/panel/support-forms/${ id }/specialists`,
      SpecialistView: ( id: RouteParam = ":id", specialist_id: RouteParam = ":specialist_id" ) => {
         return `/panel/support-forms/${ id }/specialists/${ specialist_id }`
      },

      MakeReservation: ( id: RouteParam = ":id", specialist_id: RouteParam = ":specialist_id" ) => {
         return `/panel/support-forms/${ id }/specialists/${ specialist_id }/make-reservation`
      }
   },

   Schedule: "/panel/schedule",
   
   SpecialistReservations: '/panel/reservations',

   WorkingTime: '/panel/working-time',

   PointsHistory: "/panel/points-history",

   Messenger: "/panel/messenger",

   DocumentsRepository: "/panel/documents-repository",

   NotificationList: "/panel/notifications",

   Opinions: "/panel/opinions",

   MyProfile: "/panel/profile",

   Regulations: "/panel/regulations",

   PrivacyPolicy: "/privacy-policy",
   
   ServiceRegulation: "/service-regulation",

   AccessibilityDeclaration: "/accessibility-declaration"
}