import { useState } from "react";
import { createPortal } from "react-dom";


interface StateModal<T = any> {
	Component: React.ComponentType<T>;
	props: Omit<T, "onClose"> & {onModalClose?: () => void};  
}

interface withModalProps {
	openModal: <T>( options : StateModal<T>) => void;
	closeModal: ( k: string ) => void; 
}

export default function withModal<P>( Component: React.ComponentType<P & withModalProps> ) {

	return function( props: P ) {

		const [ modals, setModals ] = useState<{[ k: string ]: StateModal }>({});

		function openModal<T>( options: StateModal<T> ) {
			setModals( m => ({
				...m,
				[ options.Component.name ]: {
					Component: options.Component,
					props: options.props || {}
				}
			}))
		}


		const closeModal = ( k: string, onModalClose? : () => void ) => {
			const m = {...modals };
			delete m[k];

			setModals( m );

			onModalClose && onModalClose();
		}


		return (
			<>
				<Component 
					{...props }
					openModal={ openModal }
					closeModal={ closeModal }
				/>

				{ Object.keys( modals ).map( key => {
						
					const { Component, props } = modals[ key ];

					return createPortal(
						<Component {...props } onClose={() => closeModal( key, props?.['onModalClose'] )}/>,
						document.body
					)
				})}
			</>
		)
	}
}