import Paths from "router/Paths";
import Toast from "utils/Toast";
import { useAuthContext } from "context/AuthContext";
import useFormData from "hooks/useFormData";

import NotLoggedIn from "layouts/NotLoggedIn";
import FormInput from "components/form/FormInput";
import CheckBox from "components/form/Checkbox";
import LoginChoose from "../LoginChoose";

const inputs = [
   { name: "email", label: "Login" },
   { name: "password", label: "Hasło", type: "password" as const }
]

export default function LogIn() {

   const { user, logIn } = useAuthContext();
   const { data, onDataChange } = useFormData();

   const onSubmitForm = async( form: HTMLFormElement ) => {
      try {
         await logIn({
            email: form.email.value,
            password: form.password.value
         });
      } catch {
         Toast.error( "Nieprawidłowe dane uwierzytelniające!" )
      }
   }

   return user.id ? <LoginChoose/> : (
      <NotLoggedIn
         title="Zaloguj się"
         submit_label="Zaloguj się"
         bottom_link={{
            to: Paths.Auth.ForgotPassword,
            label: "Zapomniałem hasła"
         }}

         onSubmitForm={ onSubmitForm }
      > 
         { inputs.map(({ name, label, type }, i ) => (
            <FormInput 
               key={ i } 
               name={ name }
               label={ label }
               type={ type || "text" }
               value={ String( data[ name ] || "" )}
               onChange={ onDataChange }
            />
         ))}

         <CheckBox 
            name="remember_me"
            checked={ !!data.remember_me }
            onClick={ onDataChange }    
         > Zapamiętaj mnie </CheckBox>
      </NotLoggedIn>
   )
}