
export default function validateFields( fields_to_validate: FieldToValidate[], data: FData ) {
		
	const errors: FDataErrors = {};

	fields_to_validate.forEach( field => {
		
		const name = field.name;
		const repeat_name = field.repeat_name;
		const value = data[ name ];
		const is_valid = Array.isArray( value ) ? !!value.length : !!value;

		if ( field.condition ) {
			for ( const c of field.condition ) {
				
				if ( c.value ) {
					switch ( c.value ) {
						case "EMPTY": if ( !!data[ c.name ]) return;
							break;

						default: if ( data[ c.name ] !== c.value ) return 	
					}
				}

				if ( !c.value && !data[ c.name ]) return;
			}
		}

		if ( repeat_name ) {
			const repeat_value = data[ repeat_name ];
			if ( value !== repeat_value )
				errors[ repeat_name ] = field.repeat_error_message || "Pole nie ma tej samej wartości!";
		}

		if ( !is_valid )
			errors[ name ] = field.error_message || "To pole jest wymagane!";
	})

	return { is_valid: Object.keys( errors ).length === 0, errors }
}