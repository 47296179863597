import useSupportFormSpecialist from "hooks/SupportForm/useSupportFormSpecialist";
import Paths from "router/Paths";

import ErrorMessage from "components/general/ErrorMessage";
import Loader from "components/general/Loader";
import PageHeader from "components/general/PageHeader";
import SpecialistBox from "./components/SpecialistBox";
import SpecialistViewReviews from "./components/SpecialistViewReviews";

export default function SupportFormSpecialistView() {

   const { support_form, specialist, e } = useSupportFormSpecialist();

   if ( e ) return <ErrorMessage message={ e }/>

   if ( support_form && !specialist ) return <ErrorMessage message="Nie znaleziono specjalisty!"/>

   return support_form && specialist ? (
      <>
         <PageHeader
            title={ specialist.full_name }
            breadcrumbs={[ 
               "SUPPORT_FORMS", 
               { 
                  to: Paths.SupportForm.Specialists( support_form.id ),
                  label: "Lista specjalistów - " + support_form.name 
               }, specialist.full_name 
            ]}
         />

         <SpecialistBox 
            specialist={ specialist }
            support_form_id={ support_form.id }
            support_form_name={ support_form.name }
         />

         <SpecialistViewReviews 
            specialist_id={ specialist.id }
            support_form_id={ support_form.id }
         />
      </>
   ) : <Loader/>
}