import SpecialistReservationAPI from "api/SpecialistReservationAPI";
import useSpecialistReservations from "hooks/Reservation/useSpecialistReservations";
import { dateISOStrToMMDDYYYY } from "utils/date";

import StatusBox from "components/general/StatusBox";
import HookedDataTable from "layouts/HookedDataTable";
import Button from "components/buttons/Button";

const columns = [
   { name: "participant_id", label: "Zapisany uczestnik" },
   { name: "date", label: "Data" },
   { name: "starting_time", label: "Godzina rozpoczęcia" },
   { name: "ending_time", label: "Godzina zakończenia" },
   { name: "status", label: "Status" },
   { label: "Akcje", align: "center" as const }
]

export default function TableView({ support_form_id, openReservationModal }: ReservationTabsGeneralProps ) {

   return (
      <>
         <HookedDataTable
            box_wrapper={ false }
            table_columns={ columns }
         
            default_filters={{ support_form_id }}

            BeforeTableComponent={({ update }) => (
               <Button 
                  style={{ marginBottom: "20px" }}
                  onClick={() => openReservationModal({ update })}
               > Dodaj spotkanie </Button>
            )}

            dataHook={ useSpecialistReservations }
            getRowsFn={( item, update ) => {
               return {
                  id: item.id,
                  cells: [
                     { content: item.participant.full_name },
                     { content: dateISOStrToMMDDYYYY( item.date )},
                     { content: item.starting_time.slice( 0, 5 )},
                     { content: item.ending_time.slice( 0, 5 )},
                     { content: <StatusBox status={ item.status }/> },
                     { actions: [   
                        { svg: "edit", onClick: () => openReservationModal({
                           item,
                           update
                        })},

                        { svg: "delete", onClick: async() => {
                           await SpecialistReservationAPI.delete( item.id );
                           update()
                        }},
                     ]}
                  ]
               }
            }} 
         />
      </>
   )
}