import { useRef, useState, useEffect } from "react";
import { getFileAsDataUrl } from "utils/functions";

import Button from "components/buttons/Button";
import default_avatar from "img/default-avatar.png";

import "./avatar-box.scss";

export default function AvatarBox({ name = "avatar", value, onChange }: {
	name: string;
	value: File | string | undefined;
	onChange: FDataOnChange;
}) {

	const input_ref = useRef<HTMLInputElement>( null );
	const [ preview, setPreview ] = useState( "" );

	useEffect(() => {

		if ( !value ) {
			setPreview( default_avatar );
			return;
		}

		if ( typeof value === "string" ) {
			setPreview( value );
			return;
		}

		getFileAsDataUrl( value )
		.then( p => setPreview( p ))

	}, [ value ])


	return (
		<div className="avatar-box">

			<div 
				className="avatar-box-preview"
				style={{ backgroundImage: `url(${ preview })` }}
				onClick={() => input_ref.current?.click()}
			/>
			
			<input
				ref={ input_ref }
				type="file"
				accept=".jpg,.png"
				onChange={ e => {
					const file = e.target.files?.[0];
					file && /jpeg|jpg|png/.test( file.type ) && onChange( name, file );

					if ( input_ref.current ) input_ref.current.value = "";
				}}
			/>
			
			<div className="avatar-box-buttons">
				<Button 
					extra_classes="transparent"
					onClick={() => input_ref.current?.click()}
				> Zmień obraz </Button>
			</div>
		</div>
	)
}