import { Fragment, useState } from "react";
import Box from "../Box";
import "./tabs.scss";

export default function Tabs({
    tabs,
    general_props = {},
    onTabChange
}: TabsProps ) {

	const [ tab, setTab ] = useState( tabs[0].value );

    return (
        <>
            <div className="tabs">
				{ tabs.map(({ value, label, SVG }) => (
					<button 	
						key={ value } 
						className={ value === tab ? "active" : "" }
						onClick={() => {
                            setTab( value );
                            onTabChange && onTabChange( value );
                        }}
					>
                        { SVG }
                        <span> { label } </span>
					</button>
				))}
			</div>

            <Box className="tabs-content"> 
                { tabs.map(({ value, Component }) => {
                        return tab === value ? (
                            <Fragment key={ value }>
                                
                                { Component && 
                                    <Component {...general_props } />
                                }
                                
                            </Fragment>
                        ) : null
                    })
                }
            </Box>
        </>
    )
}