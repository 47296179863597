import axios from "axios";
import Storage from "utils/Storage";

const API = axios.create({
   baseURL: process.env.REACT_APP_API_URL
})

API.interceptors.request.use( config => {

	const token = Storage.get( "access_token" );
    const refresh_token = Storage.get( "refresh_token" );

    if ( refresh_token && config.url === "/auth/refresh-token" )
        config.headers.Authorization = `Bearer ${ refresh_token }`;
    else if ( token && config.headers )
        config.headers.Authorization = `Bearer ${ token }`;
	
    return config;
}, error => Promise.reject( error ));

export default API;