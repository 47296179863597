import API from "./API";
import { CancelToken } from "axios";

export default {

   getAll: async(
      { query, category_id, published_from, published_to, ...rest }: PostsGETPaginatedParams = {}, 
      cancelToken?: CancelToken 
   ) => {
      const { data } = await API.get<GETPaginatedResponse<PostItem>>( `/cms/posts`, {
         params: {
            filters: {
               query,
               category_id,
               published_from,
               published_to
            },
            ...rest
         },
         cancelToken
      })

      return data;
   },

   byId: async({ slug, id, pin, email }: GETPostParams, cancelToken?: CancelToken ) => {
      const { data } = await API.get<{ post: BEPost | null, access: PostAccess }>( `/cms/posts/${ slug },${ id }`, { 
         params: { pin, email },
         cancelToken
      });

      return data;
   },

   sendRequest: ( slug: string, id: number, dto: {
      email: string;
      request_description: string;
   }) => API.post( `/cms/posts/${ slug },${ id }/request`, dto )
}