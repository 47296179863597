import { useMemo } from "react";
import useReservations from "./useReservations";
import { dateToCalendarData } from "utils/calendarHelpers";

export default function useReservationsEvents(
   params: SpecialistReservationsGETPaginatedParams & { date_obj?: Date }= {},
   onEventClick?: ( item: SpecialistReservation ) => void
) {

   const { date_obj, ...p } = params;
   const { year, month } = date_obj ? dateToCalendarData( date_obj ) : { year: undefined, month: undefined };
   
   const { d, e, update } = useReservations({ 
      limit: 500,
      year, 
      month,
      ...p
   });

   const reservations = d?.data;

   const events = useMemo(() => {

      if ( !reservations ) return undefined;

      const events = {} as ScheduleCalendarEvents; 
      
      reservations.forEach(( item ) => {
         
         const date = item.date;
         events[ date ] = events[ date ] || [];
         
         events[ date ].push({
            id: item.id,
            start_time: item.starting_time,
            end_time: item.ending_time,
            text: item.specialist.full_name + " " + item.place,
            onClick: onEventClick ? () => onEventClick( item ) : undefined
         })
      });

      Object.values( events ).forEach( arr => {
         arr.sort(( a, b ) => a.start_time > b.start_time ? 1 : -1 )
      })

      return events
   }, [ reservations ])

   return {
      e,
      reservations,
      events,
      updateReservations: update
   };
}