import FileAPI from "./FileAPI";

export async function getAvatarFileIdForDTO( dto: {
    avatar_file_id?: string;
    new_avatar?: File;
}) {

    let avatar_file_id = dto.avatar_file_id || undefined;
    if ( dto.new_avatar ) {
        const { id } = await FileAPI.uploadTemp( dto.new_avatar );
        if ( id ) avatar_file_id = id;
    }

    return avatar_file_id;
}