import PageHeader from "components/general/PageHeader";
import Tabs from "components/general/Tabs";

import SystemRegulation from "./tabs/SystemRegulation";
import PrivacyPolicy from "./tabs/PrivacyPolicy";

export default function Regulations() {
   return (
      <>
         <PageHeader
            title="Regulaminy"
            breadcrumbs={[ "Regulaminy" ]}
         />

         <Tabs tabs={[
            { 
               value: 1,
               label: "Regulamin systemu",
               Component: SystemRegulation
            },
            {
               value: 2,
               label: "Polityka prywatności",
               Component: PrivacyPolicy
            }
         ]}/>
      </>
   )
}