import { Outlet, Navigate } from "react-router-dom"
import { useAuthorized } from "context/AuthContext"
import Paths from "./Paths";
import MainLayout from "layouts/MainLayout";

export default function ProtectedRoute({ need_auth }: { need_auth?: boolean }) {

   const authorized = useAuthorized();

   if ( need_auth ) {
      return authorized ? (
         <MainLayout> 
            <Outlet/> 
         </MainLayout>
       ) : <Navigate to={ Paths.Articles.List }/> 
   } 
   
   if ( !need_auth && authorized ) return <Navigate to={ Paths.Home }/>

   return <Outlet/>
}