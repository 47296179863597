import { Fragment, useMemo } from "react";
import useFormData from "hooks/useFormData";
import useDidChange from "hooks/useDidChange";

import Box from "components/general/Box";
import PageHeader from "components/general/PageHeader";
import Table from "components/general/Table";
import ErrorMessage from "components/general/ErrorMessage";

export default function HookedDataTable<DataItem>({
    page_header,
    box_wrapper = true,
    table_columns,
    filter_inputs,
    default_filters,
    default_params = {},
    extra_filters = {},
    getRowsFn,
    dataHook,
    TFoot,
    BeforeBoxComponent,
    BeforeTableComponent
}: {
    page_header?: PageHeaderProps;
    box_wrapper?: boolean;
    table_columns: TableColumn[];
    filter_inputs?: TableFilterInputs;
    default_params?: FData;
    default_filters?: FData;
    extra_filters?: FData;
    getRowsFn: ( item: DataItem, update: () => Promise<void>, index: number ) => TableRow;
    dataHook: ( params?: any ) => {
        d: GETPaginatedResponse<DataItem> | null;
        e: string;
        update: () => Promise<void>;
    };

    TFoot?: ( rows: TableRow[] | null ) => React.ReactNode;
    BeforeBoxComponent?: React.ComponentType<{ rows: TableRow[] | null }>;
    BeforeTableComponent?: React.ComponentType<{ update: () => Promise<void> }>;
}) {
    
    const { data: params, onDataChange: onParamsChange } = useFormData({
        page: 1,
        limit: 10,
        column: "id",
        direction: "asc",
        ...default_params
    });

	const { data: filters, onDataChange: onFiltersChange } = useFormData({
        ...( filter_inputs ? Object.values( filter_inputs ).reduce(( res, item ) => {
            if ( item.type === "date-range" ) {
                const names = item.name.split(",");
                res[ names[0].trim()] = undefined;
                res[ names[1].trim()] = undefined;
            } else res[ item.name ] = undefined            
            return res;            
        }, {}) : {}),
        ...( default_filters || {})
    });

	const { d, e, update: updateData } = dataHook({...params, ...filters, ...extra_filters });

    const data = d?.data;
    const rows = useMemo(() => {
		if ( !data ) return null;
		return data.map(( item, i ) => getRowsFn( item, updateData, i ));
	}, [ data ])


    const WrapperComponent = useMemo(() => box_wrapper ? Box : Fragment, [])


    useDidChange(() => {
      default_filters && onFiltersChange( default_filters );
    }, [ default_filters ])

    return !e ? (
        <>
            { page_header && 
                <PageHeader 
                    {...page_header }
                    buttons={ page_header.buttons 
                        ? page_header.buttons.map( props => ({
                            ...props,
                            onClick: !!props.onClick 
                                ? () => props.onClick && props.onClick( updateData ) 
                                : undefined
                        })) : undefined
                    } 
                />
            }

            { BeforeBoxComponent && 
               <BeforeBoxComponent rows={ rows }/>
            }

            <WrapperComponent>

                { BeforeTableComponent && 
                    <BeforeTableComponent update={ updateData }/>
                }

                <Table
                    columns={ table_columns }
                    rows={ rows }

                    params={{
                        page: Number( params.page || 1 ),
                        column: String( params.column || "" ),
                        direction: params.direction === "asc" || params.direction === "desc"
                            ? params.direction
                            : undefined
                    }}

                    filter_inputs={ filter_inputs }
                    filters={ filters }
                    onFiltersChange={ onFiltersChange }

                    pages_amount={ d?.last_page }
                    onParamsChange={ onParamsChange }

                    TFoot={ TFoot }
                />
            </WrapperComponent>
        </>
    ) : <ErrorMessage message={ e }/>  
}