import { useAuthContext } from "context/AuthContext";
import ParticipantAvatar from "components/general/ParticipantAvatar";

export default function FamilyContent() {

   const { participants, selectItem, authorize } = useAuthContext();

   return (
      <>
         { participants && participants.map( item => (
            <ParticipantAvatar 
               key={ item.id } 
               id={ item.id }
               avatar_file_id={ item.avatar_file_id || undefined }
               full_name={ item.full_name }
               onClick={() => {
                  selectItem( item );
                  authorize();
               }}
            />
         ))}
      </>
   )
}