import { useAuthContext } from "context/AuthContext";

import NotLoggedIn from "layouts/NotLoggedIn";
import { Roles } from "constants/Roles";

import SpecialistContent from "./components/SpecialistContent";
import FamilyContent from "./components/FamilyContent";
import { useEffect } from "react";

export default function LoginChoose() {

   const { user_role, items_data, items_loading, logOut, authorize } = useAuthContext();

   const is_specialist = user_role === Roles.Specialist;
   const is_family = user_role === Roles.Family;

   useEffect(() => {
      if ( !items_loading && !items_data?.length ) authorize();
   }, [ items_data, items_loading ])

   return (
      <NotLoggedIn
         title={ is_family ? "Wybierz podkonto" : "Forma wsparcia" }
         bottom_link={{
            to: "#",
            label: "WRÓĆ DO LOGOWANIA",
            onClick: () => logOut()
         }}
      > 
         { is_specialist && 
            <SpecialistContent/>
         }

         { is_family &&
           <FamilyContent/>
         }
      </NotLoggedIn>
   )
}