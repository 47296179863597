import withModal from "hoc/withModal";
import { useAuthContext } from "context/AuthContext";
import { Roles } from "constants/Roles";

import PageHeader from "components/general/PageHeader";
import Tabs from "components/general/Tabs";
import FamilyReviewModal from "components/modal/FamilyReviewModal";
import SpecialistReviewModal from "components/modal/SpecialistReviewModal";

import ActivityRating from "./tabs/ActivityRating";
import SpecialistReviews from "./tabs/SpecialistReviews";
import { CalendarSvg, TimerSvg } from "svg/icons";

export default withModal( function Opinions({ openModal }) {
   
   const { user_role, participant, support_form } = useAuthContext();
   const is_family = user_role === Roles.Family;
   const is_specialist = user_role === Roles.Specialist;

   const openFamilyReviewModal = ({ type, review, onConfirm }: {
      type: "activity" | "specialist";
      review: SpecialistSessionReview | SpecialistReview;
      onConfirm?: ( d: SpecialistReviewDTO | SpecialistSessionReviewDTO ) => Promise<void>;
   }) => {
      openModal({
         Component: FamilyReviewModal,
         props: {
            type,
            review,
            onConfirm
         }
      })
   }

   const openSpecialistReviewModal = ({ type, review, onConfirm }: {
      type: "activity" | "specialist";
      review: SpecialistSessionReview | SpecialistReview;
      onConfirm?: ( answer: string ) => Promise<void>;
   }) => {
      openModal({
         Component: SpecialistReviewModal,
         props: {
            type,
            review,
            onConfirm
         }
      })
   }
    
   return (
      <>
         <PageHeader
            title="Opinie i ankiety"
            breadcrumbs={[ "Opinie i ankiety" ]}
         />

         <Tabs 
            general_props={{ 
               is_family,
               is_specialist,
               support_form_id: support_form?.id,
               participant_id: participant?.id,
               openFamilyReviewModal, 
               openSpecialistReviewModal 
            }}

            tabs={[
               { 
                  value: 1,
                  label: "Oceny Aktywności",
                  SVG: <CalendarSvg/>, 
                  Component: ActivityRating
               },
               {
                  value: 2,
                  label: "Oceny specjalistów",
                  SVG: <TimerSvg/>,
                  Component: SpecialistReviews
               }
            ]}
         />
      </>
   )
})