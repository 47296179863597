import SpecialistReservationAPI from "api/SpecialistReservationAPI";
import { useAuthContext } from "context/AuthContext";
import withModal from "hoc/withModal";

import PageHeader from "components/general/PageHeader";
import Tabs from "components/general/Tabs";
import ReservationModal from "pages/SpecialistReservations/components/SpecialistReservationModal";
import { NoteSvg, CalendarSvg } from "svg/icons";

import TableView from "./tabs/TableView";
import CalendarView from "./tabs/CalendarView";
import "./specialist-reservations.scss";

export default withModal( function SpecialistReservations({ openModal }) {

   const { support_form } = useAuthContext();
   const support_form_id = support_form?.id;

   const openReservationModal = ( opt: { item?: SpecialistReservation, update: () => void }) => {
        
      const { item, update } = opt;

      openModal({
         Component: ReservationModal,
         props: {
            reservation_item: item,
            onConfirm: async( d ) => {
               item 
                  ? await SpecialistReservationAPI.update( item.id, d )
                  : await SpecialistReservationAPI.add( d );
                  
               update();
            }
         }
      })
   }

   return (
      <div className="specialist-reservations-page">
         <PageHeader
            title="Zapisy uczestników"
            breadcrumbs={[ "Zapisy uczestników" ]}
         />

         <Tabs
            general_props={{ support_form_id, openReservationModal }}

            tabs={[
               {
                  value: 1,
                  SVG: <NoteSvg/>,
                  label: "Widok tabeli",
                  Component: TableView
               },
               { 
                  value: 2,
                  SVG: <CalendarSvg/>,
                  label: "Widok kalendarza",
                  Component: CalendarView
               },
            ]}
         />
      </div>
   )
})