import { forwardRef } from "react";
import withInnerPopup from "hoc/withInnerPopup";
import { Link } from "react-router-dom";
import Paths from "router/Paths";

import { CogwheelSvg, ProfileSvg, DeleteSvg } from "svg/icons";
import UserAvatar from "components/general/UserAvatar";

import "./conversation-box-head.scss";

interface Props {
    user: UserMain;
    show_popup: boolean;
    setPopup: ( s: boolean ) => void;
    deleteConversation: ( user_id: number) => void;
}

const ConversationBoxHead = forwardRef<HTMLDivElement, Props>(({ user, show_popup, setPopup, deleteConversation }, ref ) => {

    return (
        <div className="conversation-box-head">

            <UserAvatar 
                avatar_id="" 
                full_name={ user.full_name }
            />

            <div>
                <div 
                    ref={ ref } 
                    onClick={() => setPopup( !show_popup )}
                >
                    <CogwheelSvg className={'red-icon'}/>
                </div>
                { show_popup && 
                    <div className="widget-popup">
                        {/* <Link to={ Paths.User.View(user.id) }> 
                            <ProfileSvg/> Profil użytkownika
                        </Link> */}

                        <a href="#" onClick={ e => {
                            deleteConversation(user.id);
                        }}> <DeleteSvg/> Usuń konwersację </a>
                    </div>
                }
            </div>
        </div>
    )
})

export default withInnerPopup( ConversationBoxHead );