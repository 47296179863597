import { useEffect } from "react";
import useFormData from "hooks/useFormData";
import useFileShareInfo from "hooks/File/useFileShareInfo";
import useUserOptions from "hooks/User/useUserOptions";

import FormDropdown from "components/form/FormDropdown";
import Modal from "components/modal/Modal";
import FormInput from "components/form/FormInput";
import validateFields from "utils/validateFields";

export default function ItemShareModal({ id, onClose, onConfirm }: {
    id: string;
    onClose: () => void;
    onConfirm: ( d: FileShareDTO ) => Promise<void>;
}) {
    
    const { d: share_info } = useFileShareInfo( id );
    const { data, errors, onDataChange, setErrors } = useFormData();
  
    const validation = () => {

        const check = validateFields([
            { name: "users" }, { name: "valid_to" }
        ], data )

        setErrors( check.errors );
        return check.is_valid;
    }


    useEffect(() => {
        if ( !share_info ) return;
        onDataChange( "users", share_info.map( s => s.user_id ))
    }, [ share_info ])

    return (
        <Modal
            title="Udostępnij plik"
            loading={ !data.users }
            confirm_label="Udostępnij"
            validationFn={ validation }
            onClose={ onClose }
            onConfirm={() => onConfirm({
                users: ( data.users || []) as number[],
                valid_to: String( data.valid_to )
            })}
        >
            <>
               <FormDropdown
                  multiple
                  name="users"
                  value={ data.users }
                  error={ errors.users }
                  max_value_label_length={ 70 }
                  optionsHook={ useUserOptions }
                  label="Udostępnij dla użytkwoników"
                  onChange={ onDataChange }
               />

               <FormInput
                  name="valid_to"
                  value={ String( data.valid_to || "" )}
                  error={ errors.valid_to }
                  type="datetime-local"
                  label="Termin dostępności pliku"
                  style={{ marginBottom: "0" }}
                  onChange={ onDataChange }
               />
            </>
        </Modal>
    )
}