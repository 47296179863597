interface Toast extends HTMLDivElement {
	toast_id: number;
	auto_delete: NodeJS.Timeout;
};

const Toasts = ( function() {

	let toast_id = 1;
	let all_toasts: Toast[] = [];

	return {
		updatePositions: () => {

			let top = 10;
			
			all_toasts.forEach( toast => {
				toast.style.top = top + "px";
				top += toast.offsetHeight + 5;
			});
		},
	
		add: ( toast: Toast ) => {
			all_toasts.push( toast );
			Toasts.updatePositions();
		},
	
		delete: ( toast: Toast ) => {

			clearTimeout( toast.auto_delete );
	
			all_toasts = all_toasts.filter( t => t.toast_id !== toast.toast_id );
			Toasts.updatePositions();
		
			toast.style.top = -toast.offsetHeight + "px";
			setTimeout(() => toast.remove(), 600 );
		},
	
		create: ( type: "success" | "error", message: string ) => {
	
			const toast_el = document.createElement( "div" );
			toast_el.innerHTML = message;
			toast_el.classList.add( "toast", type );
			toast_el.style.opacity = "0";

			const link = document.createElement( "a" );
			link.innerHTML = "&#10006;";
			toast_el.appendChild( link );

			const toast = toast_el as Toast;
			toast.toast_id = toast_id;
			toast_id++;

			toast.addEventListener<"click">( "click", function(e) {
				const target = e.target as HTMLElement;
				target?.tagName === "A" && Toasts.delete( this as Toast );
			})
		
			document.body.appendChild( toast );
			toast.style.top = -toast.offsetHeight + "px";
			toast.style.opacity = "1";
			
			toast.auto_delete = setTimeout(() => {
				Toasts.delete( toast )
			}, 6000 )
		
			setTimeout(() => Toasts.add( toast ), 100 );
		}
	}
})();


export default {
	success: ( m: string ) => Toasts.create( "success", m ),
	error: ( m: string ) => Toasts.create( "error", m )
}