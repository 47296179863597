import API from "./API";
import { CancelToken } from "axios";

export default {

   getAll: async( params: NotificationsGETPaginatedParams = {}, cancelToken?: CancelToken ) => {
      const { data } = await API.get<GETPaginatedResponse<NotificationItem>>( `/notifications/own/list`, {
         params,
         cancelToken
      });

      return data;
   },

   byId: async( id: string, cancelToken?: CancelToken ) => {
      const { data } = await API.get<NotificationItem>( `/notifications/own/${ id }`, { cancelToken });
      return data;
   },

   readOwn: async( id: string, cancelToken?: CancelToken ) => {
      const { data } = await API.get( `/notifications/own/read/${ id }`, { cancelToken });
      return data;
   },

   unread: async( cancelToken?: CancelToken ) => {
      const { data } = await API.get( `/notifications/own/unread`);
      return data;
   }
}