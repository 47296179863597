import { useState } from "react";
import useSpecialistReservationsEvents from "hooks/Reservation/useSpecialistReservationsEvents";
import ScheduleCalendar from "components/general/ScheduleCalendar";
import Button from "components/buttons/Button";

export default function CalendarView({ support_form_id, openReservationModal }: ReservationTabsGeneralProps ) {

   const [ date, setDate ] = useState( new Date());
   const { reservations, events, updateReservations } = useSpecialistReservationsEvents({
      date_obj: date,
      support_form_id,
   }, ( item ) => openReservationModal({ item, update: updateReservations }));

   return (
      <>
         <Button 
            style={{ marginBottom: "20px" }}
            onClick={() => openReservationModal({ 
               update: updateReservations 
            })}
         > Dodaj spotkanie </Button>
      
         <ScheduleCalendar
            init_date={ date }
            loading={ !reservations }
            events={ events }
            onDateChange={( date ) => setDate( date )}
         />
      </>
   )
}