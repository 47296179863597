import { useAuthContext } from "context/AuthContext";
import SpecialistReportAPI from "api/SpecialistReportAPI";
import useSpecialistReports from "hooks/SpecialistReport/useSpecialistReports";
import { MONTH_NAMES, getDateString } from "utils/date";
import withModal from "hoc/withModal";

import HookedDataTable from "layouts/HookedDataTable";
import StatusBox from "components/general/StatusBox";
import ReportHoursModal from "./components/ReportHoursModal";

export default withModal( function WorkingTime({ openModal }) {

   const { support_form } = useAuthContext();

   return (
      <HookedDataTable
         page_header={{
            title: "Czas pracy",
            breadcrumbs: [ "Czas pracy" ]
         }}

         default_filters={{ support_form_id: support_form?.id }}

         table_columns={[
            { name: "month", label: "Miesiąc" },
            { name: "filled_at", label: "Data zaraportowania", width: 180 },
            { name: "hours", label: "Liczba godzin", width: 150 },
            { name: "is_filled", label: "Status", width: 200 },
            { label: "Akcje", align: "center" as const, width: 90 }
         ]}

         dataHook={ useSpecialistReports }
         getRowsFn={( item, update ) => {

            const max_date_to_fill = new Date( 
               `${ item.month === 12 ? item.year + 1 : item.year }/${ item.month === 12 ? 1 : item.month + 1 }/06`
            );

            const can_fill = +new Date() < +max_date_to_fill;

            return {
               id: item.id,
               cells: [
                  { content: MONTH_NAMES[ item.month ] + " " + item.year },
                  { content: item.filled_at ? getDateString( new Date( item.filled_at )) : "--" },
                  { content: item.hours || "--" },
                  { content: <StatusBox status={ item.is_filled ? "reported" : "not-reported" }/> },
                  { actions: can_fill ? [
                     {
                        svg: "edit",
                        onClick: () => openModal({
                           Component: ReportHoursModal,
                           props: {
                              id: item.id,
                              onConfirm: async( days: SpecialistReportDays ) => {
                                 await SpecialistReportAPI.fillReport( item.id, days );
                                 update();
                              }
                           }
                        })
                     }
                  ] : []}
               ]
            }
         }}
      />
   )
})