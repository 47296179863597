import API from "./API";
import { CancelToken } from "axios";
import { getAvatarFileIdForDTO } from "./helpers";

export default {

   getAll: async( params: ParticipantsGETPaginatedParams = {}, cancelToken?: CancelToken ) => {
      const { data } = await API.get<GETPaginatedResponse<Participant>>( `/participants`, {
         params,
         cancelToken
      })

      return data;
   },

   byId: async( id: number, cancelToken?: CancelToken ) => {
      const { data } = await API.get<Participant>( `/participants/${ id }`, { cancelToken });
      return data;
   },

   add: async( data: ParticipantDTO ) => {
      const avatar_file_id = await getAvatarFileIdForDTO( data );
      const { data: new_participant } = await API.post( `/participants`, {...data, avatar_file_id })
      return new_participant;
   },

   update: async( id: number, data: ParticipantDTO ) => {
      const avatar_file_id = await getAvatarFileIdForDTO( data );
      const { data: participant } = await API.post( `/participants/${ id }`, {...data, avatar_file_id });
      return participant;
   },

   delete: ( id: number ) => API.delete( `/participants/${ id }` )
}