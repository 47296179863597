import FormInput from "components/form/FormInput";
import FormDropdown from "components/form/FormDropdown";

import { format_options, order_options } from "./options";

export default function Filters({ data, onDataChange }: {
    data: FData;
    onDataChange: FDataOnChange;
}) {
    return (
        <div className="documents-repository-box-filters">
            <FormInput
                name="query"
                value={ String( data.query || "" )}
                placeholder="Wyszukaj folder lub plik po nazwie..."
                onChange={ onDataChange }
            />

            <FormDropdown
                name="format"
                value={ data.format }
                placeholder="Wybierz format pliku"
                options={ format_options }
                onChange={ onDataChange }
            />
        
            <FormDropdown
                name="order"
                value={ data.order }
                placeholder="Sortuj według"
                options={ order_options }
                onChange={ onDataChange }
            />
        </div>
    )
}