import HTMLReactParser from "html-react-parser";
import useRegulation from "hooks/Regulation/useRegulation";

export default function PrivacyPolicy() {

   const { d } = useRegulation( "privacy_policy" );

   return (
      <>
         <h2> Polityka prywatności </h2>
         
         { HTMLReactParser( d?.content || "" )}
      </>
   )
}