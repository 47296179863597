import SupportFormAPI from "api/SupportFormAPI";
import useFetchData from "hooks/useFetchData";

export default function useSupportForms( params: SupportFormsGETPaginatedParams = {}) {
   return useFetchData({
      APIMethod: SupportFormAPI.getAll,
      params: [ params ],
      debounce: true,
      clear_data_on_update: true
   })
}