import { useState } from "react";
import useFilesList from "hooks/File/useFilesList";
import FileAPI from "api/FileAPI";
import { HomeSvg } from "svg/icons";
import Toast from "utils/Toast";

import Sidebar from "./components/Sidebar";
import Main from "./components/Main";

import "./documents-repository.scss";


export default function DocumentsRepository({ 
    selected_files,
    onFileSelect
}: { 
    selected_files?: FileObj[];
    onFileSelect?: ( f: FileObj ) => void;  
}) {

    const [ shared, setShared ] = useState( false );
    const [ open_folder_id, setOpenFolderId ] = useState( "" );
    const [ breadcrumbs, setBreadcrumbs ] = useState<DocRepoBreadcrumbsItem[]>([{
		id: "",
        svg: <HomeSvg/>,
		label: "Dokumenty"
	}]);

    const { d: list, update: updateList } = useFilesList({
        shared,
        parent_id: open_folder_id
    });

    const addFiles = async( f: File[]) => {

        if ( !f || !f?.length ) return;

        const dto = f.map( item => ({
            file: item,
            parent_id: open_folder_id || undefined
        }))

        await FileAPI.uploadFew( dto );
        updateList();
    }


    const createDirectory = async( d: FileObjDTO ) => {
        await FileAPI.createDirectory({ name: d.name, parent_id: open_folder_id || undefined })
        updateList();
    }


    const updateItem = async({ id, ...data }: FileObjDTO & { id?: string }) => {
        if ( !id ) return;

        await FileAPI.update( id, data );
        updateList();
    }


    const deleteItem = async( id: string ) => {
        await FileAPI.delete( id );
        updateList();
    }


    const shareFile = async( id: string, data: FileShareDTO ) => {
        await FileAPI.share( id, data );
        Toast.success( "Plik został pomyślnie udostępniony!" )
    }

    const downloadFile = async( id: string, name: string ) => FileAPI.download( id, name )

    return (
        <div className="documents-repository">
            <Sidebar
                shared={ shared }
                onChange={ s => {
                    setShared( s );
                    setOpenFolderId( "" );
                    setBreadcrumbs( b => [b[ 0 ]]);
                }}
            />

            <Main 
                selected_files={ selected_files }
                onFileSelect={ onFileSelect }

                list={ list }
                shared={ shared }
                breadcrumbs={ breadcrumbs }
                
                createDirectory={ createDirectory }
                updateItem={ updateItem }
                addFiles={ addFiles }
                downloadFile={ downloadFile }
                shareFile={ shareFile }
                deleteItem={ deleteItem }

                openDirectory={({ id, name }) => {
                    setOpenFolderId( id )
                    setBreadcrumbs( b => [...b, { id, label: name }])
                }}

                onBreadcrumbClick={ id => {
                    if ( id === open_folder_id ) return;
                    const index = breadcrumbs.findIndex( item => item.id === id );

                    setOpenFolderId( id );   
                    setBreadcrumbs( b => b.slice( 0, index + 1 ))
                }}
            />
        </div>
    )
}