import { addZero } from "./functions";

const m_ms = 1000 * 60;
export const h_ms = m_ms * 60;
export const d_ms = h_ms * 24;

export const DAY_NAMES = [ "Poniedziałek", "Wtorek", "Środa", "Czwartek", "Piątek", "Sobota", "Niedziela" ];

export const DAY_NAME_SHORT = [ "PON", "WT", "ŚR", "CZW", "PT", "SOB", "NDZ" ];

export const MONTH_NAMES = [
	"",
	"Styczeń", "Luty", "Marzec", "Kwiecień", "Maj", "Czerwiec", 
	"Lipiec", "Sierpień", "Wrzesień", "Październik", "Listopad", "Grudzień" 
];

export const MONTH_OPTIONS = MONTH_NAMES.slice( 1 ).map(( label, i ) => ({
	value: i + 1,
	label
}))

export const isDate = ( d: any ): d is Date => d?.toString() !== "Invalid Date" && toString.call( d ) === "[object Date]";

export const getDayProperIndex = ( date: Date ) => {
	if ( !isDate( date )) throw new Error( 'Should be Date type' );
	return date.getDay() === 0 ? 6 : date.getDay() - 1;
}

export const dateISOStrToMMDDYYYY = ( date_str: string ) => {
	return date_str.replace( /^(\d{4})[-.](\d{2})[-.](\d{2})/, "$3.$2.$1" )
}

export const getDateFromStr = ( date_str: string ) => {
	const new_str = date_str.replace( /^(\d{2})[-.](\d{2})[-.](\d{4})/, "$3/$2/$1" )
	return new Date( new_str );
}

export const getDaysAmountInMonth = ( month: number, year: number ) => {

	if ( !year || !month ) return 0;

	const next_month = month + 1 !== 13 ? month + 1 : 1;
	const next_year = next_month === 1 ? year + 1 : year; 
		
	const next_month_date = +new Date(`${ next_year }/${ next_month }/01 00:00:00`);
	return new Date( next_month_date - 60000 ).getDate();
}

export const getDateString = ( date: Date | {
	day?: number;
	month: number;
	year: number;
	hours?: number;
	minutes?: number;
	seconds?: number;
}, format = "DD.MM.YYYY" ) => {
	
	if ( !isDate( date ) && ( !date?.month || !date?.year )) return "";

	let day: number, 
		month: number,
		year: number,
		hours: number,
		minutes: number,
		seconds: number;

	if ( isDate( date )) {
		day = date.getDate();
		month = date.getMonth() + 1;
		year = date.getFullYear(); 

		hours = date.getHours();
		minutes = date.getMinutes();
		seconds = date.getSeconds();
	} else {
		day = date.day || 1;
		month = date.month;
		year = date.year;

		hours = date.hours || 0;
		minutes = date.minutes || 0;
		seconds = date.seconds || 0;
	}

	let result = format;
	result = result.replace( "DD", addZero( day ));
	result = result.replace( "MM", addZero( month ));
	result = result.replace( "YYYY", String( year ));
	result = result.replace( "hh", addZero( hours ));
	result = result.replace( "mm", addZero( minutes ));
	result = result.replace( "ss", addZero( seconds ));

	return result;
}

export const getWeek = ( date_obj?: Date) => {

	const actual_date_obj = date_obj || new Date();
	const actual_date_iso = getDateString( actual_date_obj, "YYYY-MM-DD" );
	const days_amount = 7;
	const day_index = getDayProperIndex( actual_date_obj );
	const prev_days_amount = day_index;
	const next_days_amount = days_amount - 1 - day_index;

	const prev_days: WeekItem[] = [];
	const next_days: WeekItem[] = [];

	if ( prev_days_amount > 0 ) {
		for ( let i = 0; i < prev_days_amount; i++ ) {
			const cached = new Date( actual_date_obj );
			const date = new Date( cached.setDate( cached.getDate() - ( i + 1 )));
			prev_days.unshift({ date, date_iso: getDateString( date, "YYYY-MM-DD" )});
		}
	}

	if ( next_days_amount > 0 ) {
		for ( let i = 0; i < next_days_amount; i++ ) {
			const cached = new Date( actual_date_obj );
			const date = new Date( cached.setDate( cached.getDate() + ( i + 1 )));
			next_days.push({ date, date_iso: getDateString( date, "YYYY-MM-DD" )} );
		}
	}

	return [
		...prev_days, 
		{ date: actual_date_obj, date_iso: actual_date_iso },
		...next_days
	];
}

export const getTimeDuration = ( from: string, to: string ) => {

	const timeToMinutes = ( time: string ) => {
		const [ h, min ] = time.split(":");
		return +h * 60 + +min;
	}
	
	const from_min = timeToMinutes( from );
	const to_min = timeToMinutes( to );

	const duration = to_min - from_min;
	
	const h = Math.floor( duration / 60 );
	const m = duration % 60;
	const time = addZero( h ) + ":" + addZero( m ) 

	return { duration, hours: h, minutes: m, time };
}