import useCategoryOptions from "hooks/Category/useCategoryOptions";

import FormInput from "components/form/FormInput";
import FormDropdown from "components/form/FormDropdown";
import FormDateRange from "components/form/FormDateRange";

import "./article-filters.scss";

export default function ArticleFilters({ data, onChange }: {
   data: FData;
   onChange: FDataOnChange;
}) {

   const { d: category_options } = useCategoryOptions();

   return (
      <div className="article-filters">
         <FormInput
            name="query"
            value={ String( data.query || "" )}
            label="Wyszukiwarka słowna"
            onChange={ onChange }
         />

         <FormDropdown
            name="category_id"
            value={ data.category_id }
            label="Kategoria"
            options={ category_options || []}
            onChange={ onChange }
         />

         <FormDateRange
            name="published_from, published_to"
            data={ data }
            label="Data publikacji od - do"
            onChange={ onChange }
         />
      </div>
   )
}