import { cutText } from "utils/functions";
import mergeCssClasses from "utils/mergeCssClasses";
import UserAvatar from "components/general/UserAvatar";
import "./conversation-item.scss";

export default function ConversationItem( props: Conversation & { active: boolean; onClick: ( u: User ) => void; }) {

    const { active, user, last_message, onClick } = props;

    return (
        <div 
            className={ mergeCssClasses( "conversation-item", active && "active" )}
            onClick={() => onClick && onClick( user )}
        >

            <UserAvatar avatar_id=""/>

            <div>
                <div> { user.full_name } </div>
                <div> { cutText( last_message.content || "", 20 )} </div>
            </div>
        </div>
    )
}