import useFormData from "hooks/useFormData";
import useUserOptions from "hooks/User/useUserOptions";
import validateFields from "utils/validateFields";

import { useUser } from "context/AuthContext";
import Modal from "components/modal/Modal";
import FormDropdown from "components/form/FormDropdown";
import FormInput from "components/form/FormInput";

export default function AddConversationModal({ onClose, onConfirm }: {
    onClose: () => void;
    onConfirm: ( d: {
        user_id: number;
        content: string;
    }) => Promise<void>;
}) {
    
    const user = useUser();
    const { data, errors, onDataChange, setErrors } = useFormData();

    const validation = () => {

        if ( data.user_id && user.id === data.user_id ) {
            setErrors({ user_id: "Nie możesz rozpocząć rozmowy ze sobą!" });
            return false;
        }

        const check = validateFields([{ name: "user_id" }, { name: "content" }], data );
        setErrors( check.errors );

        return check.is_valid;
    }

    return (
        <Modal
            title="Dodaj nowego użytkownika"
            confirm_label="Zacznij konwersację"
            validationFn={ validation }
            onClose={ onClose }
            onConfirm={() => onConfirm({
                user_id: Number( data.user_id ),
                content: String( data.content || "" )
            })}
        >
            <>
                <FormDropdown
                    name="user_id"
                    value={ data.user_id }
                    error={ errors.user_id }
                    optionsHook={ useUserOptions }
                    label="Użytkownik"
                    onChange={ onDataChange }
                />

                <FormInput
                    name="content"
                    value={ String( data.content || "" )}
                    error={ errors.content }
                    label="Wiadomość"
                    type="textarea"
                    placeholder="Wpisz treść wiadomości"
                    style={{ margin: 0 }}
                    input_style={{ height: "174px" }}
                    onChange={ onDataChange }
                />
            </>
        </Modal>
    )
}