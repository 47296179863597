import Tile from "components/general/Tile";
import Paths from "router/Paths";

import { ReactComponent as TeacherSvg } from "./svg/teacher.svg";
import { ReactComponent as BriefcaseSvg } from "./svg/briefcase.svg";
import { ReactComponent as NoteSvg } from "./svg/note.svg";

import "./tiles.scss";

const tiles = [
    { 
        svg: <TeacherSvg/>,
        title: "Profil",
        text: "Możesz zaktualizować swoje dane osobowe, ustawić avatar lub zmienić hasło.",
        button: {
            label: "Zobacz swój profil",
            to: Paths.MyProfile
        }
    },
    {
        svg: <BriefcaseSvg/>,
        title: "Repozytorium",
        text: "Przechowuj swoje dokumenty w bezpiecznym miejscu. Każdy z naszych użytkowników otrzymuje darmowe repozytorium na swoje pliki związane z projektem.",
        button: {
            label: "Wyświetl rezpozytorium",
            to: Paths.DocumentsRepository
        }
    },
    {
        svg: <NoteSvg/>,
        title: "Komunikator",
        text: "Bądź w kontakcie z innymi użytkownikami portalu. Wymieniaj wiadomości oraz dokumenty.",
        button: {
            label: "Wyświetl wiadomości",
            to: Paths.Messenger
        }
    }
]

export default function Tiles() {
    return (
        <div className="home-tiles"> 
            { tiles.map(( tile, i ) => (
                <Tile key={ i } {...tile }/>
            ))}
        </div>
    )
}