import CategoryAPI from "api/CategoryAPI";
import useFetchData from "hooks/useFetchData";

export default function useCategoryOptions() {    
    return useFetchData({
      APIMethod: CategoryAPI.getAll,
      cache_name: "categories",
      resultDataHandler: data => data.map( category => ({
         value: category.id,
         label: category.name
      }))
    })
}