import ReservationAPI from "api/ReservationAPI";
import useFetchData from "hooks/useFetchData";

export default function useReservations( params: SpecialistReservationsGETPaginatedParams = {}) {
   return useFetchData({
      APIMethod: ReservationAPI.getAll,
      params: [ params ],
      clear_data_on_update: true,
      debounce: true
   })
}