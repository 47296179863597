import SpecialistReviewAPI from "api/SpecialistReviewAPI";
import useFetchData from "hooks/useFetchData";

export default function useSpecialistReviewsForSpecialist( params: SpecialistReviewsGETPaginatedParams = {}) {
   return useFetchData({
      APIMethod: SpecialistReviewAPI.getAllForSpecialist,
      params: [ params ],
      debounce: true,
      clear_data_on_update: true
   })
}