import { useState } from "react";

import FormAttachment from "components/form/FormAttachment";
import Modal from "../Modal";

export default function AddFilesModal( props: {
   accept?: string;
   text?: string;
   hide_doc_repo_btn?: boolean;
   onClose: () => void;
   onConfirm: ( f: File[]) => void | Promise<void>;
}) {

   const {
      accept = ".jpg,.jpeg,.png,.pdf,.doc,.docx",
      text = "(JPG, PNG, PDF, DOC, max 20MB)",
      hide_doc_repo_btn,
      onClose,
      onConfirm
   } = props;

   const [ files, setFiles ] = useState<File[]>([]);

   return (
      <Modal
         title="Dodaj pliki"
         confirm_label="Dodaj pliki"
         onClose={ onClose }
         onConfirm={() => onConfirm( files )}
      >
         <>
            <FormAttachment
               name="files"
               accept={ accept }
               text={ text }
               value={ files }
               hide_doc_repo_btn={ hide_doc_repo_btn }
               onChange={( n, v ) => setFiles( v as File[])}
            />
         </>
      </Modal>
   )
}