import SpecialistSessionReviewAPI from "api/SpecialistSessionReviewAPI";
import { getDateString } from "utils/date";
import useSpecialistSessionReviews from "hooks/Review/useSpecialistSessionReviews";
import useSpecialistSessionReviewsForSpecialist from "hooks/Review/useSpecialistSessionReviewsForSpecialist";

import RatingBox from "components/form/RatingBox";
import HookedDataTable from "layouts/HookedDataTable";

import { family_columns, specialist_columns, columns } from "./columns";
import { family_filter_inputs, specialist_filter_inputs } from "./filter_inputs";


export default function ActivityRating({
   participant_id,
   support_form_id,
   is_family,
   is_specialist,
   openFamilyReviewModal,
   openSpecialistReviewModal
}) {

   return (
      <HookedDataTable
         box_wrapper={ false }

         default_filters={{ participant_id, support_form_id }}
   
         table_columns={ is_family 
            ? [...family_columns, ...columns ]
            : [...specialist_columns, ...columns ]
         }

         filter_inputs={ is_family ? family_filter_inputs : specialist_filter_inputs }

         dataHook={ is_family ? useSpecialistSessionReviews : useSpecialistSessionReviewsForSpecialist }
         getRowsFn={( item, update ) => {

            const cells = [
               { content: getDateString( new Date( item.created_at ), "DD.MM.YYYY hh:mm" )},
               { content: <RatingBox value={ item.stars }/> },
               { actions: [
                  is_family ? { svg: "edit", onClick: () => openFamilyReviewModal({
                        type: "activity",
                        review: item,
                        onConfirm: async( d ) => {
                           await SpecialistSessionReviewAPI.update( item.id, d );
                           update();
                        }    
                  })} : null,
                  
                  is_specialist 
                     ? { svg: "view", onClick: () => openSpecialistReviewModal({ 
                        type: "activity",
                        review: item,
                        onConfirm: async( a ) => {
                           await SpecialistSessionReviewAPI.response( item.id, a );
                           update();
                        }
                     })} : null,
               ]}
            ]

            return {
               id: item.id,
               cells: is_family ? [
                  { content: item.specialist_reservation.support_form?.name },
                  { content: item.specialist.full_name },
                  ...cells
               ] : [{ content: item.participant.full_name }, ...cells ]
            }
         }}
      />
   )
}