import { useState } from "react";

import DocumentsRepository from "sections/DocumentsRepository";
import Modal from "../Modal";

import "./documents-repo-modal.scss";

export default function DocumentsRepoModal({ multiple, onClose, onConfirm }: {
    multiple: boolean;
    onClose: () => void;
    onConfirm: ( f: FileObj[]) => void;
}) {

    const [ selected_files, setSelectedFiles ] = useState<FileObj[]>([]);

    return (
        <Modal
            className="documents-repo-modal"
            title="Repozytorium dokumentów"
            confirm_label="Wybierz"
            onClose={ onClose }
            onConfirm={() => onConfirm( selected_files )}
        >
            <DocumentsRepository
                selected_files={ selected_files }
                onFileSelect={ item => setSelectedFiles( s => s.find( i => item.id === i.id ) 
                    ? multiple ? s.filter( i => i.id !== item.id ) : []
                    : multiple ? [...s, item ] : [ item ] 
                )}
            />
        </Modal>
    )
}