import withModal from "hoc/withModal";
import SpecialistScheduleAPI from "api/SpecialistScheduleAPI";
import { useAuthContext } from "context/AuthContext";

import PageHeader from "components/general/PageHeader";
import Tabs from "components/general/Tabs";
import SpecialistScheduleModal from "components/modal/SpecialistScheduleModal";
import SpecialistScheduleDeleteModal from "components/modal/SpecialistScheduleDeleteModal";

import TableView from "./tabs/TableView";
import CalendarView from "./tabs/CalendarView";
import { NoteSvg, CalendarSvg } from "svg/icons";
import "./schedule.scss";

export default withModal( function Schedule({ openModal }) {

   const { support_form } = useAuthContext();
   const support_form_id = support_form?.id;

   const openScheduleModal = ( opt: { item?: SpecialistSchedule, date?: string }, update: () => void ) => {
         
      const { item, date } = opt;

      openModal({
         Component: SpecialistScheduleModal,
         props: {
            values: item,
            date: date,
            onConfirm: async( d ) => {
               item && await SpecialistScheduleAPI.update( item.id, {
                  parent_id: item.parent_id || undefined,
                  support_form_id: support_form_id,
                  ...d
               });

               !item && await SpecialistScheduleAPI.add({
                  support_form_id: support_form_id,
                  ...d
               })

               update();
            } 
         }
      })
   }


   const openScheduleDeleteModal = ( id: number, update: () => void ) => {
      openModal({
         Component: SpecialistScheduleDeleteModal,
         props: {
            onConfirm: async( d ) => {
                  await SpecialistScheduleAPI.delete( id, d );
                  update();
            }
         }
      })
   }

   return (
      <div className="schedule-page">
         <PageHeader
            title="Terminarz"
            breadcrumbs={[ "Terminarz" ]}
         />

         <Tabs
            general_props={{
               support_form_id, 
               openScheduleModal,
               openScheduleDeleteModal
            }}

            tabs={[
               { 
                  value: 1,
                  label: "Widok tabeli",
                  SVG: <NoteSvg/>,
                  Component: TableView
               },
               { 
                  value: 2,
                  SVG: <CalendarSvg/>,
                  label: "Widok kalendarza", 
                  Component: CalendarView 
               }
            ]}
         />
      </div>
   )
})