import { useState } from "react";

export default function useFormData( default_data?: FData, default_static_data?: FData ) {

	const [ data, _setData ] = useState( default_data || {} as FData );
	const [ static_data, setStaticData ] = useState( default_static_data || {} as FData );
	const [ errors, setErrors ] = useState({} as FDataErrors );

	const onDataChange = ( name: FData | string, value?: FDataValue ) => {

		if ( typeof name === "string" ) {
			_setData( d => ({...d, [ name ]: value }));
			setErrors( err => ({...err, [ name ]: "" }));
		} else {

			const form_data = name;

			_setData( d => ({...d, ...form_data }));
			setErrors( err => {

				Object.keys( form_data ).forEach( n => {
					err[n] = "";
				})

				return {...err };
			})
		}
	}	

	
	const clearData = () => {
		_setData({});
		setErrors({})
	}


	const setData = ( d: FData ) => {
		_setData({...d });
		setErrors({});
	}


	return { 
		data, 
		static_data,
		errors, 

		onDataChange,
		clearData,
		setData,
		
		setStaticData, 
		setErrors,
	};
}