import withModal from "hoc/withModal";
import AddFilesModal from "components/modal/AddFilesModal";

import { FolderSvg } from "svg/icons";
import "./attach-files-btn.scss";

export default withModal<{ onConfirm: ( f: File[]) => void | Promise<void> }>(
    function AttachFilesBtn({ onConfirm, openModal }) {
        return (
            <button 
                className="attach-files-btn" 
                aria-label="Attach files"
                onClick={() => openModal({
                    Component: AddFilesModal,
                    props: { onConfirm }
                })}
            > 
                <FolderSvg/>
            </button>
        )
    }
)