import { getTimeDuration } from "utils/date";
import mergeCssClasses from "utils/mergeCssClasses";

export default function ScheduleCalendarEvent({
    start_time,
    end_time,
    is_break,
    text,
    style = {},
    calculate_top,
    calculate_height,
    onClick
}: ScheduleCalendarEvent & {
    is_break?: boolean;
    calculate_top?: boolean;
    calculate_height?: boolean; 
    style?: React.CSSProperties;
}) {

    const s_time = start_time?.slice( 0, 5 );
    const e_time = end_time?.slice( 0, 5 );

    const time_label = s_time + " - " + e_time;
    
    let top, height;
    if ( calculate_top ) {
        const [ start_h, start_m ] = start_time.split( ":" );
        top = ( +start_h - 7 ) * 64 + Number( start_m ) * 64 / 69 + "px";
    }

    if ( calculate_height ) {
        const duration = getTimeDuration( start_time, end_time );
        height = ( duration.hours * 64 + duration.minutes * 64 / 60 ) + "px";
    }

    if ( !start_time || !end_time ) return null;

    return (
        <div 
            className={ mergeCssClasses( "schedule-calendar-event", is_break && "break" )} 
            style={{ 
                ...style,
                top: top,
                height: height 
            }}
            onClick={ e => {
                e.preventDefault();
                e.stopPropagation();
                onClick && onClick();
            }}    
        >
            <strong> { is_break && "Przerwa: " } { time_label } </strong>
            <span> { text } </span>
        </div>
    )
}