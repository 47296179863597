import { useMemo } from "react";
import { useAuthContext } from "context/AuthContext";
import { dateISOStrToMMDDYYYY } from "utils/date";
import useFormData from "hooks/useFormData";
import useSpecialistCalendarDays from "hooks/Schedule/useSpecialistCalendarDays";
import useSupportFormParticipantsOptions from "hooks/SupportForm/useSupportFormParticipantsOptions";
import ReservationStatusOptions from "constants/ReservationStatusOptions";

import FormDropdown from "components/form/FormDropdown";
import FormCalendarInput from "components/form/FormCalendarInput";
import Modal from "components/modal/Modal";

import validateFields from "utils/validateFields";

export default function SpecialistReservationModal({ reservation_item: r, onClose, onConfirm }: {
   reservation_item?: SpecialistReservation;
   onClose: () => void;
   onConfirm: ( data: SpecialistReservationDTO ) => Promise<void>;
}) {

   const is_edit = !!r;
   const title = is_edit ? "Zmień termin spotkania" : "Dodaj spotkanie";
   const confirm_label = is_edit ? "Zmień termin" : "Dodaj spotkanie";

   const { support_form_assignment } = useAuthContext();
   const support_form_id = support_form_assignment?.support_form?.id;
   const time_frame_minutes = support_form_assignment?.time_frame_minutes;

   const { d: participant_options } = useSupportFormParticipantsOptions( is_edit ? undefined: support_form_id );

   const { data, errors, onDataChange, setErrors } = useFormData( r ? {
      participant_id: r.participant_id,
      specialist_schedule_id: r.specialist_schedule_id,
      selected_date: r.date,
      time: r.starting_time.slice( 0, 5 ) + "-" + r.ending_time.slice( 0, 5 ),
      status: r.status
   } : {});

   const { e, available_dates, time_options } = useSpecialistCalendarDays({
      support_form_id: r?.support_form_id || support_form_id,
      date_from: data.date_from ? String( data.date_from ) : undefined,
      date_to: data.date_to ? String( data.date_to ) : undefined 
   }, String( data.selected_date || "" ), r )

   const info = r ? [
      { 
         label: "Termin spotkania:", 
         value: `${ dateISOStrToMMDDYYYY( r.date )} ${ r.starting_time.slice( 0, 5 )} - ${ r.ending_time.slice( 0, 5 )}`
      },
      { label: "Uczestnik:", value: r.participant.full_name },
   ] : []

   const validation = () => {
      const check = validateFields([
         { name: "participant_id" },
         { name: "selected_date" },
         { name: "time" }
      ], data )

      setErrors( check.errors );
      return check.is_valid;
   }

   return (
      <Modal
         title={ title }
         confirm_label={ confirm_label }
         validationFn={ validation }
         onClose={ onClose }
         onConfirm={() => onConfirm({
            participant_id: Number( data.participant_id ),
            specialist_schedule_id: Number( data.specialist_schedule_id ), 
            starting_time: String( data.time || "" ).split("-")[0],
            ending_time: String( data.time || "" ).split("-")[1],
            status: data.status ? String( data.status ) : undefined
         })}
      >
         <>
            { is_edit && 
               <div style={{ marginBottom: "30px" }}>
                  { info.map(({ label, value }) => {
                     return (
                        <div key={ label }>
                           <span> { label } </span>
                           <strong> { value } </strong>
                        </div>
                     )})
                  }
               </div>
            }

            { !is_edit && time_frame_minutes &&
               <p> 
                  Długość spotkania w ramach formy wsparcia "{ support_form_assignment?.support_form?.name }": { time_frame_minutes } minut
               </p>
            }

            { !is_edit &&
               <FormDropdown
                  name="participant_id"
                  value={ data.participant_id }
                  error={ errors.participant_id }
                  label="Uczestnik"
                  options={ participant_options || []}
                  max_value_label_length={ 80 }
                  onChange={ onDataChange }
               />   
            }

            { e && <div className="error"> { e } </div> }

            { !e &&
               <>
                  <FormCalendarInput
                     name="selected_date"
                     value={ String( data.selected_date || "" )}
                     label="Data spotkania"
                     available_dates={ available_dates || []}
                     
                     onChange={( n, v ) => onDataChange({
                        [ n ]: v,
                        time: undefined
                     })}

                     onDateRangeChange={( date_from, date_to ) => onDataChange({
                        date_from,
                        date_to
                     })}
                  />

                  <FormDropdown
                     name="time"
                     value={ data.time }
                     label="Godzina rozpoczęcia"
                     error={ errors.time }
                     options={ time_options }
                     onChange={( n, v, option ) => { onDataChange({
                        [ n ]: v,
                        specialist_schedule_id: option?.extra
                     })}}
                  />

                  { is_edit && 
                     <FormDropdown
                        name="status"
                        value={ data.status }
                        label="Status"
                        error={ errors.status }
                        options={ ReservationStatusOptions }
                        onChange={ onDataChange }
                     />
                  }
               </> 
            }
         </>
      </Modal>
   )
}