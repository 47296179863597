import AuthAPI from "api/AuthAPI";
import useFormData from "hooks/useFormData";
import validateFields from "utils/validateFields";
import Toast from "utils/Toast";

import Modal from "components/modal/Modal";
import FormInput from "components/form/FormInput";

import inputs from "./inputs";

export default function ChangePasswordModal({ onClose }: {
    onClose: () => void;
}) {
    
    const { data, errors, onDataChange, setErrors } = useFormData();

    const validation = () => {
        
        const check = validateFields([
            { name: "current_password" },
            { name: "password", repeat_name: "password_confirmation" }
        ], data )

        setErrors( check.errors );
        return check.is_valid;
    }

    return (
        <Modal
            title="Zmień hasło"

            confirm_label="Zapisz nowe hasło"
            validationFn={ validation }
            onClose={ onClose }
            onConfirm={ async() => {
                await AuthAPI.updatePassword({
                    current_password: String( data.current_password ),
                    password: String( data.password ),
                    password_confirmation: String( data.password_confirmation )
                });

                Toast.success( "Hasło zostało pomyślnie zmienione!" );
            }}
        >
            <>
                <p> Aby zmienić hasło do systemu, wprowadź obecne hasło, a następnie podaj nowe hasło dwukrotnie. </p>

                { inputs.map(({ name, label }) => (
                    <FormInput
                        key={ name }
                        name={ name }
                        value={ String( data[ name ] || "" )}
                        error={ errors[ name ]}
                        label={ label }
                        type="password"
                        autoComplete="new-password"
                        onChange={ onDataChange }
                    />
                ))}
            </>
        </Modal>
    )
}