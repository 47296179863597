import Button from "components/buttons/Button";

const buttons = [
    { value: "month" as const, label: "Miesiąc" },
    { value: "week" as const, label: "Tydzień" },
    { value: "day" as const, label: "Dzień" }
]

export default function ModeSwitch({ mode, onClick }: {
    mode: ScheduleCalendarMode;
    onClick: ( m: ScheduleCalendarMode ) => void;
}) {
    return (
        <div className="schedule-calendar-mode-switch">
            { buttons.map(({ value, label }) => (
                <Button 
                    key={ value }
                    extra_classes={ value === mode ? "" : "gray" }
                    onClick={() => onClick( value )}
                >
                    { label }
                </Button>
            ))}
        </div>
    )
}