import { CancelToken } from "axios";
import API from "./API";

export default {

   getAll: async( cancelToken?: CancelToken ) => {
      const { data } = await API.get<Category[]>( `/cms/categories`, {
         cancelToken
      })

      return data;
   }
}