import { useState } from "react";
import useFormData from "hooks/useFormData";

import FormInput from "components/form/FormInput";
import Button from "components/buttons/Button";
import AttachFilesBtn from "components/buttons/AttachFilesBtn";
import FileLines from "components/general/FileLines";
import Loader from "components/general/Loader";

import "./send-message-form.scss";

let shift = false;
let disabled = false;

export default function SendMessageForm({ sendMessage }: {
    sendMessage: ( d: MessageDTO ) => Promise<void>;
}) {

    const [ querying, setQuerying ] = useState( false );
    const { data, errors, onDataChange, setErrors, clearData } = useFormData();

    const onSubmit = async() => {
        if ( !data.content ) {
            setErrors({ content: "To pole jest wymagane!" })
            return;
        } 

        if ( disabled ) return;

        data.attachments && setQuerying( true );
        disabled = true;

        await sendMessage({
            content: String( data.content ),
            attachments: ( data.attachments || []) as Array<File | FileObj>
        });

        disabled = false;
        setQuerying( false );
        clearData();
    }


    const removeFile = ( i: number ) => {
        const f = Array.isArray( data.attachments ) ? [...data.attachments ] as Array<File | FileObj> : [];
		f.splice( i, 1 );
		onDataChange( "attachments", f );
    }

    return querying ? <Loader/> : (        
        <div className="send-message-form">

            <div className="send-message-form-main"> 
                <FormInput
                    name="content"
                    value={ String( data.content || "" )}
                    error={ errors.content }
                    type="textarea"
                    placeholder="Wpisz treść wiadomości"
                    onChange={ onDataChange }
                    onKeyDown={( e ) => {

                        if ( e.key === "Enter" ) {
                            e.preventDefault();
                            
                            shift 
                                ? onDataChange( "content", data.content + "\n" )
                                : onSubmit()
                        }

                        if ( e.key === "Shift" ) shift = true;
                    }}

                    onKeyUp={ e => { if ( e.key === "Shift" ) shift = false }}
                />
        
                <AttachFilesBtn
                    onConfirm={( f ) => {
                        const attachments = ( data.attachments || []) as File[];
                        onDataChange( "attachments", [...attachments, ...f ]);
                    }}
                />

                <Button onClick={ onSubmit }> Wyślij </Button>
            </div>

            <FileLines 
                list={( data.attachments || []) as File[]} 
                onDelete={ removeFile }
            />
        </div>
    )
}