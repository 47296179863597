import PageHeader from "components/general/PageHeader";
import DocumentsRepository from "sections/DocumentsRepository";

export default function DocumentsRepositoryPage() {
    return (
        <>
            <PageHeader
                title="Repozytorium dokumentów"
                breadcrumbs={[ "Repozytorium dokumentów" ]}
            />

            <DocumentsRepository/>
        </>
    )
}