import { useLocation, useParams } from "react-router-dom";
import HTMLReactParser from "html-react-parser";

import usePost from "hooks/Post/usePost";

import PageHeader from "components/general/PageHeader";
import Box from "components/general/Box";
import Loader from "components/general/Loader";
import ErrorMessage from "components/general/ErrorMessage";
import DownloadFileLine from "components/general/DownloadFileLine";
import GuestPageHeader from "components/general/GuestPageHeader";

import "./article-view.scss";

export default function ArticleView() {

   const location = useLocation();
   const is_panel = /panel/.test( location.pathname );

   const { slug, id } = useParams();
   const { d: post, e } = usePost({ slug, id, ...( location.state || {}) });

   if ( e ) return <ErrorMessage message={ e }/> 

   return post ? (
      <>
         { is_panel ? <PageHeader
            title={ post.title }
            breadcrumbs={[ "ARTICLES", post.title ]}
         /> : <GuestPageHeader title={ post.title }/> }

         <div style={ !is_panel ? { padding: "0 15px" } : {}}> 
            <Box className="article-view-content">
               { HTMLReactParser( post.content || "" )}
            </Box>

            { post.attachments && !!post.attachments.length && 
               <Box heading="Pliki do pobrania">
                  <div style={{
                     display: "flex",
                     flexDirection: "column",
                     rowGap: "14px"
                  }}> 
                     { post.attachments.map( att => {
                        return (
                           <DownloadFileLine 
                              key={ att.id } 
                              {...att }
                              id={ att.access_token }
                           />
                        )
                     })}
                  </div>
               </Box>
            }
         </div>
      </>
   ) : <Loader/>
} 