import AuthAPI from "api/AuthAPI";
import Paths from "router/Paths";
import Toast from "utils/Toast";

import NotLoggedIn from "layouts/NotLoggedIn";
import FormInput from "components/form/FormInput";

export default function ForgotPassword() {

    const onSubmitForm = async( form: HTMLFormElement ) => {
        const email = form.email.value;

		try {
			await AuthAPI.forgotPassword( email );
			Toast.success( `Na e-mail ${ email } został wysłany link do resetowania hasła!` );
		} catch {
			Toast.error( `Nie znaleziono użytkownika z adresem e-mail ${ email }!` );
		}
    }

    return (
        <NotLoggedIn
            title="Przypomnij hasło"
            submit_label="Wyślij"
            bottom_link={{
                to: Paths.Auth.Login,
                label: "Zaloguj się"
            }}

            onSubmitForm={ onSubmitForm }
        > 

            <p> Podaj adres e-mail powiązany z Twoim kontem w aplikacji. </p>
            <p> Na podany adres e-mail zostanie wysłana instrukcja odzyskiwania hasła.</p>
            
            <FormInput
                name="email"
                label="E-mail"
                type="email"
            />
           
        </NotLoggedIn>
    )
}