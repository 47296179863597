import { useMemo } from "react";
import { useAuthContext } from "context/AuthContext";
import useFormData from "hooks/useFormData";
import useCalendarDays from "hooks/Schedule/useCalendarDays";

import FormDropdown from "components/form/FormDropdown";
import FormCalendarInput from "components/form/FormCalendarInput";
import Modal from "components/modal/Modal";

import validateFields from "utils/validateFields";

export default function ReservationModal({ add_data, reservation_item: r, onClose, onConfirm }: {
   add_data?: {
      specialist: SupportFormSpecialist;
      support_form: {
         id: number;
         name: string;
      }

      date?: string;
      from?: string;
      to?: string;
   };

   reservation_item?: SpecialistReservation;
   onClose: () => void;
   onConfirm: ( data: SpecialistReservationDTO ) => Promise<void>;
}) {

   const is_edit = !!r;
   const title = is_edit ? "Zmień termin spotkania" : "Zarezerwuj termin spotkania";
   const confirm_label = is_edit ? "Zmień termin" : "Zarezerwuj";

   const { participant } = useAuthContext();

   const { data, errors, onDataChange, setErrors } = useFormData( r ? {
      participant_id: r.participant_id,
      specialist_schedule_id: r.specialist_schedule_id,
      selected_date: r.date,
      time: r.starting_time.slice( 0, 5 ) + "-" + r.ending_time.slice( 0, 5 )
   } : {
      participant_id: participant?.id,
      selected_date: add_data?.date,
      time: add_data?.from && add_data?.to
         ?  `${ add_data.from.slice( 0, 5 )}-${ add_data.to.slice( 0, 5 )}`
         : undefined
   });

   const { e, available_dates, time_options } = useCalendarDays({
      specialist_user_id: r?.specialist_user_id || add_data?.specialist?.user?.id,
      support_form_id: r?.support_form_id || add_data?.support_form?.id,
      date_from: data.date_from ? String( data.date_from ) : undefined,
      date_to: data.date_to ? String( data.date_to ) : undefined 
   }, String( data.selected_date || "" ), r )

   const info = is_edit 
      ? [
         { label: "Forma wsparcia:", value: r?.support_form?.name },
         { label: "Specjalista:", value: r?.specialist?.full_name }
      ] 
      : [
         { label: "Forma wsparcia:", value: add_data?.support_form?.name },
         { label: "Czas trwania spotkania:", value: add_data?.specialist?.time_frame_minutes },
         { label: "Specjalista:", value: add_data?.specialist?.user?.full_name }
      ]


   const validation = () => {
      const check = validateFields([
         { name: "selected_date" },
         { name: "time" }
      ], data )

      setErrors( check.errors );
      return check.is_valid;
   }

   return (
      <Modal
         title={ title }
         confirm_label={ confirm_label }
         validationFn={ validation }
         onClose={ onClose }
         onConfirm={() => onConfirm({
            participant_id: Number( data.participant_id ),
            specialist_schedule_id: Number( data.specialist_schedule_id ), 
            starting_time: String( data.time || "" ).split("-")[0],
            ending_time: String( data.time || "" ).split("-")[1]
         })}
      >
         <>
            <div style={{ marginBottom: "30px" }}>
               { info.map(({ label, value }) => {
                  return (
                     <div key={ label }>
                        <span> { label } </span>
                        <strong> { value } </strong>
                     </div>
                  )})
               }
            </div>

            { !e && 
               <>
                  <FormCalendarInput
                     name="selected_date"
                     value={ String( data.selected_date || "" )}
                     label="Data spotkania"
                     available_dates={ available_dates || []}
                     
                     onChange={( n, v ) => onDataChange({
                        [ n ]: v,
                        time: undefined
                     })}

                     onDateRangeChange={( date_from, date_to ) => onDataChange({
                        date_from,
                        date_to
                     })}
                  />

                  <FormDropdown
                     name="time"
                     value={ data.time }
                     label="Godzina rozpoczęcia"
                     error={ errors.time }
                     options={ time_options }
                     style={{ margin: 0 }}
                     onChange={( n, v, option ) => { onDataChange({
                        [ n ]: v,
                        specialist_schedule_id: option?.extra
                     })}}
                  />
               </>
            }
         </>
      </Modal>
   )
}