import React, { useState } from "react";
import Loader from "components/general/Loader";
import Button from "components/buttons/Button";
import { ReactComponent as Logo } from "svg/logo.svg";
import Paths from 'router/Paths';

import "./not-logged-in.scss";

const default_links = [
	{ to: Paths.ServiceRegulation, label: "Regulamin aplikacji", onClick: undefined },
	{ to: Paths.PrivacyPolicy, label: "Polityka prywatności", onClick: undefined },
   { to: Paths.AccessibilityDeclaration, label: "Deklaracja dostępności", onClick: undefined }
]

export default function NotLoggedIn({
   title, 
   submit_label, 
   bottom_link, 
   children, 
   links,
   no_form,
   onSubmitForm 
}: {
	title: string;
	submit_label?: string;

	bottom_link?: {
		to: string;
		label: string;
      onClick?: () => void;
	};

	children: React.ReactNode;
	
   links?: {
		to: string;
		label: string;
      onClick?: () => void;
	}[];

	no_form?: boolean;
   onSubmitForm?: ( form: HTMLFormElement ) => Promise<void | unknown>;
}) {
	
	const [ progress, setProgress ] = useState( false );

	const onSubmit = async( e ) => {
		e.preventDefault();
		setProgress( true );

		try {
			onSubmitForm && await onSubmitForm( e.target );
		} finally {
			setProgress( false );
		}
	}

	return (
		<div className="not-logged-in">
		 
		 	<main className={ no_form ? "box-less-horizontal-padding box" : "box"}>

		 		<div className="box-logo">
               <Logo/>
            </div>

				<h1> { title } </h1>
				{ no_form && children }
				
				{ !no_form && 
               <form className="auth-form" onSubmit={ onSubmit }>
					
                  { children }

                  {( !!submit_label || bottom_link ) && 
                     <div className="auth-form-bottom"> 	
                        { progress 
                           ? <Loader/>
                           : (<>
                              { submit_label && 
                                 <Button type="submit"> 	
                                    { submit_label } 
                                 </Button> 
                              }

                              { bottom_link && 
                                 <Button 
                                    to={ bottom_link.to }
                                    extra_classes="transparent"
                                    onClick={ bottom_link.onClick }
                                 > 
                                    { bottom_link.label }
                                 </Button>
                              }
                           </>)
                        }		
                     </div> 
                  }
               </form>
            }				

				{ !progress &&
					<div className="box-bottom">
						{( links || default_links ).map(({ to, label, onClick }, i ) => (
							<Button 
								key={ i }
								to={ to }
                        onClick={ onClick }
								extra_classes="purple transparent"
							> 
								{ label }
							</Button>
						))}
					</div>
				}

			</main>
		 	
			<div className="not-logged-in-bottom"></div>
		</div>
	)
}