import { useState, useMemo } from 'react';

import useNotifications from "hooks/Notification/useNotifications";
import { getDateString } from "utils/date";
import withModal from "hoc/withModal";

import HookedDataTable from "layouts/HookedDataTable";
import NotificationViewModal from "./component/NotificationViewModal";

import "./notifications.scss";

export default withModal( function Notifications({ openModal }) {
    const [needReload, setNeedReload] = useState(false);
    return useMemo(() => (
        <HookedDataTable
            key={needReload ? 1 : 0}

            page_header={{
                title: "Lista powiadomień",
                breadcrumbs: [ "Lista powiadomień" ]
            }}

            table_columns={[
                { name: "", label: "Tytuł powiadomienia" },
                { name: "created_at", label: "Data i godzina", width: 310 },
                { label: "Akcje", align: "center" as const, width: 90 },
            ]}

            filter_inputs={{
                0: { name: "title" },
                1: { name: "date_from, date_to", type: "date-range" }
            }}

            dataHook={ useNotifications }
            getRowsFn={ item => {
                const isBold = !item.read_at ? 'bold' : undefined
                return ({
                    id: item.id,
                    cells: [
                        { content: <span className={isBold}>{item.title}</span>},
                        { content: <span className={isBold}>{getDateString( new Date( item.created_at ), "DD.MM.YYYY hh:mm" )}</span>},
                        { actions: [
                            { svg: "view", onClick: () => {
                                openModal({
                                    Component: NotificationViewModal,
                                    props: { notification: {...item }}
                                })
                                !item.read_at && setNeedReload(!needReload)
                            }}
                        ]},
                    ]
                })
            }}
        />
    ), [needReload])
})