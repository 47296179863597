import API from "./API";
import { CancelToken } from "axios";
import FileAPI from "./FileAPI";

const prepareAttachments = async( data: MessageDTO ) => {

    if ( !data.attachments ) return undefined;

    const files = data.attachments.filter( f => f instanceof File ) as File[];
    const file_objects = data.attachments.filter( f => "id" in f ) as FileObj[];

    const new_files = await FileAPI.updateFewTemp( files ) 
    return [ ...new_files, ...file_objects.map( f => f.id )];
}

export default {

    getAllConversations: async( cancelToken?: CancelToken ) => {
        const { data } = await API.get<{ conversations: SConversation[]}>( `/messenger/conversations`, { cancelToken })
        return data.conversations;
    },

    getConversation: async( user_id: number, page: number, cancelToken?: CancelToken ) => {
        const { data } = await API.get<{ messages: Message[]}>( `/messenger/conversation/${ user_id }/${ page }`, {
            params: { limit: 20 },
            cancelToken
        });
        
        return data.messages;
    },

    getUnread: async() => {
        const { data } = await API.get<{ messages: Message[]}>( `/messenger/unread`);
        
        return data;
    },

    send: async( user_id: number, data: MessageDTO ) => {
        
        const attachments = await prepareAttachments( data );
        
        return API.post(`/messenger/conversation/${ user_id }`, {
            content: data.content,
            attachments
        })
    },

    deleteMessage: ( user_id: number, message_id: number ) => {
        return API.delete( `/messenger/conversation/${ user_id }/${ message_id }`)
    },

    deleteConversation: ( user_id: number) => {
        return API.delete( `/messenger/conversation/${ user_id }`)
    }
}