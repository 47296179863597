import { useEffect, useRef } from "react";

export default function useDidChange( cb: Function, dependencies?: any[]) {
	
	const did_mount = useRef( false );

	useEffect(() => {

		if ( !did_mount.current ) {
			did_mount.current = true;
			return;
		}

		cb();

	}, dependencies || [])
}