import { useMemo, useState } from "react";
import useFormData from "hooks/useFormData";
import { getDateString } from "utils/date";

import Modal from "components/modal/Modal";
import InfoLines from "components/general/InfoLines";
import RatingBox from "components/form/RatingBox";
import FormInput from "components/form/FormInput";
import Button from "components/buttons/Button";

export default function SpecialistReviewModal({ type, review, onClose, onConfirm }: {
   type: "activity" | "specialist"; 
   review: SpecialistSessionReview | SpecialistReview;
   onClose: () => void;   
   onConfirm?: ( answer: string ) => Promise<void>;
}) {

   const [ add_comment, setAddComment ] = useState( false );

   const { data, errors, onDataChange, setErrors } = useFormData({
      answer: review.specialist_answer || ""
   });


   const validation = () => {
      
      if ( !data.answer ) {
         setErrors({ answer: "To pole jest wymagane!" });
         return false;
      }

      return true;
   }

    
   const info = useMemo(() => {
      const info = [
         "specialist_reservation" in review 
               ? { label: "Forma wsparcia:", value: review?.specialist_reservation?.support_form?.name }
               : { label: "Specjalista:", value: review.specialist?.full_name },
         { label: "Użytkownik:", value: review?.user?.full_name },
         { label: "Uczestnik:", value: review?.participant?.full_name },
         { label: "Data wystawienia:", value: getDateString( new Date( review.created_at ), "DD.MM.YYYY hh:mm" )},
         { label: "Ocena:", value: <RatingBox value={ review.stars }/> },
         { label: "Opis:", value: review.review }
      ]

      review.specialist_answer && info.push({ label: "Komenatrz specjalisty:", value: review.specialist_answer })
      return info;
   }, []) 

    
    return (
        <Modal
            title={ type === "activity" ? "Ocena aktywności" : "Ocena specjalisty" }
            confirm_label="Zapisz Komentarz"
            validationFn={ validation }
            onClose={ onClose }
            onConfirm={ onConfirm && add_comment
                ? () => onConfirm( String( data.answer ))
                : undefined 
            }
        >
         <>
            <InfoLines info={ info }/>

            {( !add_comment && !review.specialist_answer ) &&
               <div style={{ display: "flex" }}>
                  <Button 
                     style={{ margin: "20px 0 0 auto" }} 
                     onClick={() => setAddComment( true )}
                  > Dodaj Komentarz do oceny </Button>
               </div>
            }

            { add_comment && 
               <FormInput
                  name="answer"
                  label="Komentarz specjalisty"
                  type="textarea"
                  value={ String( data.answer )}
                  error={ errors.answer }
                  style={{ marginBottom: 0, marginTop: 20 }}
                  input_style={{ height: 132 }}
                  onChange={ onDataChange }
               />
            }  
         </>
        </Modal>
    )
}